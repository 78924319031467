import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { defineMessages } from 'react-intl';
import { Grid } from 'semantic-ui-react';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  SidebarPortal,
  UniversalLink,
  ConditionalLink,
} from '@plone/volto/components';
import { PresetWrapper } from '@package/components';
import { ListingImage } from '@package/components';
import CustomButton from '@package/components/Button/Button';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import Sidebar from './Sidebar';

const messages = defineMessages({
  title: {
    id: 'Title',
    defaultMessage: 'Title',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Description',
  },
  placeholder: {
    id: 'Upload a new image',
    defaultMessage: 'Upload a new image',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Image',
  },
  cta_title_default: {
    id: 'cta_title_default',
    defaultMessage: 'Vai al contenuto',
  },
  emptySelection: {
    id: 'emptySelection',
    defaultMessage:
      'Seleziona un elemento nella barra laterale per mostrarlo qui.',
  },
});

const Edit = (props) => {
  const {
    data,
    selected,
    block,
    onChangeBlock,
    isEditMode = true,
    intl,
  } = props;

  const img_column_width = data.img_column_width
    ? parseInt(data.img_column_width)
    : 6;

  let image = null;

  if (data?.content_in_evidence?.length > 0) {
    image = ListingImage({
      item: data?.content_in_evidence?.[0],
      className: 'block-highlighted-image',
      size: 'larger',
    });
  }

  if (__SERVER__) {
    return <div />;
  }

  return (
    <PresetWrapper
      {...data}
      usePresetDefaults={false}
      className="block-highlighted-content"
    >
      {data?.content_in_evidence?.length > 0 ? (
        <>
          <Grid className="block-content">
            <Grid.Row
              className={cx({
                'block-destra': data?.destra,
              })}
              columns={2}
            >
              <Grid.Column
                computer={img_column_width}
                tablet={img_column_width}
                mobile={12}
              >
                {/* image */}
                {image ? (
                  image
                ) : (
                  <div className="placeholder">
                    <img src={DefaultImageSVG} alt="" />
                  </div>
                )}
              </Grid.Column>
              <Grid.Column
                computer={12 - img_column_width}
                tablet={12 - img_column_width}
                mobile={12}
              >
                <div className="block-highlighted-body">
                  {/* title */}
                  {!data.has_cta ? (
                    <h3 className="title">
                      <ConditionalLink
                        condition={!isEditMode}
                        item={data.content_in_evidence?.[0]}
                      >
                        {data.content_in_evidence?.[0]?.title}
                      </ConditionalLink>
                    </h3>
                  ) : (
                    <h3 className="title">
                      {data.content_in_evidence?.[0]?.title}
                    </h3>
                  )}
                  {/* descrizione */}
                  {data.content_in_evidence?.[0]?.description && (
                    <div className="content-description">
                      {data.content_in_evidence?.[0]?.description}
                    </div>
                  )}
                  {/* cta */}
                  {data?.has_cta &&
                    (data.link_to?.[0] || data.link_to_external) && (
                      <div className="buttonBottom">
                        <CustomButton
                          as={UniversalLink}
                          size="large"
                          href={
                            data.link_to
                              ? flattenToAppURL(data.link_to[0]?.['@id'])
                              : data.link_to_external
                              ? data.link_to_external
                              : null
                          }
                          arrow={true}
                        >
                          {data?.cta_title ||
                            intl.formatMessage(messages.cta_title_default)}
                        </CustomButton>
                      </div>
                    )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      ) : (
        <p className="empty-selection">
          {intl.formatMessage(messages.emptySelection)}
        </p>
      )}
      <SidebarPortal selected={selected}>
        <Sidebar
          {...data}
          onChange={(fieldName, value) => {
            const newValue = fieldName === 'cta_title' ? value ?? '' : value;
            onChangeBlock(block, {
              ...data,
              [fieldName]: newValue,
            });
          }}
        />
      </SidebarPortal>
    </PresetWrapper>
  );
};

Edit.propTypes = {
  data: PropTypes.shape({
    destra: PropTypes.bool,
    link_to: PropTypes.array,
    link_to_external: PropTypes.string,
    cta_title: PropTypes.string,
    have_cta: PropTypes.any,
    url: PropTypes.any,
  }).isRequired,
  selected: PropTypes.bool,
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
};

export default Edit;
