import React, { useState } from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import {
  TextWidget,
  CheckboxWidget,
  FileWidget,
  SelectWidget,
  FileContentsWidget,
  FormFieldWrapper,
} from '../../CVWidgets';
import { Icon } from '@plone/volto/components';
import deleteSVG from '@plone/volto/icons/delete.svg';
import { Field } from '@package/components/CVWidgets';
import { messages } from '../utils';

const initialState = (socialProfiles) =>
  Array.isArray(socialProfiles)
    ? socialProfiles
    : [
        {
          social: '',
          profile: '',
        },
      ];

const AdditionalInfosStep = (props) => {
  const {
    how_did_you_know_vocab,
    relatives_in_rai,
    preferred_workplace,
    willing_to_transfer,
    protected_category,
    social_profiles,
    custom_fields,
    personal_cv,
    // image,
    setFieldValue,
    control,
    intl,
    isDisabled,
    customFieldsSchema,
  } = props;

  const [socialProfiles, setSocialProfiles] = useState(
    initialState(social_profiles),
  );

  const cvSchema = useSelector((state) => state.schema.schema);

  const preferredWorkplaceChoices =
    cvSchema?.properties.preferred_workplace?.items?.choices;

  const handleOnChange = (id, value) => {
    setFieldValue(id, value);
  };
  const addNewSocial = () => {
    const newProfessionalExperienceState = [
      ...(socialProfiles ?? []),
      ...initialState(null),
    ];
    setSocialProfiles(newProfessionalExperienceState);
    setFieldValue('social_profiles', newProfessionalExperienceState, false);
  };

  const removeSocial = (index) => {
    const newProfessionalExperienceState = socialProfiles.filter(
      (item, i) => i !== index,
    );
    setSocialProfiles(newProfessionalExperienceState);
    setFieldValue('social_profiles', newProfessionalExperienceState, false);
  };

  const handleRadiosOnChange = (id, value) => {
    setFieldValue(id, {
      title: value,
      token: value === 'Yes' ? 'True' : 'False',
    });
  };

  const handleCheckboxList = (id, value) => {
    if (props[id].some((item) => item.title === value)) {
      setFieldValue(
        id,
        props[id].filter((item) => item.title !== value),
      );
    } else {
      const newFieldValue = {
        title: value,
        token: value,
      };

      if (id === 'preferred_workplace') {
        const selectedWorkplace = preferredWorkplaceChoices.find(
          (innerArray) => innerArray[0] === value,
        );
        const preferredWorkplaceNewField = {
          title: selectedWorkplace[1],
          token: selectedWorkplace[0],
        };

        if (value === 'none') {
          setFieldValue(id, [preferredWorkplaceNewField]);
        } else {
          setFieldValue(id, [
            ...props[id].filter((val) => val.token !== 'none'),
            preferredWorkplaceNewField,
          ]);
        }
      } else {
        setFieldValue(id, [...props[id], newFieldValue]);
      }
    }
  };

  const handleSocialOnChange = (id, value, index) => {
    const newProfessionalExperienceState = socialProfiles.map((item, i) => {
      if (index === i) {
        return { ...item, [id]: value };
      } else {
        return item;
      }
    });
    setSocialProfiles(newProfessionalExperienceState);
    setFieldValue('social_profiles', newProfessionalExperienceState);
  };

  const handleCustomFieldsOnChange = (id, value) => {
    setFieldValue('custom_fields', {
      ...(custom_fields ?? {}),
      [id]: value,
    });
  };

  const handleCustomCheckboxOnChange = (id, value) => {
    const fields = custom_fields ?? {};
    if (!Array.isArray(fields[id])) {
      fields[id] = [];
    }
    setFieldValue('custom_fields', {
      ...fields,
      [id]: fields[id].includes(value)
        ? fields[id].filter((val) => val !== value)
        : [...fields[id], value],
    });
  };

  return (
    <div className="step-container" aria-live="polite">
      <h4>{intl.formatMessage(messages.additionalInfo)}</h4>
      <Form.Group widths={'equal'}>
        <SelectWidget
          isDisabled={isDisabled}
          id="how_did_you_know_vocab"
          title={intl.formatMessage(messages.howDidYouHearAboutUs)}
          value={how_did_you_know_vocab}
          onChange={handleOnChange}
          choices={cvSchema?.properties.how_did_you_know_vocab?.choices ?? []}
          noValueOption={false}
          required={false}
          control={control}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          id="relatives_in_rai"
          title={intl.formatMessage(messages.relativesInRai)}
          wrapped
          className=""
          control={control}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="relatives_in_rai"
            value={'Yes'}
            onChange={handleRadiosOnChange}
            required={true}
            title={intl.formatMessage(messages.yes)}
            radio={true}
            checked={relatives_in_rai?.token === 'True'}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="relatives_in_rai"
            value={'No'}
            onChange={handleRadiosOnChange}
            required={true}
            title={intl.formatMessage(messages.no)}
            radio={true}
            checked={relatives_in_rai?.token === 'False' || !relatives_in_rai}
          />
        </FormFieldWrapper>
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          id="preferred_workplace"
          title={intl.formatMessage(messages.preferredWorkplace)}
          wrapped
          className=""
          control={control}
          required={true}
        >
          {(cvSchema?.properties.preferred_workplace?.items?.choices ?? []).map(
            (value) => (
              <CheckboxWidget
                key={value[0]}
                isDisabled={isDisabled}
                id={`preferred_workplace`}
                value={value[0]}
                onChange={handleCheckboxList}
                title={value[1]}
                checked={
                  preferred_workplace.length === 0
                    ? value[0] === 'none'
                    : preferred_workplace?.some(
                        (item) => item.token === value[0],
                      )
                }
              />
            ),
          )}
        </FormFieldWrapper>
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          id="willing_to_transfer"
          title={intl.formatMessage(messages.willingToRelocate)}
          wrapped
          className=""
          control={control}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="willing_to_transfer"
            value={'Yes'}
            onChange={handleRadiosOnChange}
            required={true}
            title={intl.formatMessage(messages.yes)}
            radio={true}
            checked={willing_to_transfer?.token === 'True'}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="willing_to_transfer"
            value={'No'}
            onChange={handleRadiosOnChange}
            required={true}
            title={intl.formatMessage(messages.no)}
            radio={true}
            checked={
              willing_to_transfer?.token === 'False' || !willing_to_transfer
            }
          />
        </FormFieldWrapper>
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          id="protected_category"
          title={intl.formatMessage(messages.protectedCategory)}
          wrapped
          className=""
          control={control}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="protected_category"
            value={'Yes'}
            onChange={handleRadiosOnChange}
            required={true}
            title={intl.formatMessage(messages.yes)}
            radio={true}
            checked={protected_category?.token === 'True'}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="protected_category"
            value={'No'}
            onChange={handleRadiosOnChange}
            required={true}
            title={intl.formatMessage(messages.no)}
            radio={true}
            checked={
              protected_category?.token === 'False' || !protected_category
            }
          />
        </FormFieldWrapper>
      </Form.Group>
      {/* <h4>{intl.formatMessage(messages.photo)}</h4>
      <Form.Group widths={'equal'}>
        <FileWidget
          isDisabled={isDisabled}
          id="image"
          title={intl.formatMessage(messages.photo)}
          required={true}
          onChange={handleOnChange}
          wrapped={true}
          value={image}
          control={control}
        />
      </Form.Group>
      <Form.Group widths={'equal'}></Form.Group> */}

      <div className="education-add-container">
        <h4>{intl.formatMessage(messages.socialProfiles)}</h4>
      </div>
      {socialProfiles?.map((sp, spIndex) => (
        <div className="cv-field-deletable">
          <Form.Group widths={'equal'}>
            <SelectWidget
              isDisabled={isDisabled}
              id="social"
              title={intl.formatMessage(messages.social)}
              value={sp?.social}
              onChange={(id, value) => handleSocialOnChange(id, value, spIndex)}
              choices={[
                ['facebook', 'Facebook'],
                ['instagram', 'Instagram'],
                ['linkedin', 'Linkedin'],
              ]}
              noValueOption={false}
              required={false}
              control={control}
            />
            <TextWidget
              isDisabled={isDisabled}
              id="profile"
              type="text"
              name="profile"
              value={sp?.profile}
              onChange={(id, value) => handleSocialOnChange(id, value, spIndex)}
              required={false}
              title={intl.formatMessage(messages.profile)}
              wrapped={true}
              control={control}
            />
          </Form.Group>
          {spIndex > 0 && (
            <Button
              icon
              className="delete-field"
              onClick={() => removeSocial(spIndex)}
            >
              <Icon name={deleteSVG} size="24px" color="#ff004d" />
            </Button>
          )}
        </div>
      ))}
      <div className="add-new-fieldset">
        {!isDisabled && (
          <Button
            className="rw--button rw--button-outline"
            onClick={() => addNewSocial()}
          >
            {intl.formatMessage(messages.add, {
              context: intl.formatMessage(messages.socialProfiles),
            })}
          </Button>
        )}
      </div>
      {customFieldsSchema.length > 0 && (
        <>
          <h4>{intl.formatMessage(messages.customFieldsTitle)}</h4>
          {customFieldsSchema.map((field) => (
            <Form.Group widths={'equal'} key={field['@id']}>
              <Field
                name={field['@id']}
                id={field['@id']}
                value={custom_fields?.[field['@id']] ?? ''}
                onChange={
                  field.field_type === 'multiple_choice'
                    ? handleCustomCheckboxOnChange
                    : handleCustomFieldsOnChange
                }
                isOnEdit={false}
                valid={true}
                control={control}
                {...field}
                disabled={isDisabled}
              />
            </Form.Group>
          ))}
        </>
      )}
      <h4>CV</h4>
      <Form.Group widths={'equal'}>
        <FileWidget
          isDisabled={isDisabled}
          id="personal_cv"
          title="CV"
          required={true}
          onChange={handleOnChange}
          wrapped={true}
          value={personal_cv}
          control={control}
        />
      </Form.Group>
      <h4>{intl.formatMessage(messages.additionalAttachments)}</h4>
      <Form.Group widths={'equal'}>
        <FileContentsWidget
          isDisabled={isDisabled}
          id="additionalAttachments"
          title={intl.formatMessage(messages.additionalAttachmentsLabel)}
          required={false}
          onChange={() => {}}
          wrapped={true}
          control={control}
        />
      </Form.Group>
    </div>
  );
};

export default AdditionalInfosStep;
