import { flattenToAppURL } from '@plone/volto/helpers';

const getImageUrl = (item, size) => {
  let field = item.image_field;
  if (item['@type'] === 'Image' && item.image_field === 'preview_image') {
    field = 'image';
  }
  return item.image_field
    ? flattenToAppURL(`${item['@id']}/@@images/${field}/${size}`)
    : flattenToAppURL(item?.image?.scales?.[size]?.download);
};

export default getImageUrl;
