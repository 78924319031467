import { GET_MONTHS_TO_OBLIVION } from '@package/actions/monthsToOblivion';

const initialState = {
  error: null,
  hasError: false,
  successMonthsToOblivion: false,
  value: {},
};

export const getMonthsToOblivionReducer = (
  state = initialState,
  action = {},
) => {
  switch (action.type) {
    case `${GET_MONTHS_TO_OBLIVION}_PENDING`:
      return {
        ...state,
      };
    case `${GET_MONTHS_TO_OBLIVION}_SUCCESS`:
      return {
        ...state,
        successMonthsToOblivion: true,
        value: action.result,
      };
    case `${GET_MONTHS_TO_OBLIVION}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
      };
    default:
      return state;
  }
};
