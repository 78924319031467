import React from 'react';
import cx from 'classnames';
import { Card, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { Icon } from '@plone/volto/components';
import mosaicSVG from '@package/icons/squares_mosaic_blue.svg';
import { Button } from '@package/components';

import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  cta_placeholder: {
    id: 'Leggi tutto',
    defaultMessage: 'Approfondisci',
  },
});

const MosaicCard = (props) => {
  const intl = useIntl();
  const {
    title,
    description,
    card_eyelet,
    bar_color,
    have_cta,
    cta_color,
    background_type = 'white',
    image,
    cols = 3,
  } = props;

  return (
    <Card
      className={cx(`item mosaicCard cols-${cols}`, {
        backgroundBlue: background_type === 'blue',
        backgroundImage: background_type === 'image',
        backgroundMosaicImage:
          background_type === 'blue' || background_type === 'image',
      })}
      style={{
        backgroundImage:
          background_type === 'image' ? `url(${image})` : undefined,
      }}
    >
      <div
        className={cx({
          overlayBackground: background_type === 'image',
          flexOverlay: true,
        })}
      >
        <div
          className={cx({
            horiMosaicLine: true,
            barBackBlue: bar_color === 'blue',
            barBackWhite: bar_color === 'white' || background_type === 'blue',
            barBackOrange: bar_color === 'orange',
            barBackYellow: bar_color === 'yellow',
            barBackGreen: bar_color === 'green',
          })}
          aria-hidden="true"
        ></div>
        <h5
          className={cx({
            occhielloMosaicCard: true,
            barBlue: bar_color === 'blue',
            barWhite: bar_color === 'white' || background_type === 'blue',
            barOrange: bar_color === 'orange',
            barYellow: bar_color === 'yellow',
            barGreen: bar_color === 'green',
          })}
        >
          {card_eyelet?.toUpperCase() ?? ''}
        </h5>
        <Header
          as="h4"
          className={cx({
            mosaicCardHeader: true,
            whiteColorText:
              background_type === 'blue' || background_type === 'image',
          })}
        >
          <UniversalLink item={props}>{title}</UniversalLink>
        </Header>
        <p
          className={cx({
            mosaicCardContent: true,
            'item-text': true,
            whiteColorText:
              background_type === 'blue' || background_type === 'image',
            marginLong: true,
          })}
        >
          {description}
        </p>
        {have_cta && (
          <div className="mosaicButton">
            <Button
              as={UniversalLink}
              color={cta_color}
              size="large"
              item={props}
              arrow={true}
            >
              {intl.formatMessage(messages.cta_placeholder)}
            </Button>
          </div>
        )}
        {background_type === 'white' && (
          <Icon
            name={mosaicSVG}
            size={cols === 2 ? '80px' : '53px'}
            className="mosaicFooterSign"
          ></Icon>
        )}
      </div>
    </Card>
  );
};

MosaicCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  card_eyelet: PropTypes.string,
  bar_color: PropTypes.any,
  have_cta: PropTypes.bool,
  cta_color: PropTypes.string,
  image: PropTypes.any,
  background_type: PropTypes.string,
  cta_link: PropTypes.string,
  cta_testo: PropTypes.string,
};

export default MosaicCard;
