import React from 'react';
import { Form } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { SelectWidget, TextareaWidget } from '../../CVWidgets';
import { messages } from '../utils';

const ComputerSkillsField = (props) => {
  const {
    office_automation,
    operating_systems,
    programming_languages,
    graphic_design,
    setFieldValue,
    other_computer_skils,
    control,
    intl,
    isDisabled,
  } = props;

  const cvSchema = useSelector((state) => state.schema.schema);

  return (
    <div className="fields-container">
      <div className="education-add-container">
        <h4>{intl.formatMessage(messages.computerSkills)}</h4>
      </div>
      <Form.Group widths="2">
        <SelectWidget
          isDisabled={isDisabled}
          control={control}
          id="office_automation"
          title={intl.formatMessage(messages.officeAutomation)}
          value={office_automation}
          onChange={setFieldValue}
          choices={cvSchema?.properties.office_automation?.choices ?? []}
        />
        <SelectWidget
          isDisabled={isDisabled}
          control={control}
          id="operating_systems"
          title={intl.formatMessage(messages.osdbms)}
          value={operating_systems}
          onChange={setFieldValue}
          choices={cvSchema?.properties.operating_systems?.choices ?? []}
        />
        <SelectWidget
          isDisabled={isDisabled}
          control={control}
          id="programming_languages"
          title={intl.formatMessage(messages.programmingLanguages)}
          value={programming_languages}
          onChange={setFieldValue}
          choices={cvSchema?.properties.programming_languages?.choices ?? []}
        />
        <SelectWidget
          isDisabled={isDisabled}
          control={control}
          id="graphic_design"
          title={intl.formatMessage(messages.graphicDesign)}
          value={graphic_design}
          onChange={setFieldValue}
          choices={cvSchema?.properties.graphic_design?.choices ?? []}
        />
      </Form.Group>
      <Form.Group widths={'equal'} className="computer-skills-other-skills">
        <TextareaWidget
          isDisabled={isDisabled}
          id="other_computer_skils"
          type="text"
          name="other_computer_skils"
          defaultValue={other_computer_skils}
          onChange={setFieldValue}
          required={false}
          title={intl.formatMessage(messages.otherComputerSkills)}
          wrapped={true}
          control={control}
          placeholder={intl.formatMessage(messages.otherComputerSkills)}
        />
      </Form.Group>
    </div>
  );
};

export default ComputerSkillsField;
