import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { CheckboxWidget } from '@plone/volto/components';
import { TextBlockStyleSidebar } from '@package/components';
const messages = defineMessages({
  testo4alignLeft: {
    id: 'testo4alignLeft',
    defaultMessage: 'Allinea il titolo a sinistra',
  },
});

const Testo4Sidebar = (props) => {
  const { alignLeft, onChange } = props;
  const intl = useIntl();

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="Testo4" defaultMessage="Testo 4" />
        </h2>
      </header>

      <TextBlockStyleSidebar {...props} />

      <Segment className="form sidebar-listing-data">
        <CheckboxWidget
          id="alignLeft"
          title={intl.formatMessage(messages.testo4alignLeft)}
          value={alignLeft ?? false}
          onChange={onChange}
        />
      </Segment>
    </Segment.Group>
  );
};

Testo4Sidebar.propTypes = {
  fullWidth: PropTypes.bool,
  bg_color: PropTypes.string,
  title_color: PropTypes.string,
  share_social: PropTypes.bool,
  alignLeft: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Testo4Sidebar;
