import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/schemas/utils';

const messages = defineMessages({
  show_item_more_button: {
    id: 'show_item_more_button',
    defaultMessage: 'Mostra il bottone per il dettaglio della card',
  },
  cols: {
    id: 'Numero di colonne',
    defaultMessage: 'Numero di colonne',
  },
});

const getMosaicCardVariationSchema = (schema, formData, intl, pos = 0) => {
  addSchemaField(
    schema,
    'cols',
    intl.formatMessage(messages.cols),
    null,
    { type: 'number', default: 3 },
    pos,
    'default',
  );
  pos++;

  addSchemaField(
    schema,
    'have_cta',
    intl.formatMessage(messages.show_item_more_button),
    null,
    { type: 'boolean' },
    pos,
    'items',
  );
  pos++;

  return pos;
};

export default getMosaicCardVariationSchema;
