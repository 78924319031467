import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'ssb';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '381'}
      height={size ?? '393'}
      fill="none"
      viewBox="0 0 381 393"
      className={className}
      key={key}
      {...otherProps}
    >
      <path
        fill={`url(#${uniqueID}paint0_linear)`}
        d="M380.477 392.047H212.31V223.931h168.167v168.116zm-152.979-15.283h137.691v-137.65H227.498v137.65z"
      />
      <path
        fill={`url(#${uniqueID}paint1_linear)`}
        d="M50.037 102.261h98.223V.272H46.24v101.989h3.797zm90.629-94.397v86.805H53.834V7.864h86.832z"
      />
      <path
        fill={`url(#${uniqueID}paint2_linear)`}
        d="M303.537 223.929V42.226H.477v302.972h303.06V239.612h-22.882v82.711H23.359V65.001h257.296V223.93h22.882z"
      />
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="195.125"
          x2="397.617"
          y1="344.838"
          y2="271.115"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="35.815"
          x2="158.658"
          y1="73.621"
          y2="28.897"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="-30.493"
          x2="334.426"
          y1="260.119"
          y2="127.261"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Icon;
