import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

import { injectIntl } from 'react-intl';

const CheckboxWidget = (props) => {
  const { id, title, value, onChange, isDisabled, radio, checked } = props;
  const isChecked = () => {
    if (radio) return checked;
    else {
      if (value && checked !== null) return checked;
      else return value || false;
    }
  };
  return (
    <Checkbox
      name={`field-${id}`}
      className="rw--checkbox"
      checked={isChecked()}
      disabled={isDisabled}
      radio={radio}
      onChange={(event, { checked, radio, value }) => {
        if (radio) {
          onChange(id, value);
        } else onChange(id, value || checked);
      }}
      label={<label htmlFor={`field-${id}`}>{title}</label>}
      value={value}
    />
  );
};

CheckboxWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  radio: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

CheckboxWidget.defaultProps = {
  isDisabled: false,
  radio: false,
  value: null,
  onChange: null,
  checked: null,
};

export default injectIntl(CheckboxWidget);
