/**
 * SignWidget component.
 * @module components/Widgets/SignWidget
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid, Button, Accordion } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import upSVG from '@plone/volto/icons/up-key.svg';
import downSVG from '@plone/volto/icons/down-key.svg';

import { FormFieldWrapper, Icon } from '@plone/volto/components';
import { getSign, signs, DrawSign } from './signs';

const messages = defineMessages({
  labelCollapseItem: {
    id: 'Collapse item',
    defaultMessage: 'Collapse item',
  },
  labelShowItem: {
    id: 'Show item',
    defaultMessage: 'Show item',
  },
  select_sign: {
    id: 'Select sign from below list:',
    defaultMessage: "Seleziona un segno grafico dall'elenco sottostante:",
  },
  no_sign_selected: {
    id: 'No sign selected',
    defaultMessage: 'Nessun segno selezionato',
  },
});
/**
 * SignWidget component class.
 * @function SignWidget
 * @returns {string} Markup of the component.
 */
const SignWidget = (props) => {
  const intl = useIntl();
  const { value, onChange } = props; //id, title,isDisabled
  const [activeGroup, setActiveGroup] = useState(null);

  const drawSigns = (signs) => {
    return signs ? (
      <>
        <Grid centered verticalAlign="middle" className="signs">
          <Grid.Row columns={3}>
            {signs.map((sign, i) => {
              const key = new Date().getTime() + i;

              return (
                <Grid.Column
                  key={sign.id}
                  textAlign="center"
                  verticalAlign="middle"
                  stretched
                >
                  <Button
                    basic={!sign.white}
                    color={sign.white ? 'black' : null}
                    icon
                    onClick={() =>
                      onChange(props.id, sign.id === value ? null : sign.id)
                    }
                    className={cx({
                      selected: value === sign.id,
                      [sign.id]: true,
                    })}
                    key={sign.id}
                  >
                    <DrawSign
                      sign={sign.id}
                      size="25px"
                      className={'sidebar-icon ' + sign.id}
                      key={key}
                    />
                  </Button>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
      </>
    ) : (
      <></>
    );
  };

  const currentSign = props.value ? getSign(props.value) : null;

  return (
    <FormFieldWrapper {...props} columns={1}>
      <div className="widget-title">{props.title}</div>
      <div className="sign-widget-wrapper">
        {currentSign ? (
          <div className={`current-sign ${currentSign.white ? 'white' : ''}`}>
            <Icon
              name={currentSign.image}
              size="70px"
              className="sidebar-icon"
            />
          </div>
        ) : (
          <div className="current-sign placeholder">
            {intl.formatMessage(messages.no_sign_selected)}
          </div>
        )}
        <div className="small">{intl.formatMessage(messages.select_sign)}</div>
        <Accordion fluid styled>
          {Object.keys(signs).map((group_name, i) => {
            const group = signs[group_name];
            return (
              <div key={group_name}>
                <Accordion.Title
                  active={activeGroup === group_name}
                  index={i}
                  onClick={() => {
                    setActiveGroup(
                      activeGroup === group_name ? null : group_name,
                    );
                  }}
                  aria-label={`${
                    activeGroup === group_name
                      ? intl.formatMessage(messages.labelCollapseItem)
                      : intl.formatMessage(messages.labelShowItem)
                  }`}
                >
                  <div className="accordion-title-wrapper">{group.title}</div>
                  <div className="accordion-tools">
                    {activeGroup === group_name ? (
                      <Icon name={upSVG} size="20px" />
                    ) : (
                      <Icon name={downSVG} size="20px" />
                    )}
                  </div>
                </Accordion.Title>
                <Accordion.Content active={activeGroup === group_name}>
                  {group.base_version?.length > 0 && (
                    <div className="subgroup">
                      <div className="subgroup-title">Base</div>
                      {drawSigns(group.base_version)}
                    </div>
                  )}
                  {group.flat_version?.length > 0 && (
                    <div className="subgroup">
                      <div className="subgroup-title">Flat</div>
                      {drawSigns(group.flat_version)}
                    </div>
                  )}
                </Accordion.Content>
              </div>
            );
          })}
        </Accordion>
      </div>
    </FormFieldWrapper>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
SignWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.bool,
  onChange: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  wrapped: PropTypes.bool,
};

/**
 * Default properties.
 * @property {Object} defaultProps Default properties.
 * @static
 */
SignWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
  value: null,
  onChange: null,
  onEdit: null,
  onDelete: null,
};

export default SignWidget;
