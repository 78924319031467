import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { Button, Dropdown } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import cx from 'classnames';
import downSVG from '@plone/volto/icons/down-key.svg';

const messages = defineMessages({
  jobposting: {
    id: 'jobposting',
    defaultMessage: 'Job Postings',
  },
  bandi: {
    id: 'bandi',
    defaultMessage: 'Bandi',
  },
  tirocini: {
    id: 'tirocini',
    defaultMessage: 'Tirocini',
  },
});

const JobpostingListingTabs = (props) => {
  const { intl, onChangeTab, activeTab } = props;
  return (
    <>
      <div className="rw--jobposting-listing-tabs">
        <Button
          className={cx('rw--jobposting-listing-tabs-tab', {
            active: activeTab === 0,
          })}
          onClick={onChangeTab}
          key={0}
          value={0}
        >
          {intl.formatMessage(messages.jobposting)}
        </Button>
        <span className="tab-divider">|</span>
        <Button
          className={cx('rw--jobposting-listing-tabs-tab', {
            active: activeTab === 1,
          })}
          onClick={onChangeTab}
          key={1}
          value={1}
        >
          {intl.formatMessage(messages.bandi)}
        </Button>
        <span className="tab-divider">|</span>
        <Button
          className={cx('rw--jobposting-listing-tabs-tab', {
            active: activeTab === 2,
          })}
          onClick={onChangeTab}
          key={2}
          value={2}
        >
          {intl.formatMessage(messages.tirocini)}
        </Button>
      </div>
      <div className="rw--jobposting-listing-tabs mobile">
        <Dropdown
          options={[
            { key: 0, text: intl.formatMessage(messages.jobposting), value: 0 },
            { key: 1, text: intl.formatMessage(messages.bandi), value: 1 },
            { key: 2, text: intl.formatMessage(messages.tirocini), value: 2 },
          ]}
          onChange={(e, { value }) => {
            onChangeTab(e, { value });
          }}
          value={activeTab}
          closeOnChange
          className="rw--jobposting-listing-tabs-tab"
          icon={<Icon name={downSVG} size="31px" color="#000094" />}
          item
          selection
          scrolling={false}
        />
      </div>
    </>
  );
};

JobpostingListingTabs.propTypes = {
  onChangeTab: PropTypes.func,
  intl: PropTypes.object,
  activeTab: PropTypes.number,
};

export default JobpostingListingTabs;
