import React, { useState, useEffect, useRef } from 'react';
import PersonalInfosStep from './PersonalInfosStep/PersonalInfosStep';
import EducationStep from './EducationStep/EducationStep';
import KnowledgeStep from './KnowledgeStep/KnowledgeStep';
import WorkExperienceStep from './WorkExperienceStep/WorkExperienceStep';
import AdditionalInfosStep from './AdditionalInfosStep/AdditionalInfosStep';
import {
  Container,
  Form,
  Message,
  Dimmer,
  Modal,
  Header,
  Loader,
  Button as SButton,
} from 'semantic-ui-react';
import { useForm } from 'react-hook-form';
import {
  saveCV,
  resetSaveCV,
  getCvCustomFields,
  resetCvCustomFields,
} from '@package/actions';
import { useDispatch, useSelector } from 'react-redux';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Toast, Icon } from '@plone/volto/components';
import {
  prepareCVData,
  useYupValidationResolver,
  initializeFormData,
  scrollToStepTop,
  messages,
  validationSchema,
  resetFormData,
  getLongFieldLabel,
} from './utils.jsx';
import { Button } from '@package/components';
import { deleteContent, getSchema } from '@plone/volto/actions';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable';

import deleteSVG from '@package/icons/Bin.svg';

const Step = ({
  step,
  setFieldValue,
  formFields,
  nextStep,
  prevStep,
  control,
  errors,
  intl,
  isDisabled,
  customFieldsSchema,
}) => {
  switch (step) {
    case 1:
      return (
        <PersonalInfosStep
          {...formFields}
          setFieldValue={setFieldValue}
          control={control}
          intl={intl}
          isDisabled={isDisabled}
        />
      );
    case 2:
      return (
        <EducationStep
          {...formFields}
          setFieldValue={setFieldValue}
          control={control}
          intl={intl}
          isDisabled={isDisabled}
        />
      );
    case 3:
      return (
        <KnowledgeStep
          {...formFields}
          setFieldValue={setFieldValue}
          control={control}
          intl={intl}
          isDisabled={isDisabled}
        />
      );
    case 4:
      return (
        <WorkExperienceStep
          {...formFields}
          setFieldValue={setFieldValue}
          control={control}
          intl={intl}
          isDisabled={isDisabled}
        />
      );
    case 5:
      return (
        <AdditionalInfosStep
          {...formFields}
          setFieldValue={setFieldValue}
          control={control}
          intl={intl}
          isDisabled={isDisabled}
          customFieldsSchema={customFieldsSchema}
        />
      );
    default:
      return null;
  }
};

const CVFormManager = (props) => {
  const {
    content,
    intl,
    setIsEditing,
    isEditing,
    setSuccessfulSave,
    handleApplication,
    isUserProfile = true,
    closeReviewModal,
    formDisabled,
    toastify,
  } = props;
  const [formFields, setFormFields] = useState(
    initializeFormData(isUserProfile ? content : props.data),
  );
  const [step, setStep] = useState(1);
  const saveCVState = useSelector((state) => state.saveCV);
  const contentState = useSelector((state) => state.content);
  const { items: customFieldsSchema, get: getCustomFieldsState } = useSelector(
    (state) => state.cvCustomFields,
  );
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmDelete, openConfirmDelete] = useState(false);
  const [autosaveEnabled, setAutosaveEnabled] = useState(false);
  const resolver = useYupValidationResolver(validationSchema(intl), intl);
  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields },
    trigger,
    setValue,
    getValues,

    clearErrors,
  } = useForm({
    defaultValues: formFields,
    resolver,
    mode: 'onChange',
  });

  const searchable_cv = getValues('searchable_cv');
  const dismissForm = () => {
    setIsEditing(false);
    setStep(1);
  };
  useEffect(() => {
    dispatch(getSchema('CV'));
  }, [dispatch]);

  useEffect(() => {
    if (saveCVState?.error) {
      toastify.toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.error)}
          content={intl.formatMessage(messages.saveError)}
        />,
      );
    }
    if (!saveCVState?.error && saveCVState?.loading) {
      !autosaveEnabled &&
        !toastify.toast.isActive('cv-loading') &&
        toastify.toast.info(
          <Toast
            info
            title={intl.formatMessage({ id: 'Loading' })}
            content={intl.formatMessage(messages.cvLoading)}
          />,
          { id: 'cv-loading' },
        );
    }
    if (
      saveCVState?.result &&
      !saveCVState?.error &&
      !saveCVState?.loading &&
      saveCVState?.loaded
    ) {
      // update parent content

      // isUserProfile && updateContent(saveCVState?.result);
      setFormFields(initializeFormData(saveCVState?.result));

      // close delete modal if present and reenable forms, no longer submitting
      openConfirmDelete(false);
      setIsSubmitting(false);

      if (!autosaveEnabled) {
        if (toastify.toast.isActive('cv-loading'))
          toastify.toast.dismiss('cv-loading');
        // close form and show success message, dedupe it with id if already present
        !toastify.toast.isActive('save-cv-success') &&
          Object.keys(errors).length === 0 &&
          toastify.toast.success(
            <Toast
              success
              title={intl.formatMessage(messages.success)}
              content={intl.formatMessage(messages.saveSuccess)}
            />,
            {
              toastId: 'save-cv-success',
            },
          );

        Object.keys(errors).length > 0 &&
          toastify.toast.error(
            <Toast
              error
              title={intl.formatMessage(messages.error)}
              content={intl.formatMessage(messages.validationError)}
            />,
          );
        if (saveCVState?.result?.cv_sent_date !== content?.cv_sent_date) {
          setSuccessfulSave(true);
        }
      }
    }
    dispatch(resetSaveCV());
    // setIsSubmitting(saveCVState?.loading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    saveCVState?.result,
    saveCVState?.error,
    saveCVState?.loading,
    saveCVState?.loaded,
    autosaveEnabled,
  ]);

  useEffect(() => {
    if (contentState?.delete?.error) {
      toastify.toast.error(
        <Toast
          error
          title={intl.formatMessage(messages.error)}
          content={intl.formatMessage(messages.saveError)}
        />,
      );
    }

    setIsSubmitting(contentState?.delete?.loading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contentState?.delete?.error,
    contentState?.delete?.loading,
    contentState?.delete?.loaded,
  ]);

  const setFieldValue = (fieldName, value, shouldValidate) => {
    const fieldHasToBeValidated =
      shouldValidate !== undefined
        ? shouldValidate
        : [
            'gender',
            'birth_province',
            'birth_place',
            'nationality',
            'marital_state',
            'driving_license',
            'residency_address',
            'residency_province',
            'residency_municipality',
            'residency_postal_code',
            // 'domicile_address',
            // 'domicile_province',
            // 'domicile_municipality',
            // 'domicile_postal_code',
            'email',
            'mobile',
            'tax_code',
            'date_of_birth',
            // 'image',
            'personal_cv',
            // 'high_school',
            // 'university',
            // 'other_studies',
          ].includes(fieldName);

    // Also trim values if it's a string input type
    setValue(fieldName, typeof value === 'string' ? value.trim() : value, {
      shouldValidate: fieldHasToBeValidated,
      shouldTouch: true,
    });
    setFormFields((formFields) => ({ ...formFields, [fieldName]: value }));
  };
  const autosaveOnChangeStep = async (action) => {
    let validationPass;
    if (!formDisabled) {
      if (!autosaveEnabled) setAutosaveEnabled(true);

      validationPass = await onSubmit(
        JSON.parse(searchable_cv?.token?.toLowerCase()),
        undefined,
        true,
      );
    }
    if (formDisabled || validationPass) {
      if (action === 'next') nextStep();
      else if (action === 'prev') prevStep();
    }
  };

  const nextStep = () => {
    setStep(step + 1);
    ref.current !== null && ref.current.focus();
    scrollToStepTop(ref.current);
  };
  const prevStep = () => {
    setStep(step - 1);
    ref.current !== null && ref.current.focus();
    scrollToStepTop(ref.current);
  };

  const onSubmit = async (
    validation = true,
    dismissForm = undefined,
    noValidation = false,
  ) => {
    const url = isUserProfile ? content['@id'] : props?.data?.['@id'];
    if (validation) {
      const validationOutput = await trigger();

      if (noValidation) {
        setValue(
          'searchable_cv',
          { title: 'Sì', token: 'True' },
          {
            shouldValidate: false,
            shouldTouch: false,
          },
        );
        dispatch(
          saveCV(flattenToAppURL(url), {
            ...prepareCVData(formFields, touchedFields),
            cv_sent_date: new Date(),
            searchable_cv: 'True',
          }),
        );
        return noValidation;
      } else if (validationOutput) {
        setIsSubmitting(true);
        setValue(
          'searchable_cv',
          { title: 'Sì', token: 'True' },
          {
            shouldValidate: false,
            shouldTouch: false,
          },
        );
        dispatch(
          saveCV(flattenToAppURL(url), {
            ...prepareCVData(formFields, touchedFields),
            cv_sent_date: new Date(),
            searchable_cv: 'True',
          }),
        );
        if (handleApplication && step === 5) {
          await handleApplication();
        }
        if (dismissForm) {
          dismissForm();
        }
      }
      return validationOutput;
    } else {
      setIsSubmitting(true);
      dispatch(
        saveCV(flattenToAppURL(url), {
          ...prepareCVData(formFields, touchedFields),
          searchable_cv: 'False',
        }),
      );
      if (dismissForm) {
        dismissForm();
      }
      return true;
    }
  };

  const deleteCV = () => {
    setSuccessfulSave(false);
    setIsSubmitting(true);
    setAutosaveEnabled(false);
    const children = content?.items;
    if (children?.length > 0) {
      dispatch(
        deleteContent(children.map((item) => flattenToAppURL(item['@id']))),
      );
      // updateContent({ ...content, items: [] });
    }

    const newContent = resetFormData(content);
    dispatch(
      saveCV(flattenToAppURL(content['@id']), {
        ...prepareCVData(
          newContent,
          Object.keys(formFields).reduce((acc, field) => {
            acc[field] = true;
            return acc;
          }, {}),
        ),
        items: [],
      }),
    );
    clearErrors();
    dismissForm();
  };

  useEffect(() => {
    if (isEditing || isSubmitting) scrollToStepTop(ref.current);
  }, [isEditing, isSubmitting]);

  // get custom fields
  useEffect(() => {
    dispatch(getCvCustomFields());
    return () => dispatch(resetCvCustomFields());
  }, [dispatch]);

  const saveCVAction = async (validation, dismiss = false, reset = false) => {
    setAutosaveEnabled(false);
    if (dismiss) await handleSubmit(onSubmit(!!validation, dismissForm));
    else await handleSubmit(onSubmit(!!validation));
    if (reset) clearErrors();
  };

  return isEditing ? (
    <section id="user-cv-form" className="grey-bg">
      <Dimmer.Dimmable
        blurring
        dimmed={isSubmitting || getCustomFieldsState.loading}
      >
        <Dimmer active={isSubmitting || getCustomFieldsState.loading} inverted>
          <Loader>{intl.formatMessage(messages.loading)}</Loader>
        </Dimmer>
        <Container>
          <div className="formHeading">
            <h3 className="cvTitle">{intl.formatMessage(messages.cvOnline)}</h3>
            <div className="stepCounter">
              <span>Step</span>
              <span className="currentStepCounter">{step}</span>
              <span className="totalStepCounter">{'/5'}</span>
            </div>
          </div>

          <Form>
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
            <span ref={ref} id="scroll-anchor" tabIndex="0"></span>
            <Step
              step={step}
              setFieldValue={setFieldValue}
              formFields={formFields}
              control={control}
              intl={intl}
              isDisabled={formDisabled}
              customFieldsSchema={customFieldsSchema}
            />
          </Form>
          {Object.keys(errors).length > 0 && (
            <Message className="validation-errors">
              <Message.Header>
                {intl.formatMessage(messages.validationError)}
              </Message.Header>
              <Message.List>
                {Object.keys(errors)?.map((error) => (
                  <Message.Item>{getLongFieldLabel(error, intl)}</Message.Item>
                ))}
              </Message.List>
            </Message>
          )}
          <div className="cv-form-actions-container">
            <div className="cv-form-actions-left">
              {isUserProfile && !formDisabled && (
                <Modal
                  basic
                  onClose={() => openConfirmDelete(false)}
                  open={confirmDelete}
                  onOpen={() => {
                    scrollToStepTop(ref.current);
                    openConfirmDelete(true);
                  }}
                  className="delete-modal"
                  trigger={
                    <SButton
                      icon
                      className="rw--button removeCV"
                      disabled={isSubmitting}
                    >
                      <Icon
                        name={deleteSVG}
                        size="24px"
                        color="#ffffff"
                        className="removeCVIcon"
                      />
                      <span>{intl.formatMessage(messages.delete)}</span>
                    </SButton>
                  }
                >
                  <Header>{`${intl.formatMessage(messages.deleteCV)}`}</Header>
                  <Modal.Content>
                    <p>{intl.formatMessage(messages.deleteConfirm)}</p>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      className="rw--button rw--button-outline rw--button-primary"
                      onClick={() => openConfirmDelete(false)}
                    >
                      {intl.formatMessage(messages.cancel)}
                    </Button>
                    <Button
                      className="rw--button removeCV cancelAction"
                      onClick={() => deleteCV()}
                    >
                      {intl.formatMessage(messages.confirm)}
                    </Button>
                  </Modal.Actions>
                </Modal>
              )}
              {isUserProfile &&
                !formDisabled &&
                searchable_cv.token === 'False' && (
                  <Button
                    className="rw--button rw--button-outline saveAsDraft"
                    onClick={async () => await saveCVAction(false, true, true)}
                    disabled={isSubmitting}
                  >
                    {intl.formatMessage(messages.saveDraft)}
                  </Button>
                )}
              {!isUserProfile && (
                <Button
                  className="rw--button rw--button-outline rw--button-primary saveAsDraft"
                  onClick={closeReviewModal}
                >
                  {intl.formatMessage(messages.cancel)}
                </Button>
              )}
            </div>
            <div className="cv-form-actions-right">
              <Button
                className="rw--button rw--button-outline"
                onClick={async () => {
                  await autosaveOnChangeStep('prev');
                }}
                arrow={true}
                reverse={true}
                disabled={step === 1 || isSubmitting}
              >
                {intl.formatMessage(messages.prevStep)}
              </Button>
              {step !== 5 || (step === 5 && formDisabled) ? (
                <Button
                  arrow={true}
                  className="rw--button rw--button-primary"
                  onClick={async () => {
                    if (step) {
                      await autosaveOnChangeStep('next');
                    }
                  }}
                  disabled={formDisabled ? step === 5 : isSubmitting}
                >
                  {intl.formatMessage(messages.nextStep)}
                </Button>
              ) : isUserProfile ? (
                <Button
                  arrow={true}
                  className="rw--button rw--button-primary"
                  onClick={async () => await saveCVAction(true, true)}
                  disabled={isSubmitting}
                >
                  {intl.formatMessage(messages.save)}
                </Button>
              ) : (
                <Button
                  arrow={true}
                  className="rw--button rw--button-primary"
                  onClick={async () => {
                    await saveCVAction(true, true);
                  }}
                  disabled={isSubmitting}
                >
                  {intl.formatMessage(messages.apply)}
                </Button>
              )}
            </div>
          </div>
        </Container>
      </Dimmer.Dimmable>
    </section>
  ) : null;
};

export default injectLazyLibs(['toastify'])(CVFormManager);
