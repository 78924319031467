import React from 'react';
import RWHeaderDesktop from './HeaderDesktop/RWHeaderDesktop';
import RWHeaderMobile from './HeaderMobile/RWHeaderMobile';

const RWHeader = ({ pathname }) => {
  return (
    <>
      <RWHeaderDesktop pathname={pathname} />
      <RWHeaderMobile pathname={pathname} />
    </>
  );
};

export default RWHeader;
