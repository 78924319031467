import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function isFromLinkedin() {
  const date = window.localStorage.getItem('from_linkedin_date');

  return (
    window.localStorage.getItem('from_linkedin') === 'true' &&
    date &&
    parseInt(date, 10) > new Date().valueOf() - 1000 * 60 * 60 * 24
  );
}

const LinkedinTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('utm_source') === 'linkedin') {
      window.localStorage.setItem('from_linkedin', 'true');
      window.localStorage.setItem(
        'from_linkedin_date',
        new Date().valueOf().toString(),
      );
    }
  }, [location.search]);

  return null;
};

export default LinkedinTracker;
