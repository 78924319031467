import { SAVE_CV } from '@package/actions/saveCV';

const initialState = {
  loaded: false,
  loading: false,
  error: null,
  success: false,
  result: null,
};

export const saveCVReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${SAVE_CV}_PENDING`:
      return {
        ...state,
        loaded: false,
        loading: true,
        error: null,
        success: false,
      };
    case `${SAVE_CV}_SUCCESS`:
      return {
        ...state,
        success: true,
        result: action.result,
        loading: false,
        loaded: true,
        error: null,
      };
    case `${SAVE_CV}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case 'RESET_SAVE_CV':
      return initialState;
    default:
      return state;
  }
};
