import React, { useState } from 'react';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Button as SButton, Modal, Header, Loader } from 'semantic-ui-react';
import { Icon, Toast } from '@plone/volto/components';
import { Button } from '@package/components';
import { logout, purgeMessages } from '@plone/volto/actions';
import { useDispatch } from 'react-redux';
import { userOblivion, resetUser } from '@package/actions';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import deleteSVG from '@package/icons/Bin.svg';

const messages = defineMessages({
  deleteUser: {
    id: 'deleteUser',
    defaultMessage: 'Elimina il tuo profilo su Rai Way',
  },
  deleteUserText: {
    id: 'deleteUserText',
    defaultMessage:
      'Sei sicuro di voler eliminare il tuo profilo su Rai Way? Verranno cancellati anche i tuoi CV e le tue informazioni personali, oltre a tutte le candidature che hai presentato.',
  },
  confirm: {
    id: 'confirm',
    defaultMessage: 'Conferma',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Annulla',
  },
  success: {
    id: 'success',
    defaultMessage: 'Successo',
  },
  successDelete: {
    id: 'successDelete',
    defaultMessage: 'Il tuo profilo è stato eliminato con successo',
  },
});

const UserOblivionModal = ({ setIsEditing, isEditing, intl, userId }) => {
  const [deleteUser, openDeleteUser] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  // logout the user and reset the state
  const doLogout = async () => {
    await dispatch(logout());
    await dispatch(purgeMessages());
    await dispatch(resetUser());
  };
  // handle user oblivion
  const handleUserOblivion = async () => {
    setLoading(true);
    await dispatch(userOblivion(userId));
    await doLogout();
    toast.success(
      <Toast
        success
        title={intl.formatMessage(messages.success)}
        content={intl.formatMessage(messages.successDelete)}
      />,
    );
    setLoading(false);
    history.push('/');
  };
  return userId ? (
    <Modal
      basic
      onClose={() => {
        openDeleteUser(false);
        setIsEditing(false);
      }}
      open={deleteUser}
      onOpen={() => openDeleteUser(true)}
      className="delete-modal"
      trigger={
        <SButton icon onClick={() => openDeleteUser(true)}>
          <Icon name={deleteSVG} size="24px" color="#000094" />
        </SButton>
      }
    >
      <Header>{`${intl.formatMessage(messages.deleteUser)}`}</Header>
      <Modal.Content>
        {`${intl.formatMessage(messages.deleteUserText)}`}
      </Modal.Content>
      <Modal.Actions>
        <Button
          className="rw--button rw--button-outline rw--button-primary"
          onClick={() => openDeleteUser(false)}
        >
          {intl.formatMessage(messages.cancel)}
        </Button>
        <Button
          className="rw--button rw--button-outline rw--button-loading"
          onClick={() => handleUserOblivion()}
        >
          {!loading ? (
            intl.formatMessage({ id: 'Confirm' })
          ) : (
            <Loader inline={'centered'} active={loading} size="tiny" />
          )}
        </Button>
      </Modal.Actions>
    </Modal>
  ) : null;
};

UserOblivionModal.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

export default UserOblivionModal;
