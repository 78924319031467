import React from 'react';
import { Form, Button, Popup, Icon } from 'semantic-ui-react';
import {
  TextWidget,
  SelectWidget,
  DatetimeWidgetComponent,
  FormFieldWrapper,
  CheckboxWidget,
} from '../../CVWidgets';
import { messages } from '../utils';
import cx from 'classnames';

const initialLanguagesState = (languages) =>
  Array.isArray(languages)
    ? languages
    : [
        {
          language: '',
          language_other: '',
          level: '',
          have_certificate: { token: 'False', title: 'No' },
          certificate: '',
          certificate_aquired_date: '',
          vote: '',
        },
      ];

const LanguageFields = (props) => {
  const { languages, setFieldValue, control, intl, isDisabled } = props;
  const languagesState = initialLanguagesState(languages);

  const handleOnChange = (id, value, langIndex) => {
    const newLanguagesState = languagesState?.map((item, i) => {
      if (langIndex === i) {
        if (id === 'language')
          return {
            ...item,
            [id]: {
              title: value,
              token: value,
            },
          };
        if (id === 'have_certificate')
          return {
            ...item,
            [id]: {
              title: value,
              token: value === 'Yes' ? 'True' : 'False',
            },
          };
        else return { ...item, [id]: value };
      } else {
        return item;
      }
    });
    setFieldValue('languages', newLanguagesState, false);
  };

  const addNewLanguage = () => {
    const newLanguagesState = [
      ...(languagesState ?? []),
      ...initialLanguagesState({}),
    ];
    setFieldValue('languages', newLanguagesState, false);
  };
  const removeLanguage = (langIndex) => {
    const newLanguagesState = languagesState.filter(
      (item, i) => langIndex !== i,
    );

    setFieldValue('languages', newLanguagesState, false);
  };

  return (
    <div className="fields-container">
      <div className="education-add-container">
        <h4>{intl.formatMessage(messages.languages)}</h4>
      </div>
      {languagesState?.map((language, langIndex) => (
        <div className="form-fields-container">
          <h4>{`${intl.formatMessage(messages.language)} ${langIndex + 1}`}</h4>

          <Form.Group widths={'equal'}>
            <SelectWidget
              isDisabled={isDisabled}
              id="language"
              title={intl.formatMessage(messages.language)}
              value={language?.language}
              onChange={(id, value) => handleOnChange(id, value, langIndex)}
              vocabBaseUrl={'raiway.lavoraconnoi.CVLanguages'}
              noValueOption={false}
              required={false}
              control={control}
            />

            <SelectWidget
              isDisabled={isDisabled || language?.language?.token === 'Altro'}
              id="level"
              title={intl.formatMessage(messages.level)}
              value={language?.level}
              onChange={(id, value) => handleOnChange(id, value, langIndex)}
              choices={[
                ['scolastico', intl.formatMessage(messages.scolastico)],
                ['medio', intl.formatMessage(messages.medio)],
                ['avanzato', intl.formatMessage(messages.advanced)],
                ['madrelingua', intl.formatMessage(messages.madrelingua)],
              ]}
              noValueOption={false}
              required={false}
              control={control}
              className={cx('', {
                otherLanguageSelected: language?.language?.token === 'Altro',
              })}
            />
          </Form.Group>
          {language?.language?.token === 'Altro' && (
            <Form.Group widths={'equal'}>
              <TextWidget
                isDisabled={isDisabled}
                id="language_other"
                type="text"
                name="language.language_other"
                value={language?.language_other}
                onChange={(id, value) => handleOnChange(id, value, langIndex)}
                required={false}
                title={intl.formatMessage(messages.languageOther)}
                wrapped={true}
                control={control}
              />
              <SelectWidget
                isDisabled={isDisabled}
                id="level"
                title={intl.formatMessage(messages.level)}
                value={language?.level}
                onChange={(id, value) => handleOnChange(id, value, langIndex)}
                choices={[
                  ['scolastico', intl.formatMessage(messages.scolastico)],
                  ['medio', intl.formatMessage(messages.medio)],
                  ['avanzato', intl.formatMessage(messages.advanced)],
                  ['madrelingua', intl.formatMessage(messages.madrelingua)],
                ]}
                noValueOption={false}
                required={false}
                control={control}
              />
            </Form.Group>
          )}
          <Form.Group widths={'equal'}>
            <FormFieldWrapper
              id="have_certificate"
              title={intl.formatMessage(messages.haveCertificate)}
              wrapped
              className=""
              control={control}
            >
              <CheckboxWidget
                isDisabled={isDisabled}
                id="have_certificate"
                radio={true}
                value={'Yes'}
                title={intl.formatMessage(messages.yes)}
                checked={language?.have_certificate?.token === 'True'}
                onChange={(id, value) => handleOnChange(id, value, langIndex)}
              />
              <CheckboxWidget
                isDisabled={isDisabled}
                id="have_certificate"
                radio={true}
                value={'No'}
                title={intl.formatMessage(messages.no)}
                checked={language?.have_certificate?.token === 'False'}
                onChange={(id, value) => handleOnChange(id, value, langIndex)}
              />
            </FormFieldWrapper>
            <TextWidget
              isDisabled={isDisabled}
              id="certificate"
              type="text"
              name="language.certificate"
              value={language?.certificate}
              onChange={(id, value) => handleOnChange(id, value, langIndex)}
              required={false}
              title={intl.formatMessage(messages.certificate)}
              wrapped={true}
              control={control}
              popup={
                <Popup
                  trigger={<Icon name="question circle" />}
                  hoverable
                  on={['click', 'hover']}
                  content={intl.formatMessage(messages.certificatePopup, {
                    miurLink: (
                      <a
                        href="https://www.miur.gov.it/enti-certificatori-lingue-straniere"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.formatMessage(messages.here)}
                      </a>
                    ),
                  })}
                  position="top left"
                  hideOnScroll={true}
                />
              }
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DatetimeWidgetComponent
              isDisabled={isDisabled}
              id="certificate_aquired_date"
              title={intl.formatMessage(messages.certificateDate)}
              required={false}
              onChange={(id, value) => handleOnChange(id, value, langIndex)}
              wrapped={true}
              value={language?.certificate_aquired_date}
              dateOnly={true}
              control={control}
            />
            <TextWidget
              isDisabled={isDisabled}
              id="vote"
              type="text"
              name="language.vote"
              value={language?.vote}
              onChange={(id, value) => handleOnChange(id, value, langIndex)}
              required={false}
              title={intl.formatMessage(messages.vote)}
              wrapped={true}
              control={control}
            />
          </Form.Group>
          {!isDisabled && (
            <Button
              className={cx('rw--button remove-fieldset removeCV', {
                active: langIndex !== 0,
              })}
              onClick={() => removeLanguage(langIndex)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          )}
        </div>
      ))}
      <div className="add-new-fieldset">
        {!isDisabled && (
          <Button
            className="rw--button rw--button-outline"
            onClick={addNewLanguage}
          >
            {intl.formatMessage(messages.add, {
              context: intl.formatMessage(messages.language),
            })}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LanguageFields;
