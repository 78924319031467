import React, { useEffect, useState } from 'react';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Button } from '@package/components';
import { UniversalLink } from '@plone/volto/components';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { getUser } from '@plone/volto/actions';

import CVModal from './CVModal';

const messages = defineMessages({
  backLinkTextLabel: {
    id: 'backLinkTextLabel',
    defaultMessage: 'Torna alla lista',
  },
  registerLinkTextLabel: {
    id: 'registerLinkTextLabel',
    defaultMessage: 'Invia la candidatura',
  },
  applied: {
    id: 'applied',
    defaultMessage: 'Candidatura inviata',
  },
});

const Body = (props) => {
  const intl = useIntl();
  const { data, properties, moment: momentLib } = props;
  const { backLinkText, registerLinkText } = data;
  const location = useLocation();
  const dispatch = useDispatch();
  const moment = momentLib.default;

  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );
  const userLogged = useSelector((state) => state.users.user);
  const userLoggedSt = useSelector((state) => state.users);

  const [applied, setApplied] = useState(
    properties?.job_posting_cta?.already_submitted ?? false,
  );

  useEffect(() => {
    if (!userLoggedSt?.get?.loading && userId) {
      dispatch(getUser(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const renderApplicationCTA = () => {
    const hasNotExpired = moment(properties.data_scadenza).isAfter(moment());

    const jp_cta = properties?.job_posting_cta;
    if (jp_cta) {
      if (jp_cta.is_anonymous && hasNotExpired) {
        return (
          <Button
            arrow={true}
            as={userLogged?.home_page ? '' : UniversalLink}
            href={`/login?return_url=${encodeURIComponent(
              location.pathname,
            )}&keep_navigation=true`}
            key={'registerLinkAnonymous'}
          >
            {registerLinkText ??
              intl.formatMessage(messages.registerLinkTextLabel)}
          </Button>
        );
      } else if (!applied && hasNotExpired) {
        return <CVModal intl={intl} jp={properties} setApplied={setApplied} />;
      } else if (applied) {
        return (
          <Button
            arrow={false}
            disabled={true}
            key="registerLink"
            className="rw--button-green"
          >
            {intl.formatMessage(messages.applied)}
          </Button>
        );
      }
    }
    return null;
  };

  return (
    <div className="rw--jobposting-ctas">
      <Button
        as={UniversalLink}
        href={location.pathname.split('/').slice(0, -1).join('/')}
        key={'backLink'}
        arrow={true}
        color={'outline-blu'}
        reverse={true}
      >
        {backLinkText ?? intl.formatMessage(messages.backLinkTextLabel)}
      </Button>
      {renderApplicationCTA()}
    </div>
  );
};

export default injectLazyLibs('moment')(Body);
