import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'sp1b';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '632'}
      height={size ?? '283'}
      fill="none"
      viewBox="0 0 632 283"
      className={className}
      key={key}
      {...otherProps}
    >
      <g clipPath={`url(#${uniqueID}clip0)`}>
        <path
          fill={`url(#${uniqueID}paint0_linear)`}
          d="M193.71 249.722V72.029h178.272v177.693H193.71zm9.625-168.186v158.592h159.11V81.536h-159.11z"
        />
        <path
          fill={`url(#${uniqueID}paint1_linear)`}
          d="M298.076 109.503h115.7v116.009h-115.7V109.503zm107.4 8.322h-99.1v99.365h99.1v-99.365z"
        />
        <path
          fill={`url(#${uniqueID}paint2_linear)`}
          d="M446.977 139.984h78.001v78.209h-78.001v-78.209zm72.501 5.515h-66.901v67.079h66.901v-67.079z"
        />
        <path
          fill={`url(#${uniqueID}paint3_linear)`}
          d="M469.977 179.691h-86.8V92.659h86.8v87.032zm-79.9-6.919h73V99.577h-73v73.195z"
        />
        <path
          fill={`url(#${uniqueID}paint4_linear)`}
          d="M58.163.914h58.4v62.065h-58.4v-6.918h51.5V7.933h-51.5V.913z"
        />
        <path
          fill={`url(#${uniqueID}paint5_linear)`}
          d="M53.578 62.98h6.9V.913h-6.9v62.065z"
        />
        <path
          fill={`url(#${uniqueID}paint6_linear)`}
          d="M267.277 282.164H15.712V29.926h251.565v252.238zM28.724 269.022H254.17V43.068H28.724v225.954z"
        />
        <path
          fill={`url(#${uniqueID}paint7_linear)`}
          d="M459.708 241.184v-47.086h47.239v47.086h-47.239zm4.212-42.887v38.788h38.915v-38.788H463.92z"
        />
        <path
          fill={`url(#${uniqueID}paint8_linear)`}
          d="M571.676 186.408h-31.9v-31.985h31.9v31.985zm-29.1-2.707h26.3v-26.37h-26.3v26.37z"
        />
        <path
          fill={`url(#${uniqueID}paint9_linear)`}
          d="M510.507 168.591v-47.086h47.24v47.086h-47.24zm4.112-42.987v38.788h38.916v-38.788h-38.916z"
        />
        <path
          fill={`url(#${uniqueID}paint10_linear)`}
          d="M599.077 144.998h-29.8v-29.779h29.8v29.779zm-27.7-2.106h25.6v-25.668h-25.6v25.668z"
        />
        <path
          fill={`url(#${uniqueID}paint11_linear)`}
          d="M614.977 133.868h-29.8v-29.879h29.8v29.879zm-27.7-2.105h25.6v-25.669h-25.6v25.669z"
        />
      </g>
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="243.772"
          x2="321.458"
          y1="53.871"
          y2="268.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="425.6"
          x2="286.187"
          y1="142.08"
          y2="192.687"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="532.948"
          x2="438.963"
          y1="161.946"
          y2="196.063"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint3_linear`}
          className="paint_linear"
          x1="374.307"
          x2="478.896"
          y1="155.251"
          y2="117.285"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint4_linear`}
          className="paint_linear"
          x1="123"
          x2="47.417"
          y1="18.343"
          y2="46.261"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint5_linear`}
          className="paint_linear"
          x1="123"
          x2="47.417"
          y1="18.343"
          y2="46.261"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint6_linear`}
          className="paint_linear"
          x1="-9.995"
          x2="293.127"
          y1="211.332"
          y2="101.298"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint7_linear`}
          className="paint_linear"
          x1="472.973"
          x2="493.559"
          y1="189.286"
          y2="246.03"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint8_linear`}
          className="paint_linear"
          x1="536.517"
          x2="574.954"
          y1="177.426"
          y2="163.473"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint9_linear`}
          className="paint_linear"
          x1="523.773"
          x2="544.359"
          y1="116.693"
          y2="173.437"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint10_linear`}
          className="paint_linear"
          x1="566.232"
          x2="602.111"
          y1="136.636"
          y2="123.568"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint11_linear`}
          className="paint_linear"
          x1="582.132"
          x2="618.039"
          y1="125.478"
          y2="112.443"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <clipPath id={`${uniqueID}clip0`}>
          <path
            fill="#fff"
            d="M0 0H631V281.751H0z"
            transform="translate(0.476562 0.913574)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Icon;
