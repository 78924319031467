import { Form, Button } from 'semantic-ui-react';
import { messages } from '../utils';
import { TextWidget, DatetimeWidgetComponent } from '../../CVWidgets';

const OtherStudiesFields = ({
  other_studies,
  setFieldValue,
  control,
  intl,
  isDisabled,
}) => {
  const currentState = Array.isArray(other_studies) ? other_studies : [];

  const handleOnChange = (id, value, index) => {
    const newState = currentState.map((item, i) => {
      if (index === i) {
        return { ...item, [id]: value };
      }
      return item;
    });
    setFieldValue('other_studies', newState, false);
  };

  const addNewGroup = () => {
    setFieldValue('other_studies', [
      ...currentState,
      {
        title: '',
        name: '',
        start_date: '',
        end_date: '',
      },
    ]);
  };

  const removeGroup = (index) => {
    setFieldValue(
      'other_studies',
      currentState.filter((item, i) => i !== index),
    );
  };

  return (
    <div>
      <div className="education-add-container">
        <h4>{intl.formatMessage(messages.otherStudiesTitle)}</h4>
      </div>
      {currentState.map((item, index) => (
        <div className="form-fields-container" key={index}>
          <h5>
            {intl.formatMessage(messages.otherStudiesSubtitle, {
              index: index + 1,
            })}
          </h5>
          <Form.Group widths="equal">
            <TextWidget
              id={`other_studies[${index}].title`}
              name={`other_studies[${index}].title`}
              type="text"
              title={intl.formatMessage(messages.otherStudiesDegreeTitle)}
              value={item.title}
              onChange={(id, value) => handleOnChange('title', value, index)}
              required={true}
              wrapped={true}
              control={control}
              isDisabled={isDisabled}
            />
            <TextWidget
              id={`other_studies[${index}].name`}
              name={`other_studies[${index}].name`}
              type="text"
              title={intl.formatMessage(messages.otherStudiesName)}
              value={item.name}
              onChange={(id, value) => handleOnChange('name', value, index)}
              required={false}
              wrapped={true}
              control={control}
              isDisabled={isDisabled}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DatetimeWidgetComponent
              isDisabled={isDisabled}
              id={`other_studies[${index}].start_date`}
              title={intl.formatMessage(messages.startDate)}
              required={true}
              onChange={(id, value) =>
                handleOnChange('start_date', value, index)
              }
              wrapped={true}
              value={item.start_date}
              dateOnly={true}
              control={control}
            />
            <DatetimeWidgetComponent
              isDisabled={isDisabled}
              id={`other_studies[${index}].end_date`}
              title={intl.formatMessage(messages.endDateShort)}
              required={true}
              onChange={(id, value) => handleOnChange('end_date', value, index)}
              wrapped={true}
              value={item.end_date}
              dateOnly={true}
              control={control}
            />
          </Form.Group>
          {!isDisabled && (
            <Button
              className="rw--button remove-fieldset removeCV active"
              onClick={() => removeGroup(index)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          )}
        </div>
      ))}
      <div className="add-new-fieldset">
        {!isDisabled && (
          <Button
            className="rw--button rw--button-outline"
            onClick={addNewGroup}
          >
            {intl.formatMessage(messages.add, {
              context: intl.formatMessage(messages.otherStudiesTitle),
            })}
          </Button>
        )}
      </div>
    </div>
  );
};

export default OtherStudiesFields;
