import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/schemas/utils';
import { addPresetFields } from '@package/helpers/presets';

const messages = defineMessages({
  title: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
  cols: {
    id: 'Numero di colonne',
    defaultMessage: 'Numero di colonne',
  },
});

const getPhotogalleryVariationSchema = (schema, formData, intl, pos = 0) => {
  addSchemaField(
    schema,
    'cols',
    intl.formatMessage(messages.cols),
    null,
    { type: 'number', default: 3 },
    pos,
    'default',
  );
  pos++;

  pos = addPresetFields(schema, intl, pos, 'items', {
    title_color: { colors: ['blue', 'grey'] },
  });

  return pos;
};

export default getPhotogalleryVariationSchema;
