import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, defineMessages } from 'react-intl';
import { Card, Header } from 'semantic-ui-react';
import Moment from 'moment';
import cx from 'classnames';

import { UniversalLink } from '@plone/volto/components';
import { Button, ListingImage } from '@package/components';

const messages = defineMessages({
  readMore: {
    id: 'readMore',
    defaultMessage: 'Leggi di più',
  },
});

const NewsCard = (props) => {
  const intl = useIntl();
  const {
    title,
    description,
    card_eyelet, // default "News"
    tags,
    bar_color,
    effective,
    show_link_more,
    have_image,
  } = props;

  let image = have_image
    ? ListingImage({ item: props, className: 'imageCard item-image' })
    : null;

  let occhiello = tags?.length > 0 ? tags[0] : card_eyelet ?? '';

  return (
    <Card className="newsCard item">
      <div className={`card-header ${image ? 'has-image' : ''}`}>
        {image && <>{image}</>}
        <div
          className={cx('horizontal-line', {
            barBackBlue: bar_color === 'blue',
            barBackOrange: bar_color === 'orange',
            barBackYellow: bar_color === 'yellow',
            barBackGreen: bar_color === 'green',
          })}
          ria-hidden="true"
        ></div>
      </div>
      <div
        className={cx('occhiello', {
          ocBlue: bar_color === 'blue',
          ocOrange: bar_color === 'orange',
          ocYellow: bar_color === 'yellow',
          ocGreen: bar_color === 'green',
        })}
      >
        {occhiello.toUpperCase()}
      </div>
      <Header as="h5" className="item-header">
        <UniversalLink item={props}>{title}</UniversalLink>
      </Header>
      <div className="item-content">
        <div className="item-text">
          {effective && (
            <div className="dateTime">
              {Moment(effective).format('DD.MM.YYYY | HH.mm')}{' '}
            </div>
          )}
          {!image && <p>{description}</p>}
        </div>

        {show_link_more && (
          <div className="item-button">
            <Button as={UniversalLink} size="big" item={props} arrow={true}>
              {intl.formatMessage(messages.readMore)}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

NewsCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  tags: PropTypes.array,
  cardEyelet: PropTypes.string,
  image: PropTypes.any,
  show_link_more: PropTypes.bool,
  effective: PropTypes.string,
};

export default NewsCard;
