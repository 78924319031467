import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import {
  ListingHeader,
  ListingLinkMore,
  PresetWrapper,
} from '@package/components';
import { defineMessages, useIntl } from 'react-intl';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { getFileViewFormat } from '@package/helpers/files';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const messages = defineMessages({
  open_file: {
    id: 'apri',
    defaultMessage: 'Apri',
  },
});

const DownloadListing = (props) => {
  const intl = useIntl();
  moment.locale(intl.locale);
  const { items, isEditMode } = props;
  const defaultIcon = { lib: 'far', name: 'file' };

  /* 
  la proprietà props['@type'] al di fuori del blocco elenco non viene restituito, 
  lo sfruttiamo a favore del blocco "Ricerca faccette" per impostare un nostro aspetto di default
  */

  return (
    <PresetWrapper
      {...props}
      className={cx('download-listing', { show_date: props.show_date })}
    >
      <div className="block-content">
        <ListingHeader {...props} />

        {items.length > 0 && (
          <ul
            className={cx('rw--download-link', {
              'list-style': props.show_bullet_list || !props['@type'],
            })}
          >
            {items.map((item, index) => {
              let icon = null;
              if (item['@type'] === 'File' || item['@type'] === 'Image') {
                const viewFormatItem = getFileViewFormat(item);
                icon = viewFormatItem?.icon || defaultIcon;
              }

              let open_file_url = null;
              if (item?.attachment?.length > 0) {
                open_file_url = `${item.attachment[0]['@id']}/@@display-file/file`;
              }

              return (
                <li key={index}>
                  <div
                    className={cx('infos', {
                      bulletBlue: item.alternative_color === 'blue',
                      bulletOrange: item.alternative_color === 'orange',
                      bulletYellow: item.alternative_color === 'yellow',
                      bulletGreen: item.alternative_color === 'green',
                    })}
                  >
                    {icon && (props.show_icon || !props['@type']) && (
                      <FontAwesomeIcon icon={[icon.lib, icon.name]} />
                    )}
                    <ConditionalLink
                      className="item-title"
                      condition={!isEditMode}
                      item={item}
                    >
                      {item.title}
                    </ConditionalLink>
                  </div>

                  {/* Attachment of news item */}
                  {!props['@type'] &&
                    item['@type'] === 'News Item' &&
                    item?.attachment?.length > 0 && (
                      <div
                        className="ui attachment-news"
                        data-tooltip={
                          intl.formatMessage(messages.open_file) +
                          ': ' +
                          item.attachment[0].title
                        }
                      >
                        <UniversalLink href={flattenToAppURL(open_file_url)}>
                          <FontAwesomeIcon icon={['fal', 'file-pdf']} />
                        </UniversalLink>
                      </div>
                    )}
                  {item.Date && (props.show_date || !props['@type']) && (
                    <div className="date">
                      {moment(item.Date).format('DD.MM.YYYY')}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        )}

        <ListingLinkMore {...props} />
      </div>
    </PresetWrapper>
  );
};

DownloadListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
};

export default DownloadListing;
