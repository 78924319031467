import { defineMessages } from 'react-intl';

const messages = defineMessages({
  bsize_all: {
    id: 'bsize_all',
    defaultMessage: 'Tutti',
  },
});

const listingTabs = {
  0: 'jobposting',
  1: 'bando',
  2: 'tirocinio',
};

const defaultBSizes = [
  { key: 1, text: '10', value: 10 },
  { key: 2, text: '25', value: 25 },
  { key: 3, text: '50', value: 50 },
];

const initialQuery = {
  query: [
    {
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['JobPosting'],
    },
    {
      i: 'tipologia_jobposting',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['jobposting'],
    },
    {
      i: 'review_state',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['published'],
    },
  ],
  sort_order: 'descending',
  sort_on: 'effective',
  metadata_fields: '_all',
  b_size: 10,
  b_start: 0,
};

const initializeBSizes = (intl) =>
  [
    ...defaultBSizes,
    {
      key: 10000,
      text: intl.formatMessage(messages.bsize_all),
      value: 10000,
    },
  ].sort((a, b) => a.value - b.value);

const refocus = (isEditMode, listingRef) => {
  if (!isEditMode) {
    // deal with sticky header in a browser compatible way
    const headerOffset = 100;
    const elementPosition = listingRef.current.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};

const calculateCurrentShown = (currentPage, bSize, totalItems) =>
  currentPage * bSize < totalItems ? currentPage * bSize : totalItems;

export {
  listingTabs,
  initializeBSizes,
  initialQuery,
  refocus,
  calculateCurrentShown,
};
