import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import ctaArrowSVG from '@package/icons/cta-arrow.svg';

const ImportedUserData = ({ content }) => {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const {
    imported_skill_edu_formazione,
    imported_skill_edu_scuola_comprensiva,
    imported_skill_skl_competenze,
    imported_skill_wrk_esperienze_lavorative,
  } = content;

  if (
    !imported_skill_edu_formazione &&
    !imported_skill_edu_scuola_comprensiva &&
    !imported_skill_skl_competenze &&
    !imported_skill_wrk_esperienze_lavorative
  ) {
    return null;
  }

  return (
    <section
      id="imported-user-data"
      className={cx('grey-bg', {
        open: isOpen,
      })}
    >
      <h3 className="imported-cv-title">
        <button
          className="imported-cv-toggle"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {intl.formatMessage(messages.imported_data_title)}
          <Icon name={ctaArrowSVG} className="arrow-icon" size="22px" />
        </button>
      </h3>
      <div className="imported-cv-content">
        {imported_skill_edu_formazione && (
          <div className="imported-cv-item">
            <p className="imported-cv-item-title">
              {intl.formatMessage(messages.imported_skill_edu_formazione)}
            </p>
            <p className="imported-cv-item-content">
              {imported_skill_edu_formazione}
            </p>
          </div>
        )}
        {imported_skill_edu_scuola_comprensiva && (
          <div className="imported-cv-item">
            <p className="imported-cv-item-title">
              {intl.formatMessage(
                messages.imported_skill_edu_scuola_comprensiva,
              )}
            </p>
            <p className="imported-cv-item-content">
              {imported_skill_edu_scuola_comprensiva}
            </p>
          </div>
        )}
        {imported_skill_skl_competenze && (
          <div className="imported-cv-item">
            <p className="imported-cv-item-title">
              {intl.formatMessage(messages.imported_skill_skl_competenze)}
            </p>
            <p className="imported-cv-item-content">
              {imported_skill_skl_competenze}
            </p>
          </div>
        )}
        {imported_skill_wrk_esperienze_lavorative && (
          <div className="imported-cv-item">
            <p className="imported-cv-item-title">
              {intl.formatMessage(
                messages.imported_skill_wrk_esperienze_lavorative,
              )}
            </p>
            <p className="imported-cv-item-content">
              {imported_skill_wrk_esperienze_lavorative}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default ImportedUserData;

const messages = defineMessages({
  imported_data_title: {
    id: 'imported_data_title',
    defaultMessage: 'Visualizza dati CV precedentemente caricato',
  },
  imported_skill_edu_formazione: {
    id: 'imported_skill_edu_formazione',
    defaultMessage: 'Formazione',
  },
  imported_skill_edu_scuola_comprensiva: {
    id: 'imported_skill_edu_scuola_comprensiva',
    defaultMessage: 'Scuola comprensiva',
  },
  imported_skill_skl_competenze: {
    id: 'imported_skill_skl_competenze',
    defaultMessage: 'Competenze',
  },
  imported_skill_wrk_esperienze_lavorative: {
    id: 'imported_skill_wrk_esperienze_lavorative',
    defaultMessage: 'Esperienze lavorative',
  },
});
