/**
 * Add your action types here.
 * @module constants/ActionTypes
 * @example
 * export const UPDATE_CONTENT = 'UPDATE_CONTENT';
 */

export const RESET_SEARCH_CV_CONTENT = 'RESET_SEARCH_CV_CONTENT';
export const SEARCH_CV_CONTENT = 'SEARCH_CV_CONTENT';

export const GET_CV_SAVED_SEARCHES = 'GET_CV_SEARCH';
export const SAVE_CV_SEARCH = 'SAVE_CV_SEARCH';
export const RESET_CV_SAVED_SEARCHES = 'RESET_CV_SEARCH';

export const DELETE_CVS = 'DELETE_CVS';
export const RESET_DELETE_CVS = 'RESET_DELETE_CVS';

export const ADD_MULTI_COMMENT = 'ADD_MULTI_COMMENT';
export const LIST_MULTI_COMMENT = 'LIST_MULTI_COMMENT';
export const RESET_MULTI_COMMENT = 'RESET_MULTI_COMMENT';

export const GET_CV_CUSTOM_FIELDS = 'GET_CV_CUSTOM_FIELDS';
export const SAVE_CV_CUSTOM_FIELDS = 'SAVE_CV_CUSTOM_FIELDS';
export const RESET_CV_CUSTOM_FIELDS = 'RESET_CV_CUSTOM_FIELDS';
