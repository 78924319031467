/**
 * View video block.
 * @module components/manage/Blocks/Video/View
 *
 * CUSTOMIZATIONS:
 * - aggiunto campo per il titolo del video
 * - aggiunto campo per la didascalia del video
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Body from './Body';

/**
 * View video block class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => (
  <div
    className={cx(
      'block video align',
      {
        center: !Boolean(data.align),
      },
      data.align,
    )}
  >
    {data.title && <h3>{data.title}</h3>}
    <Body data={data} />
    {data.description && <p>{data.description}</p>}
  </div>
);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
