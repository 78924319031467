import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'ssfg';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '381'}
      height={size ?? '393'}
      fill="none"
      viewBox="0 0 381 393"
      className={className}
      key={key}
      {...otherProps}
    >
      <path
        fill={`url(#${uniqueID}paint0_linear)`}
        d="M380.068 392.196H211.9V224.078h168.168v168.118zm-152.98-15.284H364.78V239.261H227.088v137.651z"
      />
      <path
        fill={`url(#${uniqueID}paint1_linear)`}
        d="M49.629 102.409h98.222V.419H45.832v101.99h3.797zm90.628-94.398v86.806H53.426V8.01h86.831z"
      />
      <path
        fill={`url(#${uniqueID}paint2_linear)`}
        d="M303.128 224.077V42.374H.068v302.971h303.06V239.76h-22.882v82.71H22.949V65.15h257.297v158.927h22.882z"
      />
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="380.057"
          x2="211.971"
          y1="308.148"
          y2="308.148"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="147.845"
          x2="45.875"
          y1="51.421"
          y2="51.421"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="303.109"
          x2=".194"
          y1="193.88"
          y2="193.88"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Icon;
