import { addPresetFields } from '@package/helpers/presets';

const getJobPostingVariationSchema = (schema, formData, intl, position = 0) => {
  let pos = position;

  pos = addPresetFields(schema, intl, pos, 'items', {
    bg_color: { colors: ['light-grey'] },
  });

  return pos;
};

export default getJobPostingVariationSchema;
