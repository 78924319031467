import React from 'react';
import Body from './Body';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';
import { Container } from 'semantic-ui-react';
import cx from 'classnames';

const Edit = (props) => {
  const { selected } = props;
  if (__SERVER__) {
    return <div />;
  }
  return (
    <div
      className={cx('rw--jobposting-ctas-wrapper', {
        'grey-bg': true,
      })}
    >
      <Container>
        <Body {...props} isEditMode={true} />
      </Container>
      <SidebarPortal selected={selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </div>
  );
};

export default Edit;
