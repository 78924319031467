/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import { customCSSReducer } from './customCSSReducer';
import { saveCVReducer } from './saveCVReducer';
import searchCv from './searchCV';
import { userOblivionReducer } from './userOblivionReducer';
import { getMonthsToOblivionReducer } from './monthsToOblivionReducer';
import { savedCvSearches, saveCvSearch } from './savedCvSearch';
import { deleteCVsReducer } from './deleteCVsReducer';
import users from './users';
import multiComments from './multiComments';
import { cvCustomFields } from './cvCustomFields';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  // Add your reducers here
  customCSS: customCSSReducer,
  saveCV: saveCVReducer,
  searchCv,
  userOblivion: userOblivionReducer,
  monthsToOblivion: getMonthsToOblivionReducer,
  saveCvSearch,
  savedCvSearches,
  deleteCVs: deleteCVsReducer,
  users: users,
  multiComments,
  cvCustomFields,
};

export default reducers;
