import { v4 as uuid } from 'uuid';

/*SQUARES PRIMARY*/
import SquaresPrimary from '@package/icons/squares_primary.jsx';
import SquaresPrimaryFlat from '@package/icons/squares_primary_flat.jsx';

/*SQUARES 1 PRIMARY*/

import SquaresPrimary1 from '@package/icons/squares_primary_1.jsx';
import SquaresPrimary1Flat from '@package/icons/squares_primary_1_flat.jsx';

/*SQUARES SMALL*/
import SquaresSmallPrimary from '@package/icons/squares_small_primary.jsx';
import SquaresSmallPrimaryFlat from '@package/icons/squares_small_primary_flat.jsx';

/*SQUARES SECONDARY*/
import SquaresSecondary from '@package/icons/squares_secondary.jsx';
import SquaresSecondaryFlat from '@package/icons/squares_secondary_flat.jsx';

/*SQUARES 1 SECONDARY*/
import SquaresSecondary1 from '@package/icons/squares_secondary_1.jsx';
import SquaresSecondary1Flat from '@package/icons/squares_secondary_1_flat.jsx';

export const signs = {
  primary_big: {
    title: 'Primario grande',
    base_version: [
      { id: 'SquaresPrimaryBlue', image: SquaresPrimary },
      {
        id: 'SquaresPrimaryOrange',
        image: SquaresPrimary,
      },
      {
        id: 'SquaresPrimaryGreen',
        image: SquaresPrimary,
      },
      {
        id: 'SquaresPrimaryYellow',
        image: SquaresPrimary,
      },
      {
        id: 'SquaresPrimaryPink',
        image: SquaresPrimary,
      },
      {
        id: 'SquaresPrimaryLightBlue',
        image: SquaresPrimary,
      },
    ],
    flat_version: [
      {
        id: 'SquaresPrimaryFlatYellow',
        image: SquaresPrimaryFlat,
      },
      {
        id: 'SquaresPrimaryFlatPink',
        image: SquaresPrimaryFlat,
      },
      {
        id: 'SquaresPrimaryFlatLightBlue',
        image: SquaresPrimaryFlat,
      },
      {
        id: 'SquaresPrimaryFlatGreen',
        image: SquaresPrimaryFlat,
      },
      {
        id: 'SquaresPrimaryFlatOrange',
        image: SquaresPrimaryFlat,
      },
      {
        id: 'SquaresPrimaryFlatWhite',
        image: SquaresPrimaryFlat,
        white: true,
      },
    ],
  },
  primary_big1: {
    title: 'Primario grande 2',
    base_version: [
      { id: 'SquaresPrimary1Blue', image: SquaresPrimary1 },
      {
        id: 'SquaresPrimary1Orange',
        image: SquaresPrimary1,
      },
      {
        id: 'SquaresPrimary1Green',
        image: SquaresPrimary1,
      },
      {
        id: 'SquaresPrimary1Yellow',
        image: SquaresPrimary1,
      },
      {
        id: 'SquaresPrimary1Pink',
        image: SquaresPrimary1,
      },
      {
        id: 'SquaresPrimary1LightBlue',
        image: SquaresPrimary1,
      },
    ],
    flat_version: [
      {
        id: 'SquaresPrimary1FlatYellow',
        image: SquaresPrimary1Flat,
      },
      {
        id: 'SquaresPrimary1FlatPink',
        image: SquaresPrimary1Flat,
      },
      {
        id: 'SquaresPrimary1FlatLightBlue',
        image: SquaresPrimary1Flat,
      },
      {
        id: 'SquaresPrimary1FlatGreen',
        image: SquaresPrimary1Flat,
      },
      {
        id: 'SquaresPrimary1FlatOrange',
        image: SquaresPrimary1Flat,
      },
      {
        id: 'SquaresPrimary1FlatWhite',
        image: SquaresPrimary1Flat,
        white: true,
      },
    ],
  },
  primary_small: {
    title: 'Primario piccolo',
    base_version: [
      { id: 'SquaresSmallPrimaryBlue', image: SquaresSmallPrimary },
      {
        id: 'SquaresSmallPrimaryOrange',
        image: SquaresSmallPrimary,
      },
      {
        id: 'SquaresSmallPrimaryGreen',
        image: SquaresSmallPrimary,
      },
      {
        id: 'SquaresSmallPrimaryYellow',
        image: SquaresSmallPrimary,
      },
      {
        id: 'SquaresSmallPrimaryPink',
        image: SquaresSmallPrimary,
      },
      {
        id: 'SquaresSmallPrimaryLightBlue',
        image: SquaresSmallPrimary,
      },
    ],
    flat_version: [
      {
        id: 'SquaresSmallPrimaryFlatYellow',
        image: SquaresSmallPrimaryFlat,
      },
      {
        id: 'SquaresSmallPrimaryFlatPink',
        image: SquaresSmallPrimaryFlat,
      },
      {
        id: 'SquaresSmallPrimaryFlatLightBlue',
        image: SquaresSmallPrimaryFlat,
      },
      {
        id: 'SquaresSmallPrimaryFlatGreen',
        image: SquaresSmallPrimaryFlat,
      },
      {
        id: 'SquaresSmallPrimaryFlatOrange',
        image: SquaresSmallPrimaryFlat,
      },
      {
        id: 'SquaresSmallPrimaryFlatWhite',
        image: SquaresSmallPrimaryFlat,
        white: true,
      },
    ],
  },
  secondary: {
    title: 'Secondario',
    base_version: [
      { id: 'SquaresSecondaryBlue', image: SquaresSecondary },
      {
        id: 'SquaresSecondaryOrange',
        image: SquaresSecondary,
      },
      {
        id: 'SquaresSecondaryGreen',
        image: SquaresSecondary,
      },
      {
        id: 'SquaresSecondaryYellow',
        image: SquaresSecondary,
      },
      {
        id: 'SquaresSecondaryPink',
        image: SquaresSecondary,
      },
      {
        id: 'SquaresSecondaryLightBlue',
        image: SquaresSecondary,
      },
    ],
    flat_version: [
      {
        id: 'SquaresSecondaryFlatYellow',
        image: SquaresSecondaryFlat,
      },
      {
        id: 'SquaresSecondaryFlatPink',
        image: SquaresSecondaryFlat,
      },
      {
        id: 'SquaresSecondaryFlatLightBlue',
        image: SquaresSecondaryFlat,
      },
      {
        id: 'SquaresSecondaryFlatGreen',
        image: SquaresSecondaryFlat,
      },
      {
        id: 'SquaresSecondaryFlatOrange',
        image: SquaresSecondaryFlat,
      },
      {
        id: 'SquaresSecondaryFlatWhite',
        image: SquaresSecondaryFlat,
        white: true,
      },
    ],
  },
  secondary1: {
    title: 'Secondario 2',
    base_version: [
      { id: 'SquaresSecondary1Blue', image: SquaresSecondary1 },
      {
        id: 'SquaresSecondary1Orange',
        image: SquaresSecondary1,
      },
      {
        id: 'SquaresSecondary1Green',
        image: SquaresSecondary1,
      },
      {
        id: 'SquaresSecondary1Yellow',
        image: SquaresSecondary1,
      },
      {
        id: 'SquaresSecondary1Pink',
        image: SquaresSecondary1,
      },
      {
        id: 'SquaresSecondary1LightBlue',
        image: SquaresSecondary1,
      },
    ],
    flat_version: [
      {
        id: 'SquaresSecondary1FlatYellow',
        image: SquaresSecondary1Flat,
      },
      {
        id: 'SquaresSecondary1FlatPink',
        image: SquaresSecondary1Flat,
      },
      {
        id: 'SquaresSecondary1FlatLightBlue',
        image: SquaresSecondary1Flat,
      },
      {
        id: 'SquaresSecondary1FlatGreen',
        image: SquaresSecondary1Flat,
      },
      {
        id: 'SquaresSecondary1FlatOrange',
        image: SquaresSecondary1Flat,
      },
      {
        id: 'SquaresSecondary1FlatWhite',
        image: SquaresSecondary1Flat,
        white: true,
      },
    ],
  },
};

export const getSign = (id) => {
  let list = [];
  Object.keys(signs).forEach((group_name) => {
    let g = signs[group_name];
    list = [...list, ...g.base_version, ...g.flat_version];
  });

  let sign = list.filter((s) => s.id === id);

  if (sign?.length > 0) {
    return sign[0];
  }
  return null;
};

export const getSignGroup = (id) => {
  let group = null;
  Object.keys(signs).forEach((group_name) => {
    if (!group) {
      let g = signs[group_name];
      const list = [...g.base_version, ...g.flat_version];
      let sign = list.filter((s) => s.id === id);
      group = sign?.length > 0 ? group_name : null;
    }
  });

  return group;
};

export const DrawSign = ({ sign, ...otherProps }) => {
  const id = uuid();

  return sign
    ? getSign(sign).image({
        ...otherProps,
        className: sign + ' ' + (otherProps.className ?? ''),
        id: id,
        key: otherProps.svgKey,
      })
    : null;
};
