import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import {
  MosaicCard,
  ListingHeader,
  ListingLinkMore,
  PresetWrapper,
} from '@package/components';

const MosaicCardListing = (props) => {
  const { items, cols = 3 } = props;
  return (
    <PresetWrapper {...props} className="mosaic-card-template">
      <ListingHeader {...props} />
      <Grid columns={cols} stackable>
        <Grid.Row>
          {items.map((item) => {
            return (
              <Grid.Column key={item['@id']}>
                <MosaicCard
                  {...props}
                  {...item}
                  title={item.title_override || item.title}
                  description={item.description_override || item.description}
                  bar_color={
                    item.background === 'blue'
                      ? 'white'
                      : item.alternative_color
                  }
                  cta_color={
                    item.background === 'blue'
                      ? 'outline-white'
                      : item.background === 'image'
                      ? item.alternative_color
                      : 'outline-grey'
                  }
                  background_type={item.background}
                  image={`${item['@id']}/@@images/${
                    item.image_field || 'image'
                  }/preview`}
                ></MosaicCard>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>
      <ListingLinkMore {...props} />
    </PresetWrapper>
  );
};

MosaicCardListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  cols: PropTypes.number,
};
export default MosaicCardListing;
