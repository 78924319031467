import React, { useEffect, useState } from 'react';
import UserInfos from './components/UserInfos';
import { defineMessages, useIntl } from 'react-intl';
import UserApplications from './components/UserApplications';
import UserMessages from './components/UserMessages';
import ImportedUserData from './components/ImportedUserData';
import CVFormManager from '../CVForm/CVFormManager';
import { useDispatch, useSelector } from 'react-redux';
import { Message, Icon, Container, Dimmer, Loader } from 'semantic-ui-react';
import jwtDecode from 'jwt-decode';
import { resetSaveCV, getMonthsToOblivion } from '@package/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  candidature: {
    id: 'candidature',
    defaultMessage: 'Le tue adesioni',
  },
  candidature_admin: {
    id: 'candidature_admin',
    defaultMessage: 'Candidature',
  },
  user_profile: {
    id: 'user_profile',
    defaultMessage: 'Profilo personale',
  },
  user_profile_admin: {
    id: 'user_profile_admin',
    defaultMessage: 'Profilo candidato',
  },
  comunicazioni: {
    id: 'comunicazioni',
    defaultMessage: 'Comunicazioni da Rai Way',
  },
  successfulSave: {
    id: 'successfulSave',
    defaultMessage:
      'Il tuo curriculum vitae è stato salvato ed inviato correttamente. Grazie. \n',
  },
  successfulSaveInfo: {
    id: 'successfulSaveInfo',
    defaultMessage:
      "Ti ricordiamo che La candidatura on-line ha una validità di {monthsToOblivionData} mesi, dal momento del primo inoltro e/o dell'ultimo aggiornamento effettuato. Grazie. \n",
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading...',
  },
});

const UserProfileView = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [content, setContent] = useState(props?.content);
  const saveCVState = useSelector((state) => state.saveCV);
  const contentState = useSelector((state) => state.content.data);
  const contentLoading = useSelector((state) => state.content.get);
  const [fullyLoaded, setFullyLoaded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [successfulSave, setSuccessfulSave] = useState(false);
  const ref = React.useRef(null);
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );
  const isCreator = userId && props?.content?.creators?.includes(userId);

  useEffect(() => {
    if (
      contentState['@id'] !== content?.['@id'] ||
      contentState['@id'] !== props?.content?.['@id']
    ) {
      setContent(contentState);
    } else {
      if (
        contentLoading.loaded &&
        !contentLoading.loading &&
        contentState['@id'] === content?.['@id'] &&
        flattenToAppURL(contentState['@id']) === props?.location?.pathname &&
        !saveCVState.loading
      )
        setFullyLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props?.location?.pathname,
    content,
    contentState,
    contentLoading,
    saveCVState,
  ]);

  useEffect(() => {
    if (!isEditing) {
      dispatch(resetSaveCV());
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useDeepCompareEffect(() => {
    if (saveCVState.result && saveCVState.success) {
      setContent(saveCVState.result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCVState]);

  const applications = content?.items.filter(
    (item) => item['@type'] === 'Application',
  );

  // get months to oblivion data
  useEffect(() => {
    dispatch(getMonthsToOblivion());
  }, [dispatch]);

  const monthsToOblivion = useSelector((state) => state.monthsToOblivion.value);

  return (
    <Dimmer.Dimmable blurring dimmed={!fullyLoaded}>
      <Dimmer active={!fullyLoaded} inverted>
        <Loader>{intl.formatMessage(messages.loading)}</Loader>
      </Dimmer>
      <Container className="user-profile">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
        <span ref={ref} id="scroll-anchor-top" tabIndex="0"></span>
        {successfulSave && (
          <Message
            icon
            id="successCVSave"
            floating
            aria-live="polite"
            onDismiss={() => setSuccessfulSave(false)}
          >
            <Icon name="check" />
            <Message.Header>
              {intl.formatMessage(messages.successfulSave)}
            </Message.Header>
            <Message.Content>
              {intl.formatMessage(messages.successfulSaveInfo, {
                monthsToOblivionData: monthsToOblivion,
              })}
            </Message.Content>
          </Message>
        )}

        <section id="user-profile-header">
          <h3 className="title">
            {isCreator
              ? intl.formatMessage(messages.user_profile)
              : intl.formatMessage(messages.user_profile_admin)}
          </h3>

          <UserInfos
            {...props}
            content={content}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setContent={setContent}
            isCreator={isCreator}
            userId={userId}
          />
        </section>

        <ImportedUserData content={content} />

        <CVFormManager
          {...{
            ...props,
            intl,
            content,
            setIsEditing,
            isEditing,
            setSuccessfulSave,
          }}
          updateContent={setContent}
          formDisabled={!isCreator}
        />
        <section id="applicationsListing">
          <h3 className="applicationsTitle">
            {isCreator
              ? intl.formatMessage(messages.candidature)
              : intl.formatMessage(messages.candidature_admin)}
          </h3>
          <UserApplications items={applications} isCreator={isCreator} />
        </section>
        <UserMessages applications={applications} />
      </Container>
    </Dimmer.Dimmable>
  );
};

export default UserProfileView;
