/**
 * Search reducer.
 * @module reducers/search/search
 */

import { map, omit } from 'lodash';
import { flattenToAppURL } from '@plone/volto/helpers';

import {
  RESET_SEARCH_CV_CONTENT,
  SEARCH_CV_CONTENT,
} from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  items: [],
  total: 0,
  loaded: false,
  loading: false,
  batching: {},
  subrequests: {},
};

/**
 * Search reducer.
 * @function search
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
export default function searchCv(state = initialState, action = {}) {
  switch (action.type) {
    case `${SEARCH_CV_CONTENT}_PENDING`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                ...(state.subrequests[action.subrequest] || {
                  items: [],
                  total: 0,
                  batching: {},
                }),
                error: null,
                loaded: false,
                loading: true,
              },
            },
          }
        : {
            ...state,
            error: null,
            loading: true,
            loaded: false,
          };
    case `${SEARCH_CV_CONTENT}_SUCCESS`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: null,
                items: map(action.result.items, (item) => ({
                  ...item,
                  '@id': flattenToAppURL(item['@id']),
                  ...(item.cv_absolute_url
                    ? {
                        cv_absolute_url: flattenToAppURL(item.cv_absolute_url),
                      }
                    : {}),
                  ...(item.printed_cv_link
                    ? {
                        printed_cv_link: flattenToAppURL(item.printed_cv_link),
                      }
                    : {}),
                })),
                total: action.result.items_total,
                loaded: true,
                loading: false,
                batching: { ...action.result.batching },
              },
            },
          }
        : {
            ...state,
            error: null,
            items: map(action.result.items, (item) => ({
              ...item,
              '@id': flattenToAppURL(item['@id']),
              ...(item.cv_absolute_url
                ? {
                    cv_absolute_url: flattenToAppURL(item.cv_absolute_url),
                  }
                : {}),
              ...(item.printed_cv_link
                ? {
                    printed_cv_link: flattenToAppURL(item.printed_cv_link),
                  }
                : {}),
            })),
            total: action.result.items_total,
            loaded: true,
            loading: false,
            batching: { ...action.result.batching },
          };
    case `${SEARCH_CV_CONTENT}_FAIL`:
      return action.subrequest
        ? {
            ...state,
            subrequests: {
              ...state.subrequests,
              [action.subrequest]: {
                error: action.error,
                items: [],
                total: 0,
                loading: false,
                loaded: false,
                batching: {},
              },
            },
          }
        : {
            ...state,
            error: action.error,
            items: [],
            total: 0,
            loading: false,
            loaded: false,
            batching: {},
          };
    case RESET_SEARCH_CV_CONTENT:
      return action.subrequest
        ? {
            ...state,
            subrequests: omit(state.subrequests, [action.subrequest]),
          }
        : {
            ...state,
            error: null,
            items: [],
            total: 0,
            loading: false,
            loaded: false,
            batching: {},
          };
    default:
      return state;
  }
}
