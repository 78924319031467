import React, { useEffect, useState, createRef } from 'react';
import cx from 'classnames';
//import { useSelector } from 'react-redux';
import { Container, Segment } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import {
  //Anontools,
  Logo,
  Navigation,
  //SearchWidget,
} from '@plone/volto/components';
import { SecondaryMenu } from 'volto-secondarymenu';
import RWHeaderMobileSmall from './RWHeaderMobileSmall';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

const RWHeaderDesktop = ({ pathname }) => {
  //const token = useSelector((state) => state.userSession.token);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const intl = useIntl();
  const [sticky, setSticky] = useState(false);
  const [width, setWidth] = useState(0);
  const headerRef = createRef();
  const handleSticky = () => {
    setSticky(window.scrollY > 35);
  };

  useEffect(() => {
    setWidth(headerRef.current.clientWidth);
    window.addEventListener('scroll', handleSticky, false);
    return () => {
      window.removeEventListener('scroll', handleSticky, false);
    };
  }, []);

  //style={sticky ? { width: width + 'px' } : {}}
  const getAnchorTarget = (nodeElement) => {
    if (nodeElement.nodeName === 'A') {
      return nodeElement;
    } else if (nodeElement.parentElement?.nodeName === 'A') {
      return nodeElement.parentElement;
    } else {
      return null;
    }
  };

  useEffect(() => {
    const clickListener = (e) => {
      const targetItem = getAnchorTarget(e.target);
      const dropdownmenuLinks = [
        ...document.querySelectorAll(
          '.mobile-menu .navigation-dropdownmenu .dropdown-menu-wrapper ul li a,.mobile-menu .navigation-dropdownmenu .dropdown-menu-wrapper h2 a, .mobile-menu .navigation-dropdownmenu .dropdownmenu-blocks-column a, .mobile-menu .dropdownmenu-footer a, .mobile-menu .navigation-dropdownmenu .menu > a, .mobile-menu .secondary-menu .menu > a, .mobile-menu .mobile-menu-logo a',
        ),
      ];

      if (
        dropdownmenuLinks?.length > 0 &&
        dropdownmenuLinks?.indexOf(targetItem) >= 0
      ) {
        setIsMobileMenuOpen(false); //close mobile menu
      }
    };

    document.body.addEventListener('click', clickListener, { passive: true });

    return () => document.body.removeEventListener('click', clickListener);
  }, []);

  const Hamburger = (
    <div className="hamburger-wrapper">
      <button
        className={cx('hamburger hamburger--collapse', {
          'is-active': isMobileMenuOpen,
        })}
        aria-label={
          isMobileMenuOpen
            ? intl.formatMessage(messages.closeMobileMenu)
            : intl.formatMessage(messages.openMobileMenu)
        }
        title={
          isMobileMenuOpen
            ? intl.formatMessage(messages.closeMobileMenu)
            : intl.formatMessage(messages.openMobileMenu)
        }
        type="button"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
    </div>
  );

  return (
    <div
      className={cx('header-wrapper header-mobile', {
        'padding-bottom': sticky,
      })}
      ref={headerRef}
    >
      <RWHeaderMobileSmall pathname={pathname} />
      <div className={sticky ? 'ui fixed top sticky' : null}>
        <Segment basic className="header-wrapper" role="banner">
          <Container>
            <div className="header">
              <div className="logo-nav-wrapper">
                <div className="logo">
                  <Logo />
                </div>
              </div>

              {Hamburger}
              <div className={cx('mobile-menu', { open: isMobileMenuOpen })}>
                {Hamburger}
                <div className="mobile-menu-container">
                  <div className="mobile-menu-logo">
                    <Logo />
                  </div>
                  <div className="mobile-menu-navigation">
                    <Navigation pathname={pathname} />

                    <SecondaryMenu pathname={pathname} />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Segment>
      </div>
    </div>
  );
};

export default RWHeaderDesktop;
