import React from 'react';
import Body from './Body';
import { Container } from 'semantic-ui-react';

const View = (props) => {
  return (
    <div className="rw--jobposting-ctas-wrapper grey-bg">
      <Container>
        <div className="content-wrapper">
          <Body {...props} />
        </div>
      </Container>
    </div>
  );
};

export default View;
