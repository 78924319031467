import {
  GET_CV_CUSTOM_FIELDS,
  SAVE_CV_CUSTOM_FIELDS,
  RESET_CV_CUSTOM_FIELDS,
} from '@package/constants/ActionTypes';

const initialState = {
  get: {
    error: null,
    loaded: false,
    loading: false,
  },
  save: {
    error: null,
    loaded: false,
    loading: false,
  },
  items: [],
};

function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

export function cvCustomFields(state = initialState, action = {}) {
  switch (action.type) {
    case `${GET_CV_CUSTOM_FIELDS}_PENDING`:
    case `${SAVE_CV_CUSTOM_FIELDS}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: null,
          loading: true,
          loaded: false,
        },
      };
    case `${GET_CV_CUSTOM_FIELDS}_FAIL`:
    case `${SAVE_CV_CUSTOM_FIELDS}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: action.error,
          loading: false,
          loaded: false,
        },
      };
    case `${SAVE_CV_CUSTOM_FIELDS}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: null,
          loading: false,
          loaded: true,
        },
      };
    case `${GET_CV_CUSTOM_FIELDS}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          error: null,
          loading: false,
          loaded: true,
        },
        items: JSON.parse(action.result),
      };
    case RESET_CV_CUSTOM_FIELDS:
      if (action.resetItems) {
        return initialState;
      } else {
        return {
          ...initialState,
          items: state.items,
        };
      }
    default:
      return state;
  }
}
