/**
 * Save CV action.
 * @module monet-volto/actions/saveCV
 */
export const SAVE_CV = 'SAVE_CV';
export const RESET_SAVE_CV = 'RESET_SAVE_CV';

/**
 * Save CV
 * @function saveCV
 * @returns {Object}  Save CV action
 */
export function saveCV(path, data, returnResult = true) {
  return {
    type: SAVE_CV,
    request: {
      op: 'patch',
      path,
      data,
      headers: returnResult
        ? {
            Prefer: 'return=representation',
          }
        : {},
    },
  };
}

export function resetSaveCV() {
  return {
    type: RESET_SAVE_CV,
  };
}
