import React from 'react';
import { Form, Popup, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import {
  TextWidget,
  SelectWidget,
  DatetimeWidgetComponent,
  CheckboxWidget,
  FormFieldWrapper,
} from '../../CVWidgets';
import { messages, FOREIGN_PROVINCE } from '../utils';
import mappings from './mapping.json';

const PersonalInfosStep = (props) => {
  const {
    fullname,
    date_of_birth,
    gender,
    birth_province,
    birth_place,
    // birth_nation,
    italian_taxcode,
    tax_code,
    nationality,
    // other_nationality,
    marital_state,
    driving_license,
    residency_address,
    residency_municipality,
    residency_postal_code,
    residency_province,
    residency_nation,
    domicile,
    domicile_address,
    domicile_municipality,
    domicile_postal_code,
    domicile_province,
    domicile_nation,
    email,
    mobile,
    phone,
    setFieldValue,
    control,
    intl,
    isDisabled,
  } = props;

  const cvSchema = useSelector((state) => state.schema.schema);

  const handleRadiosOnChange = (id, value) => {
    let newFieldValue = {
      title: value,
      token: value,
    };
    if (value === 'Yes' || value === 'No')
      newFieldValue = {
        title: value,
        token: value === 'Yes' ? 'True' : 'False',
      };

    if (id === 'domicile') {
      setFieldValue('domicile_address', '', false);
      setFieldValue('domicile_municipality', null, false);
      setFieldValue('domicile_postal_code', '', false);
      setFieldValue('domicile_province', null, false);
      setFieldValue('domicile_nation', '', false);
    }

    setFieldValue(id, newFieldValue);
  };

  const handleCheckboxList = (id, value) => {
    if (props[id].some((item) => item.title === value)) {
      setFieldValue(
        id,
        props[id].filter((item) => item.title !== value),
      );
    } else {
      const newFieldValue = {
        title: value,
        token: value,
      };

      if (id === 'driving_license') {
        if (value === 'none') {
          setFieldValue(id, [newFieldValue]);
        } else {
          setFieldValue(id, [
            ...props[id].filter((val) => val.token !== 'none'),
            newFieldValue,
          ]);
        }
      } else {
        setFieldValue(id, [...props[id], newFieldValue]);
      }
    }
  };

  /**
   * province is an object when coming from the backend,
   * and it's a string while it's modified by the user :(
   *
   * e.g.
   *
   * from the backend
   * ```
   * province = {
   *   title: 'Ferrara',
   *   token: 'Ferrara',
   * }
   * ```
   *
   * from user input
   * ```
   * province = 'Ferrara'
   * ```
   */
  const townsMappedOptions = (province) => {
    let normalizedProvince = '';
    if (typeof province === 'string') {
      normalizedProvince = province;
    } else if (province?.token) {
      normalizedProvince = province.token;
    }
    if (normalizedProvince) {
      return (
        mappings[normalizedProvince]?.reduce(
          (acc, item) => [...acc, [item, item]],
          [],
        ) ?? []
      );
    }
    return [];
  };
  return (
    <div className="step-container" aria-live="polite">
      <h4>{intl.formatMessage(messages.personalInfos)}</h4>
      <Form.Group widths={'equal'}>
        <TextWidget
          isDisabled={isDisabled}
          id="fullname"
          type="text"
          name="fullname"
          value={fullname}
          onChange={setFieldValue}
          required={true}
          title={intl.formatMessage(messages.fullName)}
          wrapped={true}
          control={control}
        />
        <DatetimeWidgetComponent
          isDisabled={isDisabled}
          id="date_of_birth"
          title={intl.formatMessage(messages.birthDate)}
          required={true}
          onChange={setFieldValue}
          wrapped={true}
          control={control}
          value={date_of_birth}
          dateOnly={true}
          birthday={true}
        />
        <SelectWidget
          isDisabled={isDisabled}
          control={control}
          id="gender"
          title={intl.formatMessage(messages.gender)}
          value={gender}
          onChange={setFieldValue}
          choices={cvSchema?.properties.gender?.choices ?? []}
          required={true}
        />
      </Form.Group>

      <Form.Group widths={'equal'}>
        <SelectWidget
          isDisabled={isDisabled}
          control={control}
          id="birth_province"
          title={intl.formatMessage(messages.birthProvince)}
          value={birth_province}
          onChange={(id, value) => {
            if (value !== birth_province) {
              setFieldValue('birth_place', '', false);
            }
            setFieldValue(id, value);
          }}
          vocabBaseUrl={'raiway.lavoraconnoi.AllProvinceVocabulary'}
          required={true}
        />
        <SelectWidget
          isDisabled={
            isDisabled || !birth_province || birth_province === FOREIGN_PROVINCE
          }
          control={control}
          id="birth_place"
          title={intl.formatMessage(messages.birthPlace)}
          value={birth_place}
          onChange={setFieldValue}
          choices={townsMappedOptions(birth_province)}
          required={birth_province !== FOREIGN_PROVINCE}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          id="italian_taxcode"
          title={intl.formatMessage(messages.itTaxCode)}
          wrapped
          className=""
          required={true}
          control={control}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="italian_taxcode"
            radio={true}
            value={'Yes'}
            title={intl.formatMessage(messages.yes)}
            checked={italian_taxcode?.token === 'True'}
            onChange={handleRadiosOnChange}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="italian_taxcode"
            radio={true}
            value={'No'}
            title={intl.formatMessage(messages.no)}
            checked={italian_taxcode?.token === 'False'}
            onChange={handleRadiosOnChange}
          />
        </FormFieldWrapper>

        <TextWidget
          isDisabled={isDisabled}
          id="tax_code"
          type="text"
          name="tax_code"
          value={tax_code}
          onChange={setFieldValue}
          required={italian_taxcode?.token === 'True'}
          title={intl.formatMessage(messages.taxCode)}
          wrapped={true}
          control={control}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          id="nationality"
          title={intl.formatMessage(messages.nationality)}
          wrapped
          className=""
          control={control}
          required={true}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="nationality"
            radio={true}
            value={'IT'}
            title={intl.formatMessage(messages.IT)}
            checked={nationality?.token === 'IT'}
            onChange={handleRadiosOnChange}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="nationality"
            radio={true}
            value={'UE'}
            title={intl.formatMessage(messages.EU)}
            checked={nationality?.token === 'UE'}
            onChange={handleRadiosOnChange}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="nationality"
            radio={true}
            value={'Other'}
            title={intl.formatMessage(messages.OTHER)}
            checked={nationality?.token === 'Other'}
            onChange={handleRadiosOnChange}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          id="marital_state"
          title={intl.formatMessage(messages.maritalState)}
          wrapped
          className=""
          control={control}
          required={true}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="marital_state"
            radio={true}
            value={'Married'}
            title={intl.formatMessage(messages.married)}
            checked={marital_state?.token === 'Married'}
            onChange={handleRadiosOnChange}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="marital_state"
            radio={true}
            value={'Single'}
            title={intl.formatMessage(messages.single)}
            checked={marital_state?.token === 'Single'}
            onChange={handleRadiosOnChange}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="marital_state"
            radio={true}
            value={'Other'}
            title={intl.formatMessage(messages.OTHER)}
            checked={marital_state?.token === 'Other'}
            onChange={handleRadiosOnChange}
          />
        </FormFieldWrapper>
      </Form.Group>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          wrapped
          title={intl.formatMessage(messages.drivingLicence)}
          id="driving_license"
          className="checkbox-list"
          control={control}
          required={true}
        >
          <div className="driving_license_container">
            {(cvSchema?.properties.driving_license?.items.choices ?? []).map(
              (license) => (
                <CheckboxWidget
                  key={license[0]}
                  isDisabled={isDisabled}
                  id="driving_license"
                  value={license[0]}
                  onChange={handleCheckboxList}
                  title={license[1]}
                  checked={driving_license?.some(
                    (item) => item.token === license[0],
                  )}
                />
              ),
            )}
          </div>
        </FormFieldWrapper>
      </Form.Group>

      <h4>{intl.formatMessage(messages.residency)}</h4>
      <Form.Group widths={'equal'}>
        <TextWidget
          isDisabled={isDisabled}
          id="residency_address"
          type="text"
          name="residency_address"
          value={residency_address}
          onChange={setFieldValue}
          title={intl.formatMessage(messages.residencyAddress)}
          wrapped={true}
          control={control}
          required={true}
        />
        <SelectWidget
          isDisabled={isDisabled}
          control={control}
          id="residency_province"
          title={intl.formatMessage(messages.residencyProvince)}
          value={residency_province}
          onChange={(id, value) => {
            if (value !== residency_province) {
              setFieldValue('residency_municipality', '', false);
            }
            setFieldValue(id, value);
          }}
          vocabBaseUrl={'raiway.lavoraconnoi.AllProvinceVocabulary'}
          wrapped={true}
          required={true}
        />

        <TextWidget
          isDisabled={isDisabled}
          id="residency_postal_code"
          type="text"
          name="residency_postal_code"
          value={residency_postal_code}
          onChange={setFieldValue}
          title={intl.formatMessage(messages.residencyCAP)}
          wrapped={true}
          control={control}
          required={true}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <SelectWidget
          isDisabled={
            isDisabled ||
            !residency_province ||
            residency_province === FOREIGN_PROVINCE
          }
          control={control}
          id="residency_municipality"
          title={intl.formatMessage(messages.residencyMunicipality)}
          value={residency_municipality}
          onChange={setFieldValue}
          choices={townsMappedOptions(residency_province)}
          wrapped={true}
          required={residency_province !== FOREIGN_PROVINCE}
        />

        <TextWidget
          isDisabled={isDisabled}
          id="residency_nation"
          type="text"
          name="residency_nation"
          value={residency_nation}
          onChange={setFieldValue}
          title={intl.formatMessage(messages.residencyNation)}
          wrapped={true}
          control={control}
        />
      </Form.Group>

      <h4>{intl.formatMessage(messages.domicile)}</h4>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          id="domicile"
          title={intl.formatMessage(messages.domNotRes)}
          wrapped
          className=""
          control={control}
          required={true}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="domicile"
            value={'Yes'}
            onChange={handleRadiosOnChange}
            required={true}
            title={intl.formatMessage(messages.yes)}
            radio={true}
            checked={domicile?.token === 'True'}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="domicile"
            value={'No'}
            onChange={handleRadiosOnChange}
            required={true}
            title={intl.formatMessage(messages.no)}
            radio={true}
            checked={domicile?.token === 'False' || !domicile}
          />
        </FormFieldWrapper>
      </Form.Group>
      {domicile?.token === 'True' && (
        <div>
          <Form.Group widths={'equal'}>
            <TextWidget
              isDisabled={isDisabled}
              id="domicile_address"
              type="text"
              name="domicile_address"
              value={domicile_address}
              onChange={setFieldValue}
              required={true}
              title={intl.formatMessage(messages.domicileAddress)}
              wrapped={true}
              control={control}
            />
            <SelectWidget
              isDisabled={isDisabled}
              control={control}
              id="domicile_province"
              title={intl.formatMessage(messages.domicileProvince)}
              value={domicile_province}
              onChange={(id, value) => {
                if (value !== domicile_province) {
                  setFieldValue('domicile_municipality', '', false);
                }
                setFieldValue(id, value);
              }}
              vocabBaseUrl={'raiway.lavoraconnoi.AllProvinceVocabulary'}
              wrapped={true}
              required={true}
            />

            <TextWidget
              isDisabled={isDisabled}
              id="domicile_postal_code"
              type="text"
              name="domicile_postal_code"
              value={domicile_postal_code}
              onChange={setFieldValue}
              required={true}
              title={intl.formatMessage(messages.domicileCAP)}
              wrapped={true}
              control={control}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <SelectWidget
              isDisabled={
                isDisabled ||
                !domicile_province ||
                domicile_province === FOREIGN_PROVINCE
              }
              control={control}
              id="domicile_municipality"
              title={intl.formatMessage(messages.domicileMunicipality)}
              value={domicile_municipality}
              onChange={setFieldValue}
              choices={townsMappedOptions(domicile_province)}
              disabled={!domicile_province}
              wrapped={true}
              required={domicile_province !== FOREIGN_PROVINCE}
            />
            <TextWidget
              isDisabled={isDisabled}
              id="domicile_nation"
              type="text"
              name="domicile_nation"
              value={domicile_nation}
              onChange={setFieldValue}
              title={intl.formatMessage(messages.domicileNation)}
              wrapped={true}
              control={control}
            />
          </Form.Group>
        </div>
      )}

      <h4>{intl.formatMessage(messages.contactInfo)}</h4>
      <Form.Group widths={'equal'}>
        <TextWidget
          id="email"
          type="email"
          name="email"
          value={email}
          onChange={setFieldValue}
          required={true}
          title={intl.formatMessage(messages.email)}
          wrapped={true}
          control={control}
          isDisabled={true}
        />
        <TextWidget
          isDisabled={isDisabled}
          id="mobile"
          type="tel"
          name="mobile"
          value={mobile}
          onChange={setFieldValue}
          required={true}
          title={intl.formatMessage(messages.mobile)}
          wrapped={true}
          control={control}
        />
        <TextWidget
          isDisabled={isDisabled}
          id="phone"
          type="tel"
          name="phone"
          value={phone}
          onChange={setFieldValue}
          required={false}
          title={intl.formatMessage(messages.phone)}
          wrapped={true}
          control={control}
          popup={
            <Popup
              on={['click', 'hover']}
              trigger={<Icon name="question circle" />}
              content={intl.formatMessage(messages.phonePopup)}
              position="top left"
              hideOnScroll={true}
            />
          }
        />
      </Form.Group>
    </div>
  );
};

export default PersonalInfosStep;
