import React, { useState, useEffect } from 'react';
import { defineMessages } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Header, Dimmer, Loader } from 'semantic-ui-react';
import { Button } from '@package/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { resetSaveCV } from '@package/actions';
import CVFormManager from '@package/components/CVForm/CVFormManager';
import { Toast } from '@plone/volto/components';
import { getContent, resetContent, createContent } from '@plone/volto/actions';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable';
import { isFromLinkedin } from '../../backoffice-lavoraconnoi/LinkedinTracker/LinkedinTracker';

const messages = defineMessages({
  backLinkTextLabel: {
    id: 'backLinkTextLabel',
    defaultMessage: 'Torna alla lista',
  },
  registerLinkTextLabel: {
    id: 'registerLinkTextLabel',
    defaultMessage: 'Invia la candidatura',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading...',
  },
  noFirstSave: {
    id: 'noFirstSave',
    defaultMessage:
      'You must save your CV before you can apply for a job opening. You can do so by completing the form below.',
  },
  reviewCV: {
    id: 'reviewCV',
    defaultMessage:
      'Here is the CV you previously saved. You can review the information in the form below before applying.',
  },
  applicationLoading: {
    id: 'applicationLoading',
    defaultMessage: 'Invio della candidatura in corso, attendere prego.',
  },
  applicationCreated: {
    id: 'applicationCreated',
    defaultMessage:
      'Candidatura inviata con successo! Puoi visualizzare le tue candidature nella tua pagina personale.',
  },
  modalHeader: {
    id: 'modalHeader',
    defaultMessage: 'Invia la tua candidatura',
  },
});

const CVModal = ({ intl, jp, setApplied, toastify }) => {
  const [isOpen, setIsOpen] = useState(false);
  // const [successfulSave, setSuccessfulSave] = useState(false);
  const createApplicationState = useSelector(
    (state) => state.content.subrequests.createApplicationState,
  );
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.users.user);
  const cvState = useSelector((state) => state.content.subrequests.cvState);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isOpen) dispatch(resetSaveCV());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    return () => {
      dispatch(resetContent('cvState'));
      dispatch(resetContent('createApplicationState'));
    };
  }, [dispatch]);

  useEffect(() => {
    if (userLogged?.home_page && isOpen) {
      dispatch(
        getContent(flattenToAppURL(userLogged?.home_page), null, 'cvState'),
      );
    }
  }, [dispatch, userLogged?.home_page, isOpen]);

  useEffect(() => {
    if (cvState?.loaded && !cvState?.error && cvState?.data)
      setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cvState]);

  useEffect(() => {
    if (createApplicationState?.error) {
      if (toastify.toast.isActive('application-loading'))
        toastify.toast.dismiss('application-loading');
      toastify.toast.error(
        <Toast
          error
          title={intl.formatMessage({ id: 'Error' })}
          content={intl.formatMessage({ id: 'saveError' })}
        />,
      );
    } else if (
      !createApplicationState?.error &&
      createApplicationState?.loading
    ) {
      !toastify.toast.isActive('application-loading') &&
        toastify.toast.info(
          <Toast
            info
            title={intl.formatMessage({ id: 'Loading' })}
            content={intl.formatMessage(messages.applicationLoading)}
          />,
          { id: 'application-loading' },
        );
    } else if (
      createApplicationState?.data &&
      !createApplicationState?.error &&
      !createApplicationState?.loading
    ) {
      if (toastify.toast.isActive('application-loading'))
        toastify.toast.dismiss('application-loading');
      !toastify.toast.isActive('application-success') &&
        toastify.toast.success(
          <Toast
            success
            title={intl.formatMessage({ id: 'Success' })}
            content={intl.formatMessage(messages.applicationCreated)}
          />,
          { id: 'application-success' },
        );
      // close form
      setApplied(true);
      setIsOpen(false);
      dispatch(resetContent('cvState'));
      dispatch(resetContent('createApplicationState'));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createApplicationState?.data,
    createApplicationState?.error,
    createApplicationState?.loading,
  ]);

  // handle application
  const handleApplication = async () => {
    dispatch(
      createContent(
        flattenToAppURL(userLogged.home_page),
        {
          from_linkedin: isFromLinkedin(),
          related_jobpost: jp.UID,
          title: `application_${jp.UID}`,
          '@type': 'Application',
        },
        'createApplicationState',
      ),
    );
  };
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return (
    <Modal
      basic
      onClose={() => {
        setIsOpen(false);
      }}
      dimmer="blurring"
      open={isOpen}
      onOpen={async () => {
        setIsOpen(true);
      }}
      className="delete-modal"
      trigger={
        <Button arrow={true} key="registerLink">
          {intl.formatMessage(messages.registerLinkTextLabel)}
        </Button>
      }
    >
      <Header>{intl.formatMessage(messages.modalHeader)}</Header>
      <Modal.Content
        className="edit-cv-modal"
        // Safari is broken, nothing new about this. Use scrolling modal
        // instead of scrolling content, it's uglier but whatever
        scrolling={isSafari}
      >
        <div className="application-modal-info">
          {jp.job_posting_cta.first_save_on_cv
            ? intl.formatMessage(messages.reviewCV)
            : intl.formatMessage(messages.noFirstSave)}
        </div>
        <Dimmer.Dimmable blurring dimmed={isLoading}>
          <Dimmer active={isLoading} inverted>
            <Loader>{intl.formatMessage(messages.loading)}</Loader>
          </Dimmer>

          {!isLoading && cvState?.data ? (
            <CVFormManager
              data={cvState.data}
              updateContent={() => null}
              isEditing={isOpen}
              setIsEditing={setIsOpen}
              setSuccessfulSave={() => {}}
              handleApplication={handleApplication}
              intl={intl}
              isUserProfile={false}
              closeReviewModal={() => setIsOpen(false)}
            />
          ) : (
            <div style={{ height: 1410 }} className="grey-bg"></div>
          )}
        </Dimmer.Dimmable>
      </Modal.Content>
    </Modal>
  );
};

CVModal.propTypes = {
  jp: PropTypes.object.isRequired,

  intl: PropTypes.object.isRequired,
};

export default injectLazyLibs(['toastify'])(CVModal);
