import { USER_OBLIVION } from '@package/actions/userOblivion';

const initialState = {
  error: null,
  hasError: false,
  successUserOblivion: false,
};

export const userOblivionReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${USER_OBLIVION}_PENDING`:
      return {
        ...state,
      };
    case `${USER_OBLIVION}_SUCCESS`:
      return {
        ...state,
        successUserOblivion: true,
      };
    case `${USER_OBLIVION}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
      };
    default:
      return state;
  }
};
