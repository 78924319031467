import { DELETE_CVS, RESET_DELETE_CVS } from '@package/constants/ActionTypes';

const initialState = {
  error: null,
  loaded: false,
  loading: false,
};

export function deleteCVsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case `${DELETE_CVS}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${DELETE_CVS}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
      };
    case `${DELETE_CVS}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case RESET_DELETE_CVS:
      return initialState;
    default:
      return state;
  }
}
