import React, { useState, useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getQueryStringResults } from '@plone/volto/actions';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { Container } from 'semantic-ui-react';
import {
  flattenToAppURL,
  usePagination,
  usePrevious,
} from '@plone/volto/helpers';
import JobpostingListingTabs from './components/JobPostingListingTabs';
import { listingTabs, initializeBSizes, initialQuery, refocus } from './utils';
import JobPostingListing from '../Listing/variations/JobPostingListing';

const Body = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { /* data, */ id, path, isEditMode, listingRef } = props;
  const realPath = isEditMode
    ? flattenToAppURL(props?.properties?.['@id'] ?? '')
    : path;

  const [selectedTab, setSelectedTab] = useState(0);
  const prevSelectedTab = usePrevious(selectedTab);
  const [bSize, setBSize] = useState(initialQuery.b_size);
  const prevBSize = usePrevious(bSize);
  const { currentPage, setCurrentPage } = usePagination(initialQuery, 1);

  const [query, setQuery] = useState(initialQuery);

  const querystringResults = useSelector(
    (state) => state.querystringsearch.subrequests[id],
  );
  const totalPages = Math.ceil(querystringResults?.total / bSize);
  const hasLoaded = !querystringResults?.loading;
  const bSizeOptions = initializeBSizes(intl);

  const onChangeTab = (e, { value }) => {
    setSelectedTab(value);
  };

  const onChangeBSize = (e, { value }) => {
    setBSize(value);
    refocus(isEditMode, listingRef);
  };

  const onChangePage = (e, { activePage }) => {
    setCurrentPage(activePage);
    refocus(isEditMode, listingRef);
  };

  // handle querystring search action
  useDeepCompareEffect(() => {
    dispatch(getQueryStringResults(realPath, query, id, currentPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, query, currentPage]);

  // handle query building at tab or bsize change,
  // and do not duplicate @querystringsearch call
  useEffect(() => {
    let newQuery = {};
    if (prevSelectedTab !== selectedTab) {
      newQuery = {
        ...query,
        query: query.query?.map((q) =>
          q.i === 'tipologia_jobposting'
            ? {
                i: 'tipologia_jobposting',
                o: 'plone.app.querystring.operation.selection.any',
                v: [listingTabs[selectedTab]],
              }
            : q,
        ),
        b_size: initialQuery.b_size,
      };
      setBSize(initialQuery.b_size);
    }
    if (prevBSize !== bSize && !(prevSelectedTab !== selectedTab))
      newQuery = {
        ...query,
        b_size: bSize,
        b_start: 0,
      };

    setCurrentPage(1);
    setQuery(newQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, bSize]);

  return (
    <Container>
      <div ref={listingRef}>
        <JobpostingListingTabs
          onChangeTab={onChangeTab}
          activeTab={selectedTab}
          intl={intl}
        />
        <JobPostingListing
          items={querystringResults?.items}
          isEditMode={isEditMode}
          totalPages={totalPages}
          totalItems={querystringResults?.total}
          listingRef={listingRef}
          currentPage={currentPage}
          bSize={bSize}
          bSizeOptions={bSizeOptions}
          onChangeBSize={onChangeBSize}
          onChangePage={onChangePage}
          hasLoaded={hasLoaded}
          currentTab={selectedTab}
          isStandaloneBlock={true}
        />
      </div>
    </Container>
  );
};
export default Body;
