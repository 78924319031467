import React from 'react';

import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import {
  Option,
  DropdownIndicator,
} from '@plone/volto/components/manage/Widgets/SelectStyling';
import {
  selectTheme,
  customSelectStyles,
} from '@plone/volto/components/manage/Blocks/Search/components/SelectStyling';
import {
  selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';

const SelectFixedOptionsFacet = (props) => {
  const {
    facet,
    choices,
    reactSelect,
    isMulti,
    onChange,
    value,
    isEditMode,
  } = props;
  const Select = reactSelect.default;
  const v = Array.isArray(value) && value.length === 0 ? null : value;

  const fixedChoices = choices.filter(
    (c) => facet.fixedChoicesList?.indexOf(c.value) >= 0 ?? false,
  );

  return (
    <Select
      placeholder={facet?.title ?? (facet?.field?.label || 'select...')}
      className="react-select-container"
      classNamePrefix="react-select"
      options={fixedChoices}
      styles={customSelectStyles}
      theme={selectTheme}
      components={{ DropdownIndicator, Option }}
      isDisabled={isEditMode}
      onChange={(data) => {
        if (data) {
          onChange(
            facet.field.value,
            isMulti ? data.map(({ value }) => value) : [data.value],
          );
        } else {
          // data has been removed
          onChange(facet.field.value, []);
        }
      }}
      isMulti={facet.multiple}
      isClearable
      value={v}
    />
  );
};

const SelectFixedOptionsFacetSchemaEnhancer = ({ schema, formData }) => {
  let newSchema = selectFacetSchemaEnhancer({ schema, formData });

  if (formData.field.value) {
    let { fields } = newSchema.fieldsets[0];
    fields.push('fixedChoicesList');
    newSchema.fieldsets[0].fields = fields;

    newSchema.properties.fixedChoicesList = {
      widget: 'select_querystring',
      title: 'Valori possibili',
      description: 'Seleziona le opzioni fisse da mostrare nella lista',
      querystringField: formData.field.value,
    };
  }

  return newSchema;
};

SelectFixedOptionsFacet.schemaEnhancer = SelectFixedOptionsFacetSchemaEnhancer;
SelectFixedOptionsFacet.stateToValue = selectFacetStateToValue;
SelectFixedOptionsFacet.valueToQuery = selectFacetValueToQuery;

export default injectLazyLibs('reactSelect')(SelectFixedOptionsFacet);
