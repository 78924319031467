import {
  ADD_MULTI_COMMENT,
  LIST_MULTI_COMMENT,
  RESET_MULTI_COMMENT,
} from '@package/constants/ActionTypes';

const initialState = {
  add: {
    loaded: false,
    loading: false,
    error: null,
  },
  list: {
    loaded: false,
    loading: false,
    error: null,
  },
  subrequests: {},
};

/**
 * Get request key
 * @function getRequestKey
 * @param {string} actionType Action type.
 * @returns {string} Request key.
 */
function getRequestKey(actionType) {
  return actionType.split('_')[0].toLowerCase();
}

export default function multiComments(state = initialState, action = {}) {
  switch (action.type) {
    case `${ADD_MULTI_COMMENT}_PENDING`:
    case `${LIST_MULTI_COMMENT}_PENDING`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: true,
          loaded: false,
          error: null,
        },
      };
    case `${ADD_MULTI_COMMENT}_FAIL`:
    case `${LIST_MULTI_COMMENT}_FAIL`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    case `${ADD_MULTI_COMMENT}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case `${LIST_MULTI_COMMENT}_SUCCESS`:
      return {
        ...state,
        [getRequestKey(action.type)]: {
          loading: false,
          loaded: true,
          error: null,
        },
        subrequests: {
          ...state.subrequests,
          ...(action.subrequest
            ? {
                [action.subrequest]: {
                  items: action.result.items,
                  items_total: action.result.items_total,
                  next: action.result.batching?.next,
                },
              }
            : {}),
        },
      };
    case RESET_MULTI_COMMENT:
      if (action.subrequests) {
        return {
          ...state,
          subrequests: {
            ...state.subrequests,
            ...action.subrequests.reduce((acc, subrequest) => {
              acc[subrequest] = {
                items: [],
                items_total: 0,
                next: null,
              };
              return acc;
            }, {}),
          },
        };
      } else {
        return initialState;
      }
    default:
      return state;
  }
}
