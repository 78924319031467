import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import {
  FormattedMessage,
  useIntl,
  injectIntl,
  defineMessages,
} from 'react-intl';
import {
  CheckboxWidget,
  ObjectListWidget,
  TextWidget,
  ObjectBrowserWidget,
  SelectWidget,
} from '@plone/volto/components';

import { ColorListWidget, AlignWidget } from '@package/components/Widgets';

const messages = defineMessages({
  header_align: {
    id: "Allineamento dell'intestazione",
    defaultMessage: "Allineamento dell'intestazione",
  },
  showTitleDecoration: {
    id: 'showTitleDecoration',
    defaultMessage: 'Mostra linea di separazione del titolo',
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  const { data, onChangeBlock, block } = props;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="IconsAndTextBlock"
            defaultMessage="Blocchi con icone e testo"
          />
          :
        </h2>
      </header>
      <Segment className="form">
        <ColorListWidget
          id="bg_color"
          title="Colore di sfondo"
          colors={[
            { name: 'outline-white', label: 'Trasparente' },
            { name: 'light-grey', label: 'Grigio' },
          ]}
          value={data.bg_color ?? 'outline-white'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />
        <CheckboxWidget
          id="fullWidth"
          title="A tutta larghezza"
          value={data.fullWidth ? data.fullWidth : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />

        <ColorListWidget
          id="title_color"
          title="Colore del titolo"
          colors={[
            { name: 'grey', label: 'Grigio' },
            { name: 'blue', label: 'Blu' },
          ]}
          value={data.title_color ?? 'grey'}
          onChange={(name, value) =>
            onChangeBlock(block, { ...data, [name]: value })
          }
        />
        <CheckboxWidget
          id="showTitleDecoration"
          title={intl.formatMessage(messages.showTitleDecoration)}
          value={data.showTitleDecoration ?? true}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <AlignWidget
          id="header_align"
          title={intl.formatMessage(messages.header_align)}
          alignments={['left', 'center']}
          value={data.header_align ? data.header_align : 'center'}
          fieldSet="default"
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>

      <Segment className="form">
        <SelectWidget
          id="n_columns"
          title="Numero di colonne"
          choices={[
            ['2', '2'],
            ['3', '3'],
            ['4', '4'],
          ]}
          value={data.n_columns}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              [name]: value,
            });
          }}
        />
        <CheckboxWidget
          id="noAdaptColumns"
          title="Non adattare le colonne allo spazio disponibile"
          value={data.noAdaptColumns ? data.noAdaptColumns : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
      <Segment className="form">
        <ObjectListWidget
          {...props}
          id="columns"
          value={data?.columns ?? []}
          onChange={(id, value) =>
            onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const columnsSchema = {
              title: 'Colonna',
              addMessage: 'Aggiungi una colonna',
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: [
                    'iconImage',
                    'iconSize',
                    'headerTextPosition',
                    'dividerPosition',
                    'href',
                    'href_title',
                  ],
                },
              ],
              properties: {
                iconImage: {
                  title: 'Icona',
                  description:
                    "L'immagine deve essere un PNG o un SVG. La dimensione massima consigliata per il PNG è 200x200px.",
                  widget: 'image_upload_widget',
                  openObjectBrowser: props.openObjectBrowser,
                },
                iconSize: {
                  title: 'Dimensione immagine',
                  type: 'choices',
                  choices: [
                    ['s', 'Small'],
                    ['m', 'Medium'],
                    ['l', 'Large'],
                  ],
                  noValueOption: false,
                },
                headerTextPosition: {
                  title: "Posizione del testo nell'header",
                  type: 'choices',
                  choices: [
                    ['right', 'A destra'],
                    ['bottom', 'In basso'],
                  ],
                },
                dividerPosition: {
                  title: 'Posizione del separatore',
                  type: 'choices',
                  choices: [
                    ['before_title', 'Prima del titolo'],
                    ['after_title', 'Dopo il titolo'],
                    ['before_header_text', "Prima del testo dell'header"],
                  ],

                  noValueOption: false,
                },
                href: {
                  title: 'Link',
                  widget: 'object_browser',
                  allowExternals: true,
                  mode: 'link',
                },
                href_title: {
                  title: 'Titolo per il link',
                  description:
                    'Se non viene inserito un titolo, e viene selezionato un link, il collegamento verrà aggiunto al titolo del blocco.',
                },
              },

              required: [],
            };

            return columnsSchema;
          }}
        />

        <ObjectBrowserWidget
          id="href"
          title="Link ad altro"
          mode="link"
          allowExternals={true}
          value={data.href}
          widgetOptions={{
            pattern_options: { maximumSelectionSize: 1 },
          }}
          onChange={(name, value) => {
            onChangeBlock(block, {
              ...data,
              href: value,
            });
          }}
        />
        <TextWidget
          id="href_title"
          title="Titolo per il link ad altro"
          value={data.href_title ?? ''}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default injectIntl(Sidebar);
