import React from 'react';
import cx from 'classnames';
import { Icon } from '@plone/volto/components';
import { Container } from 'semantic-ui-react';
import squareMosaicGrey from '@package/icons/squares_mosaic_grey.svg';
import squarePrimaryGrey from '@package/icons/squares_primary_1_flat_grey.svg';
import squareSecondaryGrey from '@package/icons/squares_secondary_1_grey.svg';
import squarePrimarySmallGrey from '@package/icons/squares_small_primary_grey.svg';

const Body = (props) => {
  const { data } = props;

  const greySigns = {
    squareMosaicGrey: {
      image: squareMosaicGrey,
    },
    squarePrimaryGrey: {
      image: squarePrimaryGrey,
    },
    squareSecondaryGrey: {
      image: squareSecondaryGrey,
    },
    squarePrimarySmallGrey: {
      image: squarePrimarySmallGrey,
    },
  };

  return (
    <div
      className={cx({
        'grey-bg': data?.background,
        'full-width': data?.fullwidth,
        'border-bottom': data?.line,
        'stick-on-top': data?.stickOnTop,
      })}
    >
      <Container>
        <div
          className={cx('signbox', {
            'text-right': data?.align === 'right',
          })}
        >
          <Icon
            name={data?.sign ? greySigns[data.sign].image : squareMosaicGrey}
            size="50px"
            role="presentation"
            alt=""
          />
        </div>
      </Container>
    </div>
  );
};
export default Body;
