import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Label, TextArea } from 'semantic-ui-react';

import FormFieldWrapper from '../FormFieldWrapper';

/**
 * TextareaWidget, a widget for multiple lines text
 *
 * To use it, in schema properties, declare a field like:
 *
 * ```jsx
 * {
 *  title: "Text",
 *  widget: 'textarea',
 * }
 * ```
 */
const TextareaWidget = (props) => {
  const { id, maxLength, defaultValue, onChange, placeholder } = props;
  const [lengthError, setlengthError] = useState('');
  const [valueState, setValueState] = useState(defaultValue);

  const onhandleChange = (id, value) => {
    if (maxLength & value?.length) {
      let remlength = maxLength - value.length;
      if (remlength < 0) {
        setlengthError(`You have exceed word limit by ${Math.abs(remlength)}`);
      } else {
        setlengthError('');
      }
    }
    setValueState(value);
    onChange(id, value);
  };

  return (
    <FormFieldWrapper {...props} className="textarea">
      {lengthError.length > 0 && (
        <Label key={lengthError} basic color="red" pointing="below">
          {lengthError}
        </Label>
      )}
      <TextArea
        id={`field-${id}`}
        className="rw--textarea-widget"
        name={id}
        value={valueState || ''}
        defaultValue={defaultValue || ''}
        disabled={props.isDisabled}
        placeholder={placeholder}
        onChange={({ target }) => {
          onhandleChange(id, target.value === '' ? undefined : target.value);
        }}
      />
    </FormFieldWrapper>
  );
};

TextareaWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  wrapped: PropTypes.bool,
  placeholder: PropTypes.string,
  control: PropTypes.any,
};

TextareaWidget.defaultProps = {
  description: null,
  maxLength: null,
  required: false,
  error: [],
  defaultValue: '',
  onChange: null,
};

export default TextareaWidget;
