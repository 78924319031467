import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'sspfg';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '493'}
      height={size ?? '241'}
      fill="none"
      viewBox="0 0 493 241"
      className={className}
      key={key}
      {...otherProps}
    >
      <path
        fill={`url(#${uniqueID}paint0_linear)`}
        d="M176.257 90.217h115.489v115.735H176.257V90.217zm107.204 8.302h-98.919v99.13h98.919v-99.13z"
      />
      <path
        fill={`url(#${uniqueID}paint1_linear)`}
        d="M324.885 120.626h77.857v78.023h-77.857v-78.023zm72.367 5.502h-66.778v66.919h66.778v-66.919z"
      />
      <path
        fill={`url(#${uniqueID}paint2_linear)`}
        d="M261.2 160.237V73.411h86.642v86.826H261.2zm6.888-79.924v73.022h72.866V80.313h-72.866z"
      />
      <path
        fill={`url(#${uniqueID}paint3_linear)`}
        d="M337.66 221.656v-47.114h47.014v47.114H337.66zm4.193-42.913v38.812h38.728v-38.812h-38.728z"
      />
      <path
        fill={`url(#${uniqueID}paint4_linear)`}
        d="M417.516 166.94v-31.909h31.841v31.909h-31.841zm2.695-29.108v26.308h26.252v-26.308h-26.252z"
      />
      <path
        fill={`url(#${uniqueID}paint5_linear)`}
        d="M388.369 149.234V102.12h47.014v47.114h-47.014zm4.092-43.013v38.812h38.729v-38.812h-38.729z"
      />
      <path
        fill={`url(#${uniqueID}paint6_linear)`}
        d="M446.961 95.918h29.746v29.709h-29.746V95.918zm27.65 2.101h-25.554v25.608h25.554V98.019z"
      />
      <path
        fill={`url(#${uniqueID}paint7_linear)`}
        d="M462.832 114.524v-29.81h29.746v29.81h-29.746zm2.096-27.709v25.609h25.554V86.815h-25.554z"
      />
      <mask
        id="sspfgqjaqjtwyba"
        width="250"
        height="241"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#C4C4C4"
          d="M0.578 0.39H249.123V240.27399999999997H0.578z"
        />
      </mask>
      <g mask="url(#sspfgqjaqjtwyba)">
        <path
          fill={`url(#${uniqueID}paint8_linear)`}
          d="M226.744 227.958H38.18V38.992h188.564v188.966zM48.268 217.756h168.296V49.102H48.268v168.654z"
        />
      </g>
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="176.265"
          x2="291.698"
          y1="148.076"
          y2="148.076"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="324.889"
          x2="402.71"
          y1="159.632"
          y2="159.632"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="304.515"
          x2="304.515"
          y1="160.232"
          y2="73.447"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint3_linear`}
          className="paint_linear"
          x1="361.164"
          x2="361.164"
          y1="221.653"
          y2="174.562"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint4_linear`}
          className="paint_linear"
          x1="433.434"
          x2="433.434"
          y1="166.938"
          y2="135.044"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint5_linear`}
          className="paint_linear"
          x1="411.873"
          x2="411.873"
          y1="149.231"
          y2="102.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint6_linear`}
          className="paint_linear"
          x1="446.963"
          x2="476.695"
          y1="110.771"
          y2="110.771"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint7_linear`}
          className="paint_linear"
          x1="477.703"
          x2="477.703"
          y1="114.523"
          y2="84.727"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint8_linear`}
          className="paint_linear"
          x1="226.732"
          x2="38.258"
          y1="133.488"
          y2="133.488"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Icon;
