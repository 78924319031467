import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button as SButton } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import deleteSVG from '@plone/volto/icons/delete.svg';
import {
  searchContent,
  resetSearchContent,
  deleteContent,
} from '@plone/volto/actions';
import { Icon, ContentsUploadModal } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';
import { Button } from '@package/components';
import FormFieldWrapper from '../FormFieldWrapper';

const messages = defineMessages({
  deleteFile: {
    id: 'file_contents_widget_delete_file',
    defaultMessage: 'Elimina file',
  },
  noFiles: {
    id: 'file_contents_widget_no_files',
    defaultMessage: 'Nessun file allegato',
  },
  upload: {
    id: 'file_contents_widget_upload',
    defaultMessage: 'Carica file',
  },
});

const FileContentsWidget = (props) => {
  const { isDisabled } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();

  const userCv = flattenToAppURL(
    useSelector((state) => state.users.user.home_page ?? ''),
  );
  const searchState = useSelector((state) => state.search.subrequests[userCv]);
  const deleteState = useSelector((state) => state.content.delete);

  const search = useCallback(() => {
    dispatch(
      searchContent(
        userCv,
        {
          b_size: 1000000,
          'path.depth': 1,
          portal_type: ['File', 'Image'],
        },
        userCv,
      ),
    );
  }, [dispatch, userCv]);

  useEffect(() => {
    if (!searchState?.loaded && !searchState?.loading) {
      search();
    }
  }, [searchState?.loaded, searchState?.loading, search]);

  useEffect(() => {
    if (deleteState.loaded) {
      search();
    }
  }, [deleteState.loaded, search]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => dispatch(resetSearchContent(userCv)), []);

  // TODO check errore caricamento e cancellazione allegati

  return (
    <FormFieldWrapper {...props}>
      <div className="file-contents-widget">
        {searchState?.items.map((item) => {
          const link =
            item['@type'] === 'File'
              ? `${flattenToAppURL(item['@id'])}/@@download/file`
              : `${flattenToAppURL(item['@id'])}/@@download/image`;

          return (
            <div className="field-file-name" key={flattenToAppURL(item['@id'])}>
              <a href={link} title="Download">
                {item.title}
              </a>
              <SButton
                type="button"
                icon
                basic
                disabled={isDisabled}
                className="delete-button"
                aria-label={intl.formatMessage(messages.deleteFile)}
                title={intl.formatMessage(messages.deleteFile)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  dispatch(deleteContent(item['@id']));
                }}
              >
                <Icon name={deleteSVG} size="20px" />
              </SButton>
            </div>
          );
        })}
        {searchState?.items.length === 0 && (
          <div className="field-file-name">
            {intl.formatMessage(messages.noFiles)}
          </div>
        )}
        <Button
          type="button"
          color="outline-blue"
          onClick={() => setIsModalOpen(true)}
          disabled={isDisabled}
        >
          {intl.formatMessage(messages.upload)}
        </Button>
      </div>
      <ContentsUploadModal
        pathname={userCv}
        open={isModalOpen}
        onOk={() => {
          props.onChange();
          dispatch(resetSearchContent(userCv));
          setIsModalOpen(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      />
    </FormFieldWrapper>
  );
};

FileContentsWidget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  wrapped: PropTypes.bool,
  isDisabled: PropTypes.bool,
  control: PropTypes.any,
};

FileContentsWidget.defaultProps = {
  description: null,
  required: false,
  error: [],
};

export default injectIntl(FileContentsWidget);
