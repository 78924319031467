import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

const Sidebar = (props) => {
  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="JobPostingListing"
            defaultMessage="Listato Job Posting"
          />
        </h2>
      </header>
      <Segment>
        <p style={{ fontSize: '14px' }}>
          <FormattedMessage
            id="BlockNoConfig"
            defaultMessage="Nessuna configurazione disponibile per questo blocco"
          />
        </p>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default Sidebar;
