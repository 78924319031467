import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/schemas/utils';
import { addPresetFields } from '@package/helpers/presets';

const messages = defineMessages({
  title: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'Decrizione',
    defaultMessage: 'Descrizione',
  },

  header_align: {
    id: "Allineamento dell'intestazione",
    defaultMessage: "Allineamento dell'intestazione",
  },
});

// // SIGN WIDGET EXAMPLE
//const messages = defineMessages({
// sign: {
//   id: 'Segno grafico',
//   defaultMessage: 'Segno grafico',
// },
//});

// addSchemaField(
//   schema,
//   'sign',
//   intl.formatMessage(messages.sign),
//   null,
//   { widget: 'sign', intl: intl },
//   pos,
// );

/** DEFAULT **/

const getDefaultSchema = (schema, formData, intl, position = 0) => {
  let pos = position;
  schema.fieldsets[0].fields = schema.fieldsets[0].fields.filter(
    (f) => f !== 'querystring',
  ); //move 'querystring' field to a separate fieldset

  schema.fieldsets.splice(1, 0, {
    fields: ['querystring'],
    id: 'criteria',
    title: 'Criteri',
  });
  schema.fieldsets.splice(1, 0, {
    fields: [],
    id: 'items',
    title: 'Stile degli elementi',
  });

  addSchemaField(
    schema,
    'title',
    intl.formatMessage(messages.title),
    null,
    { type: 'string' },
    pos,
  );
  pos++;

  addSchemaField(
    schema,
    'description',
    intl.formatMessage(messages.description),
    null,
    { type: 'string' },
    pos,
  );
  pos++;

  addSchemaField(
    schema,
    'header_align',
    intl.formatMessage(messages.header_align),
    null,
    {
      widget: 'rwalign',
      defaultValue: 'center',
      alignments: ['left', 'center'],
    },
    pos,
  );
  pos++;

  pos = addPresetFields(schema, intl, pos, 'default', {
    bg_color: { colors: ['outline-white', 'light-grey'] },
  });

  return pos;
};

export default getDefaultSchema;
