export const RESET_USER = 'RESET_USER';

/**
 * Reset User on logout
 * @function resetUser
 * @returns {Object}
 */
export function resetUser() {
  return {
    type: RESET_USER,
  };
}
