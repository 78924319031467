import { Form, Popup, Icon, Button } from 'semantic-ui-react';
import {
  TextWidget,
  SelectWidget,
  DatetimeWidgetComponent,
  CheckboxWidget,
  FormFieldWrapper,
  TextareaWidget,
} from '../../CVWidgets';
import { OTHER_DEGREE_TOKEN, messages } from '../utils';

const regex = /^[0-9]*$/;

const WorkExperienceFields = (props) => {
  const {
    professional_experience,
    setFieldValue,
    control,
    intl,
    isDisabled,
  } = props;
  const professional_experienceState = Array.isArray(professional_experience)
    ? professional_experience
    : [];

  const handleOnChange = (id, value, weIndex) => {
    const newProfessionalExperienceState = professional_experienceState.map(
      (item, i) => {
        if (weIndex === i) {
          if (id === 'notice_to_end_experience' && value) {
            if (!regex.test(value)) return item;
          }
          if (id === 'area' && item.area !== value) {
            return {
              ...item,
              area: value,
              attivita: '',
            };
          }
          if (id === 'ongoing_experience') {
            if (value) {
              return {
                ...item,
                ongoing_experience: value,
                data_fine: '',
              };
            } else {
              return {
                ...item,
                ongoing_experience: value,
                notice_to_end_experience: '',
              };
            }
          }
          return { ...item, [id]: value };
        } else {
          return item;
        }
      },
    );
    setFieldValue(
      'professional_experience',
      newProfessionalExperienceState,
      false,
    );
  };

  const addNewWE = () => {
    setFieldValue(
      'professional_experience',
      [
        ...professional_experienceState,
        {
          settore: '',
          area: '',
          attivita: '',
          ruolo: '',
          tipologia_contrattuale: '',
          tipologia_contrattuale_altro: '',
          azienda: '',
          luogo: '',
          data_inizio: '',
          ongoing_experience: false,
          data_fine: '',
          notice_to_end_experience: '',
          descrizione: '',
        },
      ],
      false,
    );
  };

  const removeWE = (index) => {
    setFieldValue(
      'professional_experience',
      professional_experienceState.filter((item, i) => i !== index),
      false,
    );
  };

  return (
    <div>
      <div className="education-add-container">
        <h4>{intl.formatMessage(messages.professionalExperiences)}</h4>
      </div>
      <p className="help">
        {intl.formatMessage(messages.professionalExperiencesHelp)}
      </p>
      {professional_experienceState.map((we, i) => (
        <div className="form-fields-container" key={i}>
          <h5>
            {intl.formatMessage(
              i === 0
                ? messages.professionalExperiencesMain
                : messages.professionalExperiencesOther,
            )}
          </h5>
          <Form.Group widths={'equal'}>
            <SelectWidget
              id={`professional_experience[${i}].settore`}
              title={intl.formatMessage(messages.settore)}
              value={we.settore}
              onChange={(id, value) => handleOnChange('settore', value, i)}
              vocabBaseUrl="raiway.lavoraconnoi.Sector"
              noValueOption={false}
              required={true}
              control={control}
              isDisabled={isDisabled}
            />
            <SelectWidget
              id={`professional_experience[${i}].area`}
              title={intl.formatMessage(messages.area)}
              value={we.area}
              onChange={(id, value) => handleOnChange('area', value, i)}
              vocabBaseUrl="raiway.lavoraconnoi.Area"
              noValueOption={false}
              required={true}
              control={control}
              isDisabled={isDisabled}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <SelectWidget
              key={we.area}
              id={`professional_experience[${i}].attivita`}
              title={intl.formatMessage(messages.attivita)}
              value={we.attivita}
              onChange={(id, value) => handleOnChange('attivita', value, i)}
              vocabBaseUrl={we.area}
              noValueOption={false}
              required={true}
              control={control}
              isDisabled={isDisabled || !we.area}
            />
            <SelectWidget
              id={`professional_experience[${i}].ruolo`}
              title={intl.formatMessage(messages.ruolo)}
              value={we.ruolo}
              onChange={(id, value) => handleOnChange('ruolo', value, i)}
              vocabBaseUrl="raiway.lavoraconnoi.Ruolo"
              noValueOption={false}
              required={true}
              control={control}
              isDisabled={isDisabled}
            />
          </Form.Group>
          <Form.Group widths={2}>
            <SelectWidget
              isDisabled={isDisabled}
              id={`professional_experience[${i}].tipologia_contrattuale`}
              title={intl.formatMessage(messages.contractType)}
              value={we.tipologia_contrattuale}
              onChange={(id, value) =>
                handleOnChange('tipologia_contrattuale', value, i)
              }
              vocabBaseUrl="raiway.lavoraconnoi.TipologiaContrattuale"
              noValueOption={false}
              required={true}
              control={control}
            />
            {we.tipologia_contrattuale === OTHER_DEGREE_TOKEN && (
              <TextWidget
                isDisabled={isDisabled}
                id={`professional_experience[${i}].tipologia_contrattuale_altro`}
                type="text"
                name="tipologia_contrattuale_altro"
                value={we.tipologia_contrattuale_altro}
                onChange={(id, value) =>
                  handleOnChange('tipologia_contrattuale_altro', value, i)
                }
                required={we.tipologia_contrattuale === OTHER_DEGREE_TOKEN}
                title={intl.formatMessage(messages.contractTypeOther)}
                wrapped={true}
                control={control}
              />
            )}
          </Form.Group>
          <Form.Group widths={'equal'}>
            <TextWidget
              isDisabled={isDisabled}
              id={`professional_experience[${i}].azienda`}
              type="text"
              name="azienda"
              value={we.azienda}
              onChange={(id, value) => handleOnChange('azienda', value, i)}
              required={false}
              title={intl.formatMessage(messages.company)}
              wrapped={true}
              control={control}
            />
            <TextWidget
              isDisabled={isDisabled}
              id={`professional_experience[${i}].luogo`}
              type="text"
              name="luogo"
              value={we.luogo}
              onChange={(id, value) => handleOnChange('luogo', value, i)}
              required={false}
              title={intl.formatMessage(messages.companyLocation)}
              wrapped={true}
              control={control}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DatetimeWidgetComponent
              isDisabled={isDisabled}
              id={`professional_experience[${i}].data_inizio`}
              title={intl.formatMessage(messages.startDate)}
              required={true}
              onChange={(id, value) => handleOnChange('data_inizio', value, i)}
              wrapped={true}
              value={we.data_inizio}
              dateOnly={true}
              control={control}
              // TODO month only?
            />
            <FormFieldWrapper
              id={`professional_experience[${i}].ongoing_experience`}
              title={intl.formatMessage(messages.ongoingContract)}
              wrapped
              className=""
              required={true}
              control={control}
            >
              <CheckboxWidget
                isDisabled={isDisabled}
                id={`professional_experience[${i}].ongoing_experience`}
                checked={we.ongoing_experience}
                value={true}
                radio={true}
                title={intl.formatMessage(messages.yes)}
                onChange={(id, value) =>
                  handleOnChange('ongoing_experience', value, i)
                }
              />
              <CheckboxWidget
                isDisabled={isDisabled}
                id={`professional_experience[${i}].ongoing_experience`}
                checked={!we.ongoing_experience}
                value={false}
                radio={true}
                title={intl.formatMessage(messages.no)}
                onChange={(id, value) =>
                  handleOnChange('ongoing_experience', value, i)
                }
              />
            </FormFieldWrapper>
          </Form.Group>
          <Form.Group widths={'equal'}>
            <DatetimeWidgetComponent
              isDisabled={isDisabled || we.ongoing_experience}
              id={`professional_experience[${i}].data_fine`}
              title={intl.formatMessage(messages.endDateWE)}
              required={!we.ongoing_experience}
              onChange={(id, value) => handleOnChange('data_fine', value, i)}
              wrapped={true}
              value={we.data_fine}
              dateOnly={true}
              control={control}
              // TODO month only?
            />
            <TextWidget
              isDisabled={isDisabled || !we.ongoing_experience}
              id={`professional_experience[${i}].notice_to_end_experience`}
              name="notice_to_end_experience"
              value={we.notice_to_end_experience}
              onChange={(id, value) =>
                handleOnChange('notice_to_end_experience', value, i)
              }
              title={intl.formatMessage(messages.daysNotice)}
              required={we.ongoing_experience}
              wrapped={true}
              control={control}
              popup={
                <Popup
                  on={['click', 'hover']}
                  trigger={<Icon name="question circle" />}
                  content={intl.formatMessage(messages.preavvisoPopup)}
                  position="top left"
                  hideOnScroll={true}
                />
              }
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <TextareaWidget
              isDisabled={isDisabled}
              id={`professional_experience[${i}].descrizione`}
              type="text"
              name="descrizione"
              defaultValue={we.descrizione}
              onChange={(id, value) => handleOnChange('descrizione', value, i)}
              required={false}
              title={intl.formatMessage(messages.description)}
              placeholder={intl.formatMessage(messages.description)}
              wrapped={true}
              control={control}
            />
          </Form.Group>
          {!isDisabled && (
            <Button
              className="rw--button remove-fieldset removeCV active"
              onClick={() => removeWE(i)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          )}
        </div>
      ))}
      <div className="add-new-fieldset">
        <Button className="rw--button rw--button-outline" onClick={addNewWE}>
          {intl.formatMessage(messages.add, {
            context: intl.formatMessage(messages.professionalExperiences),
          })}
        </Button>
      </div>
    </div>
  );
};

export default WorkExperienceFields;
