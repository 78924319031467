/**
 * Register container.
 * @module components/theme/Register/Register
 * Customization:
 * - added GoogleReCaptcha
 * - added privacy acceptance checkbox
 * - added custom "autoregistered" info to be used in the backend
 */

import React, { Component } from 'react';
import { Helmet } from '@plone/volto/helpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Form, Toast } from '@plone/volto/components';
import { createUser } from '@plone/volto/actions';
import { isFromLinkedin } from '../backoffice-lavoraconnoi/LinkedinTracker/LinkedinTracker';

const messages = defineMessages({
  title: {
    id: 'Registration form',
    defaultMessage: 'Registration form',
  },
  default: {
    id: 'Default',
    defaultMessage: 'Default',
  },
  fullnameTitle: {
    id: 'Full Name',
    defaultMessage: 'Full Name',
  },
  fullnameDescription: {
    id: 'Enter full name, e.g. John Smith.',
    defaultMessage: 'Enter full name, e.g. John Smith.',
  },
  emailTitle: {
    id: 'E-mail',
    defaultMessage: 'E-mail',
  },
  emailDescription: {
    id:
      'Enter an email address. This will be your login name. We respect your privacy, and will not give the address away to any third parties or expose it anywhere.',
    defaultMessage:
      'Enter an email address. This will be your login name. We respect your privacy, and will not give the address away to any third parties or expose it anywhere.',
  },
  successRegisterCompletedTitle: {
    id: 'Account Registration Completed',
    defaultMessage: 'Account Registration Completed',
  },
  successRegisterCompletedBody: {
    id:
      'The registration process has been successful. Please check your e-mail inbox for information on how activate your account.',
    defaultMessage:
      'The registration process has been successful. Please check your e-mail inbox for information on how activate your account.',
  },
  register: {
    id: 'Register',
    defaultMessage: 'Register',
  },
  registerIntro: {
    id: 'registerIntro',
    defaultMessage:
      "La registrazione ti consentirà l'accesso all'area riservata del sito. In tale area potrai inserire e mantenere aggiornato il tuo curriculum vitae, rendendolo così disponibile alle nostre consultazioni. Potrai inoltre candidarti alle eventuali ricerche di personale pubblicate da Rai Way. Dopo aver compilato il form sottostante, il sistema creerà automaticamente un account e ti invierà una mail alla casella di posta indicata. Il completamento della registrazione e l'attivazione dell'account avverranno cliccando sul link che troverai all'interno dell'e-mail.",
  },
  privacyCheckboxTitle: {
    id: 'privacyCheckboxTitle',
    defaultMessage: 'Ho letto e accetto le condizioni di privacy',
  },
  privacyCheckboxDescription: {
    id: 'privacyCheckboxDescription',
    defaultMessage:
      'Registrandomi dichiaro di aver letto e compreso l’informativa sul trattamento dei dati personali',
  },
  duplicate_mail: {
    id: 'duplicate_mail',
    defaultMessage: 'Email già in uso.',
  },
});

/**
 * Register class.
 * @class Register
 * @extends Component
 */
class Register extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    createUser: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    loaded: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
    GoogleReCaptcha: PropTypes.any,
  };

  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static defaultProps = {
    error: null,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs WysiwygEditor
   */
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      error: null,
      validToken: null,
    };
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.loading && nextProps.loaded) {
      toast.success(
        <Toast
          success
          title={this.props.intl.formatMessage(
            messages.successRegisterCompletedTitle,
          )}
          content={this.props.intl.formatMessage(
            messages.successRegisterCompletedBody,
          )}
        />,
      );
      this.props.history.push('/login');
    }
  }

  /**
   * On submit handler
   * @method onSubmit
   * @param {object} data Form data.
   * @returns {undefined}
   */
  onSubmit(data) {
    let autoregistered = 'website';
    if (isFromLinkedin()) {
      autoregistered = 'linkedin';
    }

    this.props.createUser({
      fullname: data.fullname,
      email: data.email,
      password: data.password,
      privacy_checkbox: data.privacy,
      'g-recaptcha-response': this.state.validToken,
      autoregistered,
    });
    this.setState({
      error: null,
    });
  }

  onVerifyCaptcha = (token) => {
    this.setState({
      validToken: token,
    });
  };

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const error =
      this.props.error?.response?.text &&
      JSON.parse(this.props.error.response.text)?.type === 'ValueError'
        ? this.props.intl.formatMessage(messages.duplicate_mail)
        : this.props.error;

    return (
      <div id="page-register">
        <Helmet title={this.props.intl.formatMessage(messages.register)} />
        <Container className="intro-text">
          <p>{this.props.intl.formatMessage(messages.registerIntro)}</p>
        </Container>
        <Form
          onSubmit={!!this.state.validToken ? this.onSubmit : undefined}
          title={this.props.intl.formatMessage(messages.title)}
          error={error ? { message: error } : null}
          loading={this.props.loading}
          submitLabel={this.props.intl.formatMessage(messages.register)}
          schema={{
            fieldsets: [
              {
                id: 'default',
                title: this.props.intl.formatMessage(messages.default),
                fields: ['fullname', 'email', 'privacy', 'captcha'],
              },
            ],
            properties: {
              fullname: {
                type: 'string',
                title: this.props.intl.formatMessage(messages.fullnameTitle),
                description: this.props.intl.formatMessage(
                  messages.fullnameDescription,
                ),
              },
              email: {
                type: 'string',
                title: this.props.intl.formatMessage(messages.emailTitle),
                description: this.props.intl.formatMessage(
                  messages.emailDescription,
                ),
              },
              privacy: {
                type: 'boolean',
                title: this.props.intl.formatMessage(
                  messages.privacyCheckboxTitle,
                ),
                description: (
                  <a
                    href={'/' + this.props.intl.locale + '/privacy-policy'}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {this.props.intl.formatMessage(
                      messages.privacyCheckboxDescription,
                    )}
                    <FontAwesomeIcon
                      icon={['far', 'external-link-alt']}
                      className="privacy-icon"
                    />
                  </a>
                ),
              },
              captcha: {
                onVerify: this.onVerifyCaptcha,
              },
            },
            required: ['fullname', 'email', 'privacy'],
          }}
        />
      </div>
    );
  }
}

export default compose(
  withRouter,
  injectIntl,
  connect(
    (state) => ({
      loading: state.users.create.loading,
      loaded: state.users.create.loaded,
      error: state.users.create.error,
    }),
    { createUser },
  ),
)(Register);
