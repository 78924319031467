import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'spb';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '632'}
      height={size ?? '283'}
      fill="none"
      viewBox="0 0 632 283"
      className={className}
      key={key}
      {...otherProps}
    >
      <g clipPath={`url(#${uniqueID}clip0)`}>
        <path
          fill={`url(#${uniqueID}paint0_linear)`}
          d="M165.772
        259.877V54.216h206.21v205.661h-206.21zm11.133-194.658v183.555H360.95V65.219H176.905z"
        />
        <path
          fill={`url(#${uniqueID}paint1_linear)`}
          d="M298.076 109.492h115.7V225.57h-115.7V109.492zm107.4 8.327h-99.1v99.424h99.1v-99.424z"
        />
        <path
          fill={`url(#${uniqueID}paint2_linear)`}
          d="M446.978 139.991h78v78.255h-78v-78.255zm72.5 5.518h-66.9v67.119h66.9v-67.119z"
        />
        <path
          fill={`url(#${uniqueID}paint3_linear)`}
          d="M469.976 179.721h-86.8V92.637h86.8v87.084zm-79.9-6.923h73V99.56h-73v73.238z"
        />
        <path
          fill={`url(#${uniqueID}paint4_linear)`}
          d="M58.163.838h58.4V62.94h-58.4v-6.922h51.5V7.86h-51.5V.838z"
        />
        <path
          fill={`url(#${uniqueID}paint5_linear)`}
          d="M53.578 62.94h6.9V.838h-6.9V62.94z"
        />
        <path
          fill={`url(#${uniqueID}paint6_linear)`}
          d="M267.277 282.255H.477V14.583h266.8v267.672zm-253-13.946h239.1V28.529h-239.1v239.78z"
        />
        <path
          fill={`url(#${uniqueID}paint7_linear)`}
          d="M459.707 241.251v-47.114h47.239v47.114h-47.239zm4.212-42.913v38.812h38.915v-38.812h-38.915z"
        />
        <path
          fill={`url(#${uniqueID}paint8_linear)`}
          d="M571.677 186.442h-31.901v-32.004h31.901v32.004zm-29.101-2.708h26.301v-26.386h-26.301v26.386z"
        />
        <path
          fill={`url(#${uniqueID}paint9_linear)`}
          d="M510.507 168.615v-47.114h47.24v47.114h-47.24zm4.113-43.013v38.812h38.915v-38.812H514.62z"
        />
        <path
          fill={`url(#${uniqueID}paint10_linear)`}
          d="M642.676 170.19h-29.8v-29.898h29.8v29.898zm-27.7-2.007h25.6v-25.684h-25.6v25.684z"
        />
        <path
          fill={`url(#${uniqueID}paint11_linear)`}
          d="M599.077 145.008h-29.8v-29.797h29.8v29.797zm-27.7-2.107h25.6v-25.684h-25.6v25.684z"
        />
        <path
          fill={`url(#${uniqueID}paint12_linear)`}
          d="M614.977 133.872h-29.8v-29.898h29.8v29.898zm-27.7-2.107h25.6v-25.684h-25.6v25.684z"
        />
        <path
          fill={`url(#${uniqueID}paint13_linear)`}
          d="M163.076 126.848h-92.2V34.347h92.2v92.501zm-85.2-6.923h78.3V41.37h-78.3v78.555z"
        />
      </g>
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="223.679"
          x2="313.635"
          y1="33.199"
          y2="281.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="425.6"
          x2="286.168"
          y1="142.088"
          y2="192.672"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="532.948"
          x2="438.95"
          y1="161.966"
          y2="196.067"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint3_linear`}
          className="paint_linear"
          x1="374.306"
          x2="478.909"
          y1="155.267"
          y2="117.318"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint4_linear`}
          className="paint_linear"
          x1="123"
          x2="47.406"
          y1="18.277"
          y2="46.182"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint5_linear`}
          className="paint_linear"
          x1="123"
          x2="47.406"
          y1="18.277"
          y2="46.182"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint6_linear`}
          className="paint_linear"
          x1="-26.788"
          x2="294.736"
          y1="207.089"
          y2="90.445"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint7_linear`}
          className="paint_linear"
          x1="472.972"
          x2="493.58"
          y1="189.322"
          y2="246.092"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint8_linear`}
          className="paint_linear"
          x1="536.517"
          x2="574.96"
          y1="177.455"
          y2="163.508"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint9_linear`}
          className="paint_linear"
          x1="523.773"
          x2="544.381"
          y1="116.686"
          y2="173.456"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint10_linear`}
          className="paint_linear"
          x1="609.831"
          x2="645.743"
          y1="161.794"
          y2="148.765"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint11_linear`}
          className="paint_linear"
          x1="566.232"
          x2="602.116"
          y1="136.64"
          y2="123.578"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint12_linear`}
          className="paint_linear"
          x1="582.132"
          x2="618.044"
          y1="125.476"
          y2="112.447"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint13_linear`}
          className="paint_linear"
          x1="61.454"
          x2="172.565"
          y1="100.872"
          y2="60.563"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <clipPath id={`${uniqueID}clip0`}>
          <path
            fill="#fff"
            d="M0 0H631V281.918H0z"
            transform="translate(0.476562 0.837891)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Icon;
