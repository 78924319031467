import {
  SAVE_CV_SEARCH,
  GET_CV_SAVED_SEARCHES,
  RESET_CV_SAVED_SEARCHES,
} from '@package/constants/ActionTypes';

const searchInitialState = {
  error: null,
  items: [],
  total: 0,
  loaded: false,
  loading: false,
};

const saveInitialState = {
  error: null,
  loaded: false,
  loading: false,
};

export function saveCvSearch(state = saveInitialState, action = {}) {
  switch (action.type) {
    case `${SAVE_CV_SEARCH}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${SAVE_CV_SEARCH}_SUCCESS`:
      return {
        ...state,
        error: null,
        loading: false,
        loaded: true,
      };
    case `${SAVE_CV_SEARCH}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function savedCvSearches(state = searchInitialState, action = {}) {
  switch (action.type) {
    case `${GET_CV_SAVED_SEARCHES}_PENDING`:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case `${GET_CV_SAVED_SEARCHES}_SUCCESS`:
      try {
        return {
          ...state,
          error: null,
          items: JSON.parse(action.result),
          total: action.result.items_total,
          loaded: true,
          loading: false,
        };
      } catch {
        return {
          ...state,
          error: {},
          loading: false,
          loaded: false,
        };
      }
    case `${GET_CV_SAVED_SEARCHES}_FAIL`:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case RESET_CV_SAVED_SEARCHES:
      return { ...searchInitialState };
    default:
      return state;
  }
}
