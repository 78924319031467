import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import {
  CheckboxWidget,
  ObjectListWidget,
  SelectWidget,
} from '@plone/volto/components';
import { AlignWidget } from '@package/components/Widgets';

const messages = defineMessages({
  fullwidth_title: {
    id: 'fullwidth_title',
    defaultMessage: 'A tutta larghezza',
  },
  fullwidth_description: {
    id: 'fullwidth_description',
    defaultMessage: 'Espande lo slider a tutta larghezza',
  },
  size: {
    id: 'Size',
    defaultMessage: 'Dimensione',
  },
  size_description: {
    id: 'size_description',
    defaultMessage: "Imposta l'altezza del blocco",
  },
  autoplay: {
    id: 'autoplay',
    defaultMessage: 'Autoplay',
  },
  autoplay_speed: {
    id: 'autoplay_speed',
    defaultMessage: "Velocità dell'autoplay",
  },
  autoplay_description: {
    id: 'autoplay_description',
    defaultMessage:
      'Se autoplay è attivo, si può scegliere la velocità (in secondi)',
  },
  img_amount: {
    id: 'img_amount',
    defaultMessage: 'Immagini visibili',
  },
  img_amount_description: {
    id: 'img_amount_description',
    defaultMessage: 'Selezionare la quantità di immagini visibili nello slider',
  },
  slideToScrollArrows: {
    id: 'slideToScrollArrows',
    defaultMessage: 'Scroll per le frecce',
  },
  slideToScrollArrows_description: {
    id: 'slideToScrollArrows_description',
    defaultMessage:
      'Selezionare la quantità di immagini da scorrere con le frecce',
  },
  slidesToScroll: {
    id: 'slidesToScroll',
    defaultMessage: 'Scroll per i pallini',
  },
  slidesToScroll_description: {
    id: 'slidesToScroll_description',
    defaultMessage:
      'Selezionare la quantità di immagini da scorrere coi pallini',
  },
  img_align: {
    id: 'img_align',
    defaultMessage: 'Allineamento immagini',
  },
  add_slide: {
    id: 'add_slide',
    defaultMessage: 'Aggiungi una Slide',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Immagine',
  },
  alt_title: {
    id: 'alt_title',
    defaultMessage: 'Testo alt',
  },
  alt_title_description: {
    id: 'alt_title_description',
    defaultMessage: "Descrizione breve dell'immagine",
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  const { data, block, onChangeBlock } = props;
  const optionsNumbers = [
    [1, '1'],
    [2, '2'],
    [3, '3'],
    [4, '4'],
    [5, '5'],
    [6, '6'],
    [7, '7'],
    [8, '8'],
    [9, '9'],
    [10, '10'],
  ];

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="imageViewerBlock"
            defaultMessage="Blocco Visualizzatore Immagini"
          />
          :
        </h2>
      </header>

      <Segment className="form sidebar-listing-data">
        <CheckboxWidget
          id="fullwidth"
          title={intl.formatMessage(messages.fullwidth_title)}
          description={intl.formatMessage(messages.fullwidth_description)}
          value={data.fullwidth ?? false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <AlignWidget
          id="img_align"
          title={intl.formatMessage(messages.img_align)}
          alignments={['top', 'middle', 'bottom']}
          value={data.img_align ?? 'middle'}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <Segment className="form">
          <CheckboxWidget
            id="autoplay"
            title={intl.formatMessage(messages.autoplay)}
            value={data.autoplay ?? false}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
          />
          <SelectWidget
            id="autoplay_speed"
            title={intl.formatMessage(messages.autoplay_speed)}
            description={intl.formatMessage(messages.autoplay_description)}
            choices={optionsNumbers}
            value={data.autoplay_speed ?? 3}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />

          <SelectWidget
            id="img_amount"
            title={intl.formatMessage(messages.img_amount)}
            description={intl.formatMessage(messages.img_amount_description)}
            choices={optionsNumbers}
            value={data.img_amount ?? 1}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />
          <SelectWidget
            id="slidesToScrollArrows"
            title={intl.formatMessage(messages.slideToScrollArrows)}
            description={intl.formatMessage(
              messages.slideToScrollArrows_description,
            )}
            choices={optionsNumbers}
            value={data.slidesToScrollArrows ?? 1}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
          />
          <SelectWidget
            id="slidesToScroll"
            title={intl.formatMessage(messages.slidesToScroll)}
            description={intl.formatMessage(
              messages.slidesToScroll_description,
            )}
            choices={optionsNumbers}
            value={data.slidesToScroll ?? data.img_amount ?? 1}
            onChange={(name, value) => {
              onChangeBlock(block, { ...data, [name]: value });
            }}
          />
        </Segment>

        <ObjectListWidget
          {...props}
          id="slides"
          className="form sidebar-listing-data"
          value={data?.slides ?? []}
          onChange={(id, value) =>
            props.onChangeBlock(block, { ...data, [id]: value })
          }
          schema={() => {
            const slideSchema = {
              title: 'Slide',
              addMessage: intl.formatMessage(messages.add_slide),
              fieldsets: [
                {
                  id: 'default',
                  title: 'Default',
                  fields: ['image', 'href', 'alt'],
                },
              ],
              properties: {
                image: {
                  title: intl.formatMessage(messages.image),
                  widget: 'object_browser',
                  mode: 'image',
                  allowExternals: true,
                },
                href: {
                  title: 'Link',
                  widget: 'object_browser',
                  mode: 'link',
                  allowExternals: true,
                },
                alt: {
                  title: intl.formatMessage(messages.alt_title),
                  description: intl.formatMessage(
                    messages.alt_title_description,
                  ),
                  type: 'string',
                },
              },

              required: ['image', 'alt'],
            };

            return slideSchema;
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default Sidebar;
