import { useSelector } from 'react-redux';

import { ArrayWidget } from '@plone/volto/components';

const SelectQuerystring = (props) => {
  const querystring = useSelector((state) => state.querystring);

  const values = querystring.indexes[props.querystringField].values;
  const choices = Object.keys(values).map((e) => {
    return { label: values[e].title, value: e };
  });

  return ArrayWidget({ ...props, choices });
};

export default SelectQuerystring;
