import DefaultView from '@plone/volto/components/theme/View/DefaultView';
import { UserProfileView } from '@package/components';

export const RaiWayViews = (config) => {
  config.views.contentTypesViews = {
    ...config.views.contentTypesViews,
    'News Item': DefaultView,
    JobPosting: DefaultView,
    Event: DefaultView,
    CV: UserProfileView,
  };
};
