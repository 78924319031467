import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Grid, Button } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { AlignWidget } from '@package/components/Widgets';
import { CheckboxWidget, Icon } from '@plone/volto/components';
import cx from 'classnames';

import squareMosaicGrey from '@package/icons/squares_mosaic_grey.svg';
import squarePrimaryGrey from '@package/icons/squares_primary_1_flat_grey.svg';
import squareSecondaryGrey from '@package/icons/squares_secondary_1_grey.svg';
import squarePrimarySmallGrey from '@package/icons/squares_small_primary_grey.svg';

const messages = defineMessages({
  showBgGrey: {
    id: 'Sfondo grigio',
    defaultMessage: 'Sfondo grigio',
  },
  fullwidthLabel: {
    id: 'Imposta sfondo a larghezza intera',
    defaultMessage: 'Imposta sfondo a larghezza intera',
  },
  showLine: {
    id: 'Linea alla base del segno',
    defaultMessage: 'Linea alla base del segno',
  },
  alignSignLabel: {
    id: 'Allineamento segno grafico',
    defaultMessage: 'Allineamento segno grafico',
  },
  stickOnTop: {
    id: 'Attacca in alto il segno grafico',
    defaultMessage: 'Attacca in alto il segno grafico',
  },
  selectSignLabel: {
    id: 'Seleziona un segno grafico',
    defaultMessage: 'Seleziona un segno grafico',
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  const { data, block, onChangeBlock } = props;

  const greySigns = [
    {
      id: 'squareMosaicGrey',
      image: squareMosaicGrey,
    },
    {
      id: 'squarePrimaryGrey',
      image: squarePrimaryGrey,
    },
    {
      id: 'squarePrimarySmallGrey',
      image: squarePrimarySmallGrey,
    },
    {
      id: 'squareSecondaryGrey',
      image: squareSecondaryGrey,
    },
  ];

  let currentSign = data.sign ? data.sign : greySigns[0].id;

  const drawSigns = (signs) => {
    return signs.length > 0 ? (
      <>
        <Grid centered verticalAlign="middle" className="signs">
          <Grid.Row columns={2}>
            {signs.map((sign) => (
              <Grid.Column
                key={sign.id}
                textAlign="center"
                verticalAlign="middle"
                stretched
              >
                <Button
                  basic
                  icon
                  onClick={(name, value) => {
                    onChangeBlock(block, { ...data, sign: sign.id });
                    currentSign = data.sign;
                  }}
                  className={cx({
                    'selected-sign': currentSign === sign.id,
                  })}
                  key={sign.id}
                >
                  {sign.image?.attributes && (
                    <Icon
                      key={sign.id}
                      name={sign.image}
                      size="40px"
                      className="sidebar-icon"
                    />
                  )}
                </Button>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </>
    ) : (
      <></>
    );
  };

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="SignedSeparator"
            defaultMessage="Separatore con segno grafico"
          />
          :
        </h2>
      </header>

      <Segment className="form sidebar-listing-data sidebar-signed-separator">
        <CheckboxWidget
          id="background"
          title={intl.formatMessage(messages.showBgGrey)}
          value={data.background ? data.background : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <CheckboxWidget
          id="fullwidth"
          title={intl.formatMessage(messages.fullwidthLabel)}
          value={data.fullwidth ? data.fullwidth : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
      <Segment className="form sidebar-listing-data sidebar-signed-separator">
        <CheckboxWidget
          id="stickOnTop"
          title={intl.formatMessage(messages.stickOnTop)}
          value={data.stickOnTop ? data.stickOnTop : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, stickOnTop: value });
          }}
        />
        <AlignWidget
          id="align"
          title={intl.formatMessage(messages.alignSignLabel)}
          alignments={['left', 'right']}
          value={data.align ? data.align : 'left'}
          fieldSet="default"
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, align: value });
          }}
        />
      </Segment>
      <Segment className="form sidebar-listing-data sidebar-signed-separator">
        <CheckboxWidget
          id="line"
          title={intl.formatMessage(messages.showLine)}
          value={data.line ? data.line : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, line: value });
          }}
        />
        <div className="section-sign">
          <div className="small">
            {intl.formatMessage(messages.selectSignLabel)}
          </div>
          {drawSigns(greySigns)}
        </div>
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default Sidebar;
