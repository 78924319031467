import {
  GET_CV_CUSTOM_FIELDS,
  SAVE_CV_CUSTOM_FIELDS,
  RESET_CV_CUSTOM_FIELDS,
} from '@package/constants/ActionTypes';

export function saveCvCustomFields(fields) {
  return {
    type: SAVE_CV_CUSTOM_FIELDS,
    request: {
      op: 'patch',
      path: '/@custom_fields/',
      data: {
        'raiway.custom_fields': JSON.stringify(fields),
      },
    },
  };
}

export function getCvCustomFields() {
  return {
    type: GET_CV_CUSTOM_FIELDS,
    request: {
      op: 'get',
      path: '/@custom_fields/raiway.custom_fields',
    },
  };
}

export function resetCvCustomFields(resetItems = false) {
  return {
    type: RESET_CV_CUSTOM_FIELDS,
    resetItems,
  };
}
