import { SelectFacetFilterListEntry } from '@plone/volto/components/manage/Blocks/Search/components';
import SelectFixedOptionsFacet from '@package/components/Blocks/Search/SelectFixedOptionsFacet';
import CheckboxFixedOptionsFacet from '@package/components/Blocks/Search/CheckboxFixedOptionsFacet';

export const updateSearchBlockExtensions = (config) => {
  config.blocks.blocksConfig.search.extensions.facetWidgets.types.push({
    id: 'selectFixedChoicesFacet',
    title: 'Select con opzioni fisse',
    view: SelectFixedOptionsFacet,
    isDefault: false,
    schemaEnhancer: SelectFixedOptionsFacet.schemaEnhancer,
    stateToValue: SelectFixedOptionsFacet.stateToValue,
    valueToQuery: SelectFixedOptionsFacet.valueToQuery,
    filterListComponent: SelectFacetFilterListEntry,
  });

  config.blocks.blocksConfig.search.extensions.facetWidgets.types.push({
    id: 'checkboxFixedChoicesFacet',
    title: 'Checkbox con opzioni fisse',
    view: CheckboxFixedOptionsFacet,
    isDefault: false,
    schemaEnhancer: CheckboxFixedOptionsFacet.schemaEnhancer,
    stateToValue: CheckboxFixedOptionsFacet.stateToValue,
    valueToQuery: CheckboxFixedOptionsFacet.valueToQuery,
    filterListComponent: SelectFacetFilterListEntry,
  });
};
