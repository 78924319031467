/* eslint-disable react-hooks/exhaustive-deps */
/**
 * View icons block.
 * @module components/manage/Blocks/IconsBlocks/View
 */

import React, { createRef } from 'react';
// import PropTypes from 'prop-types';
import Body from './Body';

/**
 * View icons blocks class.
 * @class View
 * @extends Component
 */
const View = (props) => {
  const listingRef = createRef();
  return (
    <div className="block jobpostingListing">
      <Body {...props} isEditMode={false} listingRef={listingRef} />
    </div>
  );
};

export default View;
