import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';

const GenericLCNListingItem = (props) => {
  const intl = useIntl();
  props.moment.default.locale(intl.locale);
  const {
    firstColumnContent,
    secondColumnContent,
    thirdColumnContent,
    fourthColumnContent,
    fifthColumnContent,
    additionalColumns = [],
    itemUrl,
    columns = 5,
  } = props;
  return (
    <Grid.Row
      className="rw--jobposting-item"
      columns={columns}
      stretched
      as={UniversalLink}
      href={itemUrl}
    >
      <Grid.Column
        mobile={2}
        tablet={1}
        computer={firstColumnContent.width ?? 1}
        className={firstColumnContent.className}
      >
        {firstColumnContent.content?.desktop}
      </Grid.Column>
      <Grid.Column
        only="tablet mobile"
        tablet={9}
        mobile={8}
        className={'mobile-info-layout'}
      >
        <Grid.Row
          className={cx('item-title-container', secondColumnContent.className)}
        >
          {secondColumnContent.content?.mobile
            ? secondColumnContent?.content.mobile
            : secondColumnContent?.content}
        </Grid.Row>
        <Grid.Row className={cx('item-created', thirdColumnContent.className)}>
          {thirdColumnContent.content?.mobile
            ? thirdColumnContent.content.mobile
            : thirdColumnContent.content}
        </Grid.Row>
        <Grid.Row className={cx(fourthColumnContent.className)}>
          {fourthColumnContent.content?.mobile
            ? fourthColumnContent.content.mobile
            : fourthColumnContent.content}
        </Grid.Row>
        {additionalColumns.length > 0 &&
          additionalColumns.map((column) => (
            <Grid.Row mobile={8} tablet={9} className={column.className}>
              {column.content?.mobile ? column.content.mobile : column.content}
            </Grid.Row>
          ))}
        <Grid.Row className={cx(fifthColumnContent.className)}>
          {fifthColumnContent.content?.chips}
        </Grid.Row>
      </Grid.Column>
      <Grid.Column
        only="tablet mobile"
        width={2}
        className={'icon-container mobile'}
      >
        {fifthColumnContent.content?.button}
      </Grid.Column>
      <Grid.Column
        only="large screen"
        computer={secondColumnContent.width ?? 4}
        className={cx('item-title-container', secondColumnContent.className)}
      >
        {secondColumnContent.content?.desktop
          ? secondColumnContent.content.desktop
          : secondColumnContent.content}
      </Grid.Column>
      <Grid.Column
        only="large screen"
        computer={thirdColumnContent.width ?? 3}
        className={cx('item-created', thirdColumnContent.className)}
      >
        {thirdColumnContent.content?.desktop
          ? thirdColumnContent.content.desktop
          : thirdColumnContent.content}
      </Grid.Column>
      <Grid.Column
        only="large screen"
        computer={fourthColumnContent.width ?? 3}
        className={cx(fourthColumnContent.className)}
      >
        {fourthColumnContent.content?.desktop
          ? fourthColumnContent.content?.desktop
          : fourthColumnContent.content}
      </Grid.Column>
      {additionalColumns.length > 0 &&
        additionalColumns.map((column) => (
          <Grid.Column
            only="large screen"
            computer={column.width ?? 3}
            className={column.className}
          >
            {column.content?.desktop ? column.content.desktop : column.content}
          </Grid.Column>
        ))}
      <Grid.Column
        only="large screen"
        computer={fifthColumnContent.width ?? 1}
        className={cx('icon-container', fifthColumnContent.className)}
      >
        {fifthColumnContent.content?.chips}
        {fifthColumnContent.content?.button}
      </Grid.Column>
    </Grid.Row>
  );
};

GenericLCNListingItem.propTypes = {
  firstColumnContent: PropTypes.shape({
    content: PropTypes.any,
    className: PropTypes.string,
    width: PropTypes.number,
  }),
  secondColumnContent: PropTypes.shape({
    content: PropTypes.any,
    className: PropTypes.string,
    width: PropTypes.number,
  }),
  thirdColumnContent: PropTypes.shape({
    content: PropTypes.any,
    className: PropTypes.string,
    width: PropTypes.number,
  }),
  fourthColumnContent: PropTypes.shape({
    content: PropTypes.any,
    className: PropTypes.string,
    width: PropTypes.number,
  }),
  fifthColumnContent: PropTypes.shape({
    content: PropTypes.any,
    className: PropTypes.string,
    width: PropTypes.number,
  }),
  additionalColumns: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.any,
      className: PropTypes.string,
      width: PropTypes.number,
    }),
  ),
  columns: PropTypes.number,
  itemUrl: PropTypes.string,
};

export default injectLazyLibs(['moment'])(GenericLCNListingItem);
