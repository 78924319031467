/**
 * Edit icons block.
 * @module components/manage/Blocks/Title/Edit
 */

import React from 'react';

import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';
import Body from './Body';

const Edit = (props) => {
  const { selected } = props;

  return __SERVER__ ? (
    <div />
  ) : (
    <div className="block jobpostingListing">
      <Body {...props} isEditMode={true} />

      <SidebarPortal selected={selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </div>
  );
};

export default Edit;
