import React, { useState, useEffect, useRef } from 'react';
import { PresetWrapper } from '@package/components';
import { Container, Image } from 'semantic-ui-react';
import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useIntl, defineMessages } from 'react-intl';
import { Icon } from 'semantic-ui-react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import cx from 'classnames';

const messages = defineMessages({
  viewImage: {
    id: "Vedi l'immagine",
    defaultMessage: "Vedi l'immagine",
  },
  play: {
    id: 'Play slider',
    defaultMessage: 'Play',
  },
  pause: {
    id: 'Pause slider',
    defaultMessage: 'Metti in pausa',
  },
});

const Body = ({ data, inEditMode }) => {
  const intl = useIntl();

  const [userAutoplay, setUserAutoplay] = useState(data?.autoplay ?? false);
  const [state, setState] = useState({
    nav1: null,
    nav2: null,
  });

  const slider1 = useRef();
  const slider2 = useRef();
  const { nav1, nav2 } = state;

  useEffect(() => {
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
    });
  }, []);

  const toggleAutoplay = () => {
    if (!slider1?.current) return;
    if (userAutoplay) {
      setUserAutoplay(false);
      slider1.current.slickPause();
    } else {
      setUserAutoplay(true);
      slider1.current.slickPlay();
    }
  };

  const settingsSlider = (slide_to_scroll = 1, dots = false, arrows = true) => {
    let n_items = data?.slides?.length ?? 0;
    let slides_to_show = data?.img_amount ?? 1;
    let autoplay_speed = data?.autoplay_speed
      ? data.autoplay_speed * 1000
      : 3 * 1000;

    return {
      slidesToShow: slides_to_show >= n_items ? n_items : slides_to_show,
      slidesToScroll: slide_to_scroll,
      dots: dots,
      arrows: arrows,
      focusOnSelect: true,
      infinite: true,
      autoplay: userAutoplay,
      autoplaySpeed: autoplay_speed,
      swipe: true,
      swipeToSlide: true,
      draggable: true,
      accessibility: true,
      adaptiveHeight: false,

      responsive: [
        {
          breakpoint: 980,
          settings: {
            slidesToShow: 1,
          },
        },
      ],

      appendDots: (dots) => (
        <div>
          {n_items > slides_to_show && (
            <div className="play-pause-wrapper">
              <button
                onClick={() => toggleAutoplay()}
                title={
                  userAutoplay
                    ? intl.formatMessage(messages.pause)
                    : intl.formatMessage(messages.play)
                }
              >
                <Icon name={userAutoplay ? 'pause' : 'video play'} />
              </button>
            </div>
          )}

          <ul style={{ margin: '0px' }}> {dots} </ul>
        </div>
      ),
    };
  };

  return data?.slides && data?.slides?.length > 0 && data?.slides[0]?.image ? (
    <Container className="slider-wrapper">
      <div
        className={cx({
          'full-width': data?.fullwidth,
        })}
      >
        {/* FIRST SLIDER SHOWN */}
        <Slider
          asNavFor={nav2}
          ref={(slider) => (slider1.current = slider)}
          {...settingsSlider(data.slidesToScrollArrows)}
        >
          {data?.slides.map((slide, slideIndex) => {
            // IMAGE URL
            let slideImageURL =
              slide?.image?.[0]?.image?.scales?.huge?.download ??
              slide?.image?.[0]?.['@id']
                ? flattenToAppURL(
                    slide?.image?.[0]?.['@id'] + '/@@images/image',
                  )
                : null;

            return (
              slideImageURL && (
                <PresetWrapper
                  fullWidth={false}
                  className="single-slide-wrapper"
                  key={slideIndex + 'slide'}
                >
                  <div>
                    {slide?.href && slide?.href?.length > 0 ? (
                      <div
                        className={cx('image-wrapper', {
                          ['img-align-' + data?.img_align]: data?.img_align,
                        })}
                      >
                        <ConditionalLink
                          href={slide?.href[0]['@id']}
                          condition={!inEditMode}
                        >
                          <Image
                            className={cx('bg', {
                              'no-src': !slideImageURL && !inEditMode,
                            })}
                            src={slideImageURL}
                            role="presentation"
                            alt={slide?.alt}
                            title={slide?.alt}
                          />
                        </ConditionalLink>
                      </div>
                    ) : (
                      <div
                        className={cx('image-wrapper', {
                          ['img-align-' + data?.img_align]: data?.img_align,
                        })}
                      >
                        <Image
                          className={cx('bg', {
                            'no-src': !slideImageURL && !inEditMode,
                          })}
                          src={slideImageURL}
                          role="presentation"
                          alt={slide?.alt}
                          title={slide?.alt}
                        />
                      </div>
                    )}
                  </div>
                </PresetWrapper>
              )
            );
          })}
        </Slider>

        {/* SECOND SLIDER HIDDEN */}
        <Slider
          className="slider-hidden"
          asNavFor={nav1}
          ref={(slider) => (slider2.current = slider)}
          {...settingsSlider(data.slidesToScroll, true, false)}
        >
          {data?.slides.map((slide, slideIndex) => {
            return <></>;
          })}
        </Slider>
      </div>
    </Container>
  ) : inEditMode ? (
    <div
      className={cx('slide-placeholder', {
        'full-width': data?.fullwidth,
      })}
    ></div>
  ) : (
    <></>
  );
};

export default Body;
