import { DELETE_CVS, RESET_DELETE_CVS } from '@package/constants/ActionTypes';

export function deleteCVs(cvUIDs = []) {
  // const querystring = cvUIDs.map((UID) => `UIDS:list=${UID}`).join('&');

  return {
    type: DELETE_CVS,
    request: {
      op: 'del',
      // path: `/@delete_users${querystring ? `?${querystring}` : ''}`,
      path: '/@delete_users',
      data: {
        UIDS: cvUIDs,
      },
    },
  };
}

export function resetDeleteCVs() {
  return {
    type: RESET_DELETE_CVS,
  };
}
