import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getUser,
  logout,
  purgeMessages,
  getContent,
  resetContent,
} from '@plone/volto/actions';
import { useDetectClickOutside } from '@plone/volto/helpers';
import jwtDecode from 'jwt-decode';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Popup, Grid } from 'semantic-ui-react';
import { UniversalLink, Icon } from '@plone/volto/components';
import { useHistory } from 'react-router-dom';
import { resetUser } from '@package/actions';
import logoutSVG from '@plone/volto/icons/log-out.svg';
import userSVG from '@plone/volto/icons/user.svg';

const messages = defineMessages({
  logout: {
    id: 'Logout',
    defaultMessage: 'Logout',
  },
  profile: {
    id: 'Profile',
    defaultMessage: 'Profilo personale',
  },
});

const HeaderUserDropwdown = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );
  const userLogged = useSelector((state) => state.users.user);
  const userLoggedSt = useSelector((state) => state.users);
  const profile = useSelector((state) => state.content.subrequests.profile);
  const saveCVState = useSelector((state) => state.saveCV);
  const outsideClickRef = useDetectClickOutside({
    onTriggered: () => setIsOpen(false),
    triggerKeys: ['Escape'],
  });

  useEffect(() => {
    if (!userLoggedSt?.get?.loading && userLoggedSt?.get?.loaded && userId) {
      dispatch(getUser(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // logout the user and reset the state
  const doLogout = async () => {
    await dispatch(logout());
    await dispatch(purgeMessages());
    await dispatch(resetUser());
    await dispatch(resetContent('profile'));
    history.push('/');
  };

  // fetch user CV link and get avatar image
  useEffect(() => {
    if (userLogged?.home_page && !profile)
      dispatch(
        getContent(flattenToAppURL(userLogged.home_page), null, 'profile'),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogged]);

  useEffect(() => {
    return dispatch(resetContent('profile'));
  }, [dispatch]);

  useEffect(() => {
    if (
      saveCVState?.result &&
      saveCVState?.result?.image !== profile?.data?.image
    )
      dispatch(
        getContent(flattenToAppURL(userLogged.home_page), null, 'profile'),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveCVState?.result]);

  return userId ? (
    <div className="rw--user-dropdown">
      <Popup
        trigger={
          <button
            className="rw--user-profile-avatar"
            onClick={() => setIsOpen(!isOpen)}
            ref={outsideClickRef}
            tabIndex={-1}
            disabled={!userLogged?.home_page}
          >
            <FontAwesomeIcon icon={['fas', 'user-circle']} size="2x" />
          </button>
        }
        className="rw--user-menu-container"
        position="bottom right"
        on={['click', 'focus']}
        open={isOpen}
        pinned
        wide
        role="menu"
        hideOnScroll={true}
      >
        <Grid className="rw--user-menu" columns={1}>
          <Grid.Row
            as={UniversalLink}
            href={userLogged.home_page}
            className="user-profile"
            role="menuitem"
          >
            <Icon name={userSVG} size="24px" />
            <span>{props.intl.formatMessage(messages.profile)}</span>
          </Grid.Row>
          <Grid.Row
            as={'a'}
            className="logout"
            role="menuitem"
            onClick={doLogout}
          >
            <Icon name={logoutSVG} size="24px" />
            <span>{props.intl.formatMessage(messages.logout)}</span>
          </Grid.Row>
        </Grid>
      </Popup>
    </div>
  ) : null;
};

export default compose(injectIntl)(HeaderUserDropwdown);
