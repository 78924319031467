import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Label } from 'semantic-ui-react';
import cx from 'classnames';
import { Controller } from 'react-hook-form';

class FormFieldWrapper extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    required: PropTypes.bool,
    // error: PropTypes.arrayOf(PropTypes.string),
    wrapped: PropTypes.bool,
    className: PropTypes.string,
    width: PropTypes.number,
    control: PropTypes.any,
    popup: PropTypes.element,
  };

  static defaultProps = {
    title: '',
    description: null,
    required: false,
    // error: [],
    wrapped: true,
  };

  renderField(
    wrapped,
    required,
    error,
    description,
    className,
    id,
    title,
    fieldSet,
    popup,
    width,
  ) {
    const wdg = <>{this.props.children}</>;
    return wrapped ? (
      <Form.Field
        required={required}
        error={error}
        className={cx(
          popup ? 'field-with-popup' : '',
          description ? 'help' : '',
          className,
          `field-wrapper-${id}`,
          'rw--form-field',
        )}
        width={width}
      >
        {error && (
          <Label key={`${id}-error`} basic color="red" pointing="below">
            {error}
          </Label>
        )}
        <label
          id={`fieldset-${fieldSet}-field-label-${id}`}
          htmlFor={`field-${id}`}
        >
          {title}
        </label>
        {popup && popup}
        <div className="wrapper">{wdg}</div>
        {description && <p className="help">{description}</p>}
      </Form.Field>
    ) : (
      <>
        {error && (
          <Label key={`${id}-error`} basic color="red" pointing="below">
            {error}
          </Label>
        )}
        {wdg}
      </>
    );
  }

  render() {
    const {
      id,
      title,
      description,
      fieldSet,
      required,
      wrapped,
      className,
      width,
      control,
      popup,
    } = this.props;

    return (
      <Controller
        name={id}
        control={control}
        render={({ field, fieldState }) => {
          return this.renderField(
            wrapped,
            required,
            fieldState?.error?.message,
            description,
            className,
            id,
            title,
            fieldSet,
            popup,
            width,
          );
        }}
      />
    );
  }
}

export default FormFieldWrapper;
