/* eslint-disable react-hooks/exhaustive-deps */
/**
 * View icons block.
 * @module components/manage/Blocks/IconsBlocks/View
 */

import React from 'react';
import PropTypes from 'prop-types';
import Body from './Body';

/**
 * View icons blocks class.
 * @class View
 * @extends Component
 */
const View = ({ data }) => {
  return (
    <div className="block image-viewer">
      <Body data={data} />
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
