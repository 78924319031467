import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  euroland_title: {
    id: 'Euroland ticker',
    defaultMessage: 'Ticker di Euroland',
  },
});

const EurolandTicker = ({ url }) => {
  const intl = useIntl();
  return (
    <div className="euroland-ticker">
      <iframe
        title={intl.formatMessage(messages.euroland_title)}
        src={url}
        height="27"
        width="500"
      />
    </div>
  );
};

export default EurolandTicker;
