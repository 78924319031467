import React from 'react';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { TextWidget } from '@plone/volto/components';

const messages = defineMessages({
  related_items: {
    id: 'Contenuti correlati',
    defaultMessage: 'Contenuti correlati',
  },
  related_items_title: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  const { data, block, onChangeBlock } = props;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="related_items"
            defaultMessage="Contenuti correlati"
          />
          :
        </h2>
      </header>
      <Segment className="form sidebar-listing-data">
        <TextWidget
          id="title"
          title={intl.formatMessage(messages.related_items_title)}
          value={data.title}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

export default Sidebar;
