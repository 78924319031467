export const GET_MONTHS_TO_OBLIVION = 'GET_MONTHS_TO_OBLIVION';

export function getMonthsToOblivion() {
  return {
    type: GET_MONTHS_TO_OBLIVION,
    request: {
      op: 'get',
      path:
        '@months_to_oblivion/raiway.lavoraconnoi.interfaces.settings.IRaiwayLavoraconnoiSettings.months_to_oblivion',
    },
  };
}
