import { defineMessages } from 'react-intl';
import { addSchemaField } from '@package/config/Blocks/schemas/utils';
import { addPresetFields } from '@package/helpers/presets';

const messages = defineMessages({
  title: {
    id: 'Titolo',
    defaultMessage: 'Titolo',
  },
  title_decoration: {
    id: 'title_decoration',
    defaultMessage: 'Mostra linea di separazione del titolo',
  },
  show_item_more_button: {
    id: 'show_item_more_button',
    defaultMessage: 'Mostra il bottone per il dettaglio della card',
  },
  have_image: {
    id: 'have_image',
    defaultMessage: "Mostra l'immagine",
  },
  cols: {
    id: 'Numero di colonne',
    defaultMessage: 'Numero di colonne',
  },
});

const getNewsCardVariationSchema = (schema, formData, intl, pos = 0) => {
  addSchemaField(
    schema,
    'show_title_decoration',
    intl.formatMessage(messages.title_decoration),
    null,
    { type: 'boolean', default: true },
    pos,
    'default',
  );
  pos++;

  addSchemaField(
    schema,
    'cols',
    intl.formatMessage(messages.cols),
    null,
    { type: 'number', default: 3 },
    pos,
    'default',
  );
  pos++;

  addSchemaField(
    schema,
    'have_image',
    intl.formatMessage(messages.have_image),
    null,
    { type: 'boolean', default: true },
    pos,
    'items',
  );
  pos++;

  pos = addPresetFields(schema, intl, pos, 'items', {
    title_color: { colors: ['blue', 'grey'] },
  });

  addSchemaField(
    schema,
    'show_item_more_button',
    intl.formatMessage(messages.show_item_more_button),
    null,
    { type: 'boolean', default: false },
    pos,
    'items',
  );
  pos++;

  return pos;
};

export default getNewsCardVariationSchema;
