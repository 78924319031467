/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { ColorListWidget } from '@package/components/Widgets';

import {
  CheckboxWidget,
  ObjectBrowserWidget,
  TextWidget,
  SelectWidget,
} from '@plone/volto/components';

const messages = defineMessages({
  content_link: {
    id: 'content_link',
    defaultMessage: 'Contenuto in evidenza',
  },
  content_description: {
    id: 'content_description',
    defaultMessage:
      'Inserisci un collegamento per il blocco. Puoi selezionare un contenuto dal menu laterale.',
  },
  titleColor: {
    id: 'titleColor',
    defaultMessage: 'Colore del titolo',
  },
  bgColor: {
    id: 'bgColor',
    defaultMessage: 'Colore di sfondo',
  },
  bgFullWidth: {
    id: 'bgFullWidth',
    defaultMessage: 'Imposta lo sfondo a larghezza intera',
  },
  has_cta: {
    id: 'has_cta',
    defaultMessage: 'Mostra la CTA',
  },
  has_cta_description: {
    id: 'has_cta_description',
    defaultMessage:
      'Scegli se mostrare o meno la CTA nel blocco, assente di default.',
  },
  cta_title: {
    id: 'cta_title',
    defaultMessage: 'Testo della CTA',
  },
  cta_title_default: {
    id: 'cta_title_default',
    defaultMessage: 'Vai al contenuto',
  },
  cta_title_description: {
    id: 'cta_title_description',
    defaultMessage: 'Testo da mostrare per la CTA del blocco.',
  },
  link_to: {
    id: 'link_to',
    defaultMessage: 'Link CTA',
  },
  link_to_description: {
    id: 'link_to_description',
    defaultMessage:
      'Inserisci un collegamento per la CTA del blocco. Puoi digitare un URL esterno o selezionare un contenuto.',
  },
  destra: {
    id: 'destra',
    defaultMessage: 'Immagine a destra',
  },
  destra_description: {
    id: 'destra_description',
    defaultMessage:
      "Allinea l'immagine a destra, di default questa viene allineata a sinistra.",
  },
  img_column_width: {
    id: 'img_column_width',
    defaultMessage: "Larghezza dell'immagine",
  },
});

const Sidebar = (props) => {
  const intl = useIntl();

  const {
    content_in_evidence,
    has_cta,
    cta_title,
    destra,
    link_to,
    img_column_width,
    onChange,
    title_color,
    bg_color,
    fullWidth,
    backgroundColorOptions = [
      { name: 'white', label: 'Bianco' },
      { name: 'light-grey', label: 'Grigio' },
    ],
    titleColorOptions = [
      { name: 'grey', label: 'Grigio' },
      { name: 'blue', label: 'Blu' },
    ],
  } = props;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="highlighted_content"
            defaultMessage="Contenuto in evidenza"
          />
        </h2>
      </header>

      <Segment className="form sidebar-listing-data">
        <ObjectBrowserWidget
          id="content_in_evidence"
          title={intl.formatMessage(messages.content_link)}
          description={intl.formatMessage(messages.content_description)}
          mode="link"
          required={has_cta}
          value={content_in_evidence}
          onChange={onChange}
          allowExternals={true}
        />
        <CheckboxWidget
          id="destra"
          title={intl.formatMessage(messages.destra)}
          value={destra}
          onChange={onChange}
        />
        <SelectWidget
          id="img_column_width"
          title={intl.formatMessage(messages.img_column_width)}
          choices={[
            ['1', '8%'],
            ['2', '16%'],
            ['3', '25%'],
            ['4', '33%'],
            ['5', '41%'],
            ['6', '50%'],
            ['7', '58%'],
            ['8', '66%'],
            ['9', '75%'],
            ['10', '83%'],
            ['11', '91%'],
          ]}
          value={img_column_width ?? 6}
          onChange={onChange}
        />
      </Segment>

      <Segment className="form sidebar-listing-data">
        <ColorListWidget
          id="bg_color"
          title={intl.formatMessage(messages.bgColor)}
          colors={backgroundColorOptions}
          value={bg_color ?? backgroundColorOptions[0].name}
          onChange={onChange}
        />
        <CheckboxWidget
          id="fullWidth"
          title={intl.formatMessage(messages.bgFullWidth)}
          value={fullWidth ?? false}
          onChange={onChange}
        />
      </Segment>
      {titleColorOptions?.length > 0 && (
        <Segment className="form sidebar-listing-data">
          <ColorListWidget
            id="title_color"
            title={intl.formatMessage(messages.titleColor)}
            colors={titleColorOptions}
            value={title_color ?? titleColorOptions[0].name}
            onChange={onChange}
          />
        </Segment>
      )}

      <Segment className="form sidebar-listing-data">
        <CheckboxWidget
          id="has_cta"
          title={intl.formatMessage(messages.has_cta)}
          value={has_cta}
          onChange={onChange}
        />
        {has_cta && (
          <>
            <TextWidget
              id="cta_title"
              title={intl.formatMessage(messages.cta_title)}
              description={intl.formatMessage(messages.cta_title_description)}
              value={cta_title ?? ''}
              onChange={onChange}
              placeholder={intl.formatMessage(messages.cta_title_default)}
            />
            <ObjectBrowserWidget
              id="link_to"
              title={intl.formatMessage(messages.link_to)}
              description={intl.formatMessage(messages.link_to_description)}
              mode="link"
              required={has_cta}
              value={link_to}
              onChange={onChange}
              allowExternals={true}
            />
          </>
        )}
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  block: PropTypes.string.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

export default Sidebar;
