/* eslint-disable prettier/prettier */
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { isEmpty } from 'lodash';
import * as yup from 'yup';
// import CodiceFiscale from 'codice-fiscale-js';
// import moment from 'moment';

const messages = defineMessages({
  tipologia_jp: {
    id: 'tipologia_jp',
    defaultMessage: 'Tipologia Job Posting',
  },
  spontaneous_application: {
    id: 'spontaneous_application',
    defaultMessage: 'Candidature spontanee',
  },
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
  apply: {
    id: 'apply',
    defaultMessage: 'Apply',
  },
  scolastico: {
    id: 'scolastico',
    defaultMessage: 'Basic knowledge',
  },
  medio: {
    id: 'medio',
    defaultMessage: 'Medium knowledge',
  },
  madrelingua: {
    id: 'madrelingua',
    defaultMessage: 'Native speaker',
  },
  advanced: {
    id: 'advanced',
    defaultMessage: 'Advanced',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'Loading...',
  },
  cvOnline: {
    id: 'cvOnline',
    defaultMessage: 'Your online CV',
  },
  add: {
    id: 'add',
    defaultMessage: 'Add {context}',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'Cancel',
  },
  remove: {
    id: 'remove',
    defaultMessage: 'Remove',
  },
  deleteConfirm: {
    id: 'deleteConfirm',
    defaultMessage: 'Are you sure you want to delete your CV?',
  },
  deleteCV: {
    id: 'deleteCV',
    defaultMessage: 'Delete CV',
  },
  success: {
    id: 'Success',
    defaultMessage: 'Success',
  },
  error: {
    id: 'Error',
    defaultMessage: 'Error',
  },
  saveError: {
    id: 'saveError',
    defaultMessage: 'Error saving data',
  },
  saveSuccess: {
    id: 'Successfully saved data',
    defaultMessage: 'Successfully saved data',
  },
  validationError: {
    id: 'Validation error',
    defaultMessage:
      'Some fields are not valid. Please check the highlighted fields and try again.',
  },
  required: {
    id: 'Required',
    defaultMessage: 'Required',
  },
  fullnameRequired: {
    id: 'Fullname required',
    defaultMessage: 'Fullname required',
  },
  invalidEmail: {
    id: 'Invalid email',
    defaultMessage: 'Invalid email',
  },
  invalidPhone: {
    id: 'Invalid phone',
    defaultMessage: 'Invalid phone number',
  },
  delete: {
    id: 'Delete',
    defaultMessage: 'Delete',
  },
  save: {
    id: 'Save and apply',
    defaultMessage: 'Save and apply',
  },
  saveDraft: {
    id: 'Save as draft',
    defaultMessage: 'Save as draft',
  },
  prevStep: {
    id: 'Previous step',
    defaultMessage: 'Previous step',
  },
  nextStep: {
    id: 'Next step',
    defaultMessage: 'Next step',
  },
  personalInfos: {
    id: 'Personal infos',
    defaultMessage: 'Personal Information',
  },
  fullName: {
    id: 'Fullname',
    defaultMessage: 'Fullname',
  },
  birthDate: {
    id: 'Birth date',
    defaultMessage: 'Birth date',
  },
  gender: {
    id: 'Select your gender from the radio input below',
    defaultMessage: 'Select your gender from the radio input below',
  },
  gender_bo: {
    id: 'gender_bo',
    defaultMessage: 'Gender',
  },
  birthProvince: {
    id: 'Birth Province',
    defaultMessage: 'Birth Province',
  },
  birthPlace: {
    id: 'Birth Place',
    defaultMessage: 'Birth Place',
  },
  itTaxCode: {
    id: 'IT Tax Code',
    defaultMessage: 'Check if you have italian tax code',
  },
  yes: {
    id: 'Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'No',
    defaultMessage: 'No',
  },
  confirm: {
    id: 'Confirm',
    defaultMessage: 'Confirm',
  },
  taxCode: {
    id: 'tax_code',
    defaultMessage: 'Tax Code',
  },
  nationality: {
    id: 'Nationality',
    defaultMessage: 'Nationality',
  },
  IT: {
    id: 'IT',
    defaultMessage: 'Italian',
  },
  EU: {
    id: 'EU',
    defaultMessage: 'European',
  },
  OTHER: {
    id: 'OTHER',
    defaultMessage: 'Other',
  },
  maritalState: {
    id: 'Marital State',
    defaultMessage: 'Marital State',
  },
  single: {
    id: 'Single',
    defaultMessage: 'Single',
  },
  married: {
    id: 'Married',
    defaultMessage: 'Married',
  },
  other: {
    id: 'other',
    defaultMessage: 'Other',
  },
  drivingLicence: {
    id: 'Driving Licence',
    defaultMessage: 'Driving Licence',
  },
  residency: {
    id: 'Residency',
    defaultMessage: 'Residency',
  },
  residencyAddress: {
    id: 'Residency Address',
    defaultMessage: 'Residency Address',
  },
  residencyMunicipality: {
    id: 'Residency Municipality',
    defaultMessage: 'Residency Municipality',
  },
  residencyProvince: {
    id: 'Residency Province',
    defaultMessage: 'Residency Province',
  },
  residencyNation: {
    id: 'Residency Nation',
    defaultMessage: 'Residency Nation',
  },
  residencyCAP: {
    id: 'Residency CAP',
    defaultMessage: 'Residency CAP',
  },
  domicile: {
    id: 'Domicile',
    defaultMessage: 'Domicile',
  },
  domNotRes: {
    id: 'Domicile not residency',
    defaultMessage: 'Does your domicile differ from your residency?',
  },
  domicileAddress: {
    id: 'Domicile Address',
    defaultMessage: 'Domicile Address',
  },
  domicileMunicipality: {
    id: 'Domicile Municipality',
    defaultMessage: 'Domicile Municipality',
  },
  domicileProvince: {
    id: 'Domicile Province',
    defaultMessage: 'Domicile Province',
  },
  domicileNation: {
    id: 'Domicile Nation',
    defaultMessage: 'Domicile Nation',
  },
  domicileCAP: {
    id: 'Domicile CAP',
    defaultMessage: 'Domicile CAP',
  },
  contactInfo: {
    id: 'Contact Info',
    defaultMessage: 'Contact Info',
  },
  phone: {
    id: 'Phone',
    defaultMessage: 'Phone',
  },
  phonePopup: {
    id: 'Phone Popup',
    defaultMessage: 'It is recommended to provide a phone number',
  },
  professionalRegistryPopup: {
    id: 'Professional Registry Popup',
    defaultMessage: 'Specify the professional registry',
  },
  preavvisoPopup: {
    id: 'Preavviso Popup',
    defaultMessage: 'State the needed days of notice',
  },
  mobile: {
    id: 'mobile',
    defaultMessage: 'Mobile',
  },
  email: {
    id: 'Email',
    defaultMessage: 'Email',
  },
  highSchoolDegreeTitle: {
    id: 'highSchoolDegreeTitle',
    defaultMessage: 'Diploma di maturità',
  },
  highSchoolDegreeType: {
    id: 'highSchoolDegreeType',
    defaultMessage: 'Tipo di diploma di maturità',
  },
  highSchoolDegreeOther: {
    id: 'highSchoolDegreeOther',
    defaultMessage: 'Specificare (Altro)',
  },
  highSchoolName: {
    id: 'highSchoolName',
    defaultMessage: 'Nome scuola',
  },
  universityTitle: {
    id: 'universityTitle',
    defaultMessage: 'Laurea/Diploma Universitario',
  },
  universitySubtitlePrimary: {
    id: 'universitySubtitlePrimary',
    defaultMessage: 'Titolo di studio principale',
  },
  universitySubtitleOther: {
    id: 'universitySubtitleOther',
    defaultMessage: 'Altro titolo di studio',
  },
  universityDegreeType: {
    id: 'universityDegreeType',
    defaultMessage: 'Tipo di Laurea',
  },
  universityDegreeClass: {
    id: 'universityDegreeClass',
    defaultMessage: 'Classe di Laurea',
  },
  universityDegreeOther: {
    id: 'universityDegreeOther',
    defaultMessage: 'Specificare (Altro)',
  },
  universityName: {
    id: 'universityName',
    defaultMessage: 'Nome Ateneo',
  },
  otherStudiesTitle: {
    id: 'otherStudiesTitle',
    defaultMessage: 'Altri Titoli',
  },
  otherStudiesSubtitle: {
    id: 'otherStudiesSubtitle',
    defaultMessage: 'Titolo aggiuntivo {index}',
  },
  otherStudiesDegreeTitle: {
    id: 'otherStudiesDegreeTitle',
    defaultMessage: 'Qualifica',
  },
  otherStudiesName: {
    id: 'otherStudiesName',
    defaultMessage: 'Nome Scuola/Ateneo/Ente',
  },
  degree: {
    id: 'degree',
    defaultMessage: 'Degree',
  },
  degreeOther: {
    id: 'degreeOther',
    defaultMessage: 'Other degree',
  },
  degreeClass: {
    id: 'degreeClass',
    defaultMessage: 'Degree class',
  },
  fieldOfStudy: {
    id: 'fieldOfStudy',
    defaultMessage: 'Field of study',
  },
  equipollenza: {
    id: 'equipollenza',
    defaultMessage: 'Equivalence of foreign qualification',
  },
  equipollenzaPopup: {
    id: 'equipollenzaPopup',
    defaultMessage:
      'If you have a foreign qualification or degree, you must check this field to indicate that you have the equivalent of your qualification or degree in Italy.',
  },
  ongoingStudies: {
    id: 'ongoingStudies',
    defaultMessage: 'Ongoing studies',
  },
  startDate: {
    id: 'startDate',
    defaultMessage: 'Start date',
  },
  endDateShort: {
    id: 'endDateShort',
    defaultMessage: 'End date',
  },
  endDate: {
    id: 'endDate',
    defaultMessage: 'End date (effective or presumed)',
  },
  endDateWE: {
    id: 'endDateWE',
    defaultMessage: 'End date',
  },
  vote: {
    id: 'vote',
    defaultMessage: 'Vote',
  },
  votingSystem: {
    id: 'votingSystem',
    defaultMessage: 'Voting system',
  },
  laudem: {
    id: 'laudem',
    defaultMessage: 'Laudem',
  },
  professionalRegistry: {
    id: 'professionalRegistry',
    defaultMessage: 'Professional registry',
  },
  professionalAbilitationExam: {
    id: 'professionalAbilitationExam',
    defaultMessage: 'Has the professional abilitation exam been passed?',
  },
  specificProfessionalRegistry: {
    id: 'specificProfessionalRegistry',
    defaultMessage: 'Professional registry',
  },
  registrationDate: {
    id: 'registrationDate',
    defaultMessage: 'Registration date',
  },
  computerSkills: {
    id: 'computerSkills',
    defaultMessage: 'Computer skills',
  },
  officeAutomation: {
    id: 'officeAutomation',
    defaultMessage: 'Office automation',
  },
  level: {
    id: 'level',
    defaultMessage: 'Knowledge level',
  },
  osdbms: {
    id: 'osdbms',
    defaultMessage: 'Operating Systems and DBMSes',
  },
  programmingLanguages: {
    id: 'programmingLanguages',
    defaultMessage: 'Programming Languages',
  },
  graphicDesign: {
    id: 'graphicDesign',
    defaultMessage: 'Graphic Design and Multimedia Apps',
  },
  otherComputerSkills: {
    id: 'otherComputerSkills',
    defaultMessage: 'Details',
  },
  languages: {
    id: 'languages',
    defaultMessage: 'Languages',
  },
  language: {
    id: 'language',
    defaultMessage: 'Language',
  },
  languageOther: {
    id: 'languageOther',
    defaultMessage: 'Other language',
  },
  haveCertificate: {
    id: 'haveCertificate',
    defaultMessage: 'Do you have a certificate?',
  },
  certificate: {
    id: 'certificate',
    defaultMessage: 'Certificate issued by',
  },
  certificatePopup: {
    id: 'certificatePopup',
    defaultMessage:
      'If you have a certificate, you must add the certifying body. Only some certifying bodies are accredited by MIUR: a comprehensive list of MIUR-Accredited certifying bodies can be found at {miurLink}.',
  },
  here: {
    id: 'here',
    defaultMessage: 'here',
  },
  certificateDate: {
    id: 'certificateDate',
    defaultMessage: 'Certificate achievement date',
  },
  businessAreasOfInterest: {
    id: 'businessAreasOfInterest',
    defaultMessage: 'Business Areas of Interest',
  },
  businessAreasOfInterestHelp: {
    id: 'businessAreasOfInterestHelp',
    defaultMessage: 'Select your Business Areas of Interest (max. 3)',
  },
  professionalExperiences: {
    id: 'professionalExperiences',
    defaultMessage: 'Professional Experiences',
  },
  professionalExperiencesMain: {
    id: 'professionalExperiencesMain',
    defaultMessage: 'Main Professional Experience',
  },
  professionalExperiencesOther: {
    id: 'professionalExperiencesOther',
    defaultMessage: 'Other Professional Experience',
  },
  professionalExperiencesHelp: {
    id: 'professionalExperiencesHelp',
    defaultMessage:
      'È possibile cliccare su "Aggiungi" per poter compilare più sezioni riferite alle esperienze professionali svolte',
  },
  settore: {
    id: 'settore',
    defaultMessage: 'Settore',
  },
  area: {
    id: 'area',
    defaultMessage: 'Area',
  },
  attivita: {
    id: 'attivita',
    defaultMessage: 'Attività',
  },
  ruolo: {
    id: 'ruolo',
    defaultMessage: 'Ruolo',
  },
  contractType: {
    id: 'contractType',
    defaultMessage: 'Contract Type',
  },
  contractTypeOther: {
    id: 'contractTypeOther',
    defaultMessage: 'Other Contract Type',
  },
  company: {
    id: 'company',
    defaultMessage: 'Company',
  },
  companyLocation: {
    id: 'companyLocation',
    defaultMessage: 'Company Location',
  },
  ongoingContract: {
    id: 'ongoingContract',
    defaultMessage: 'Ongoing Contract',
  },
  daysNotice: {
    id: 'daysNotice',
    defaultMessage: 'Days notice (if applicable)',
  },
  additionalInfo: {
    id: 'additionalInfo',
    defaultMessage: 'Additional Informations',
  },
  howDidYouHearAboutUs: {
    id: 'howDidYouHearAboutUs',
    defaultMessage: 'How did you hear about us?',
  },
  relativesInRai: {
    id: 'relativesInRai',
    defaultMessage: 'Do you have any relatives in RAI?',
  },
  preferredWorkplace: {
    id: 'preferredWorkplace',
    defaultMessage: 'Preferred Workplace Area',
  },
  willingToRelocate: {
    id: 'willingToRelocate',
    defaultMessage: 'Are you willing to relocate?',
  },
  protectedCategory: {
    id: 'protectedCategory',
    defaultMessage: 'Do you belong to a protected category (>=46%)?',
  },
  photo: {
    id: 'photo',
    defaultMessage: 'Photo',
  },
  socialProfiles: {
    id: 'socialProfiles',
    defaultMessage: 'Social Profiles',
  },
  social: {
    id: 'social',
    defaultMessage: 'Social Network',
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Profile link',
  },
  additionalAttachments: {
    id: 'additionalAttachments',
    defaultMessage: 'Altri documenti da allegare',
  },
  additionalAttachmentsLabel: {
    id: 'additionalAttachmentsLabel',
    defaultMessage: 'File allegati',
  },
  jobPostingId: {
    id: 'jobPostingId',
    defaultMessage: 'Job Posting',
  },
  customFieldsTitle: {
    id: 'customFieldsTitle',
    defaultMessage: 'Ulteriori informazioni',
  },
  cvLoading: {
    id: 'cvLoading',
    defaultMessage: 'Salvataggio del curriculum in corso, attendere prego.',
  },
});

const validationSchema = (intl) =>
  yup.object({
    fullname: yup
      .string()
      .trim()
      .required(intl.formatMessage(messages.fullnameRequired)),
    gender: yup.string().required(intl.formatMessage(messages.required)),
    birth_province: yup
      .string()
      .required(intl.formatMessage(messages.required)),
    birth_place: yup.string().when('birth_province', {
      is: (val) => val !== FOREIGN_PROVINCE,
      then: (schema) => schema.required(intl.formatMessage(messages.required)),
      otherwise: (schema) => schema,
    }),
    nationality: yup
      .object()
      .shape({
        title: yup.string(),
        token: yup.string(),
      })
      .required(intl.formatMessage(messages.required)),
    marital_state: yup
      .object()
      .shape({
        title: yup.string(),
        token: yup.string(),
      })
      .required(intl.formatMessage(messages.required)),
    driving_license: yup
      .array()
      .of(
        yup.object().shape({
          title: yup.string(),
          token: yup.string(),
        }),
      )
      .min(1, intl.formatMessage(messages.required)),
    residency_address: yup
      .string()
      .trim()
      .required(intl.formatMessage(messages.required)),
    residency_province: yup
      .string()
      .required(intl.formatMessage(messages.required)),
    residency_postal_code: yup
      .string()
      .trim()
      .required(intl.formatMessage(messages.required)),
    residency_municipality: yup.string().when('residency_province', {
      is: (val) => val !== FOREIGN_PROVINCE,
      then: (schema) => schema.required(intl.formatMessage(messages.required)),
      otherwise: (schema) => schema,
    }),
    domicile_address: yup
      .string()
      .trim()
      .when('domicile', {
        is: (val) => val?.token === 'True',
        then: (schema) =>
          schema.required(intl.formatMessage(messages.required)),
        otherwise: (schema) => schema.nullable(),
      }),
    domicile_province: yup.string().when('domicile', {
      is: (val) => val?.token === 'True',
      then: (schema) => schema.required(intl.formatMessage(messages.required)),
      otherwise: (schema) => schema.nullable(),
    }),
    domicile_postal_code: yup
      .string()
      .trim()
      .when('domicile', {
        is: (val) => val?.token === 'True',
        then: (schema) =>
          schema.required(intl.formatMessage(messages.required)),
        otherwise: (schema) => schema.nullable(),
      }),
    domicile_municipality: yup
      .string()
      .when(['domicile', 'domicile_province'], {
        is: (domicile, domicile_province) =>
          domicile?.token === 'True' && domicile_province !== FOREIGN_PROVINCE,
        then: (schema) =>
          schema.required(intl.formatMessage(messages.required)),
        otherwise: (schema) => schema.nullable(),
      }),
    email: yup
      .string()
      .trim()
      .email(intl.formatMessage(messages.invalidEmail))
      .required(intl.formatMessage(messages.required)),
    italian_taxcode: yup
      .object()
      .shape({
        title: yup.string(),
        token: yup.string(),
      })
      .required(intl.formatMessage(messages.required)),
    tax_code: yup
      .string()
      .trim()
      .when('italian_taxcode', {
        is: (val) => val.token === 'True',
        then: (schema) =>
          schema.required(intl.formatMessage(messages.required)),
        otherwise: (schema) => schema,
      }),
    // .test('invalidCF', 'Invalid CF', (value, context) => {
    //   const { parent } = context;
    //   if (parent.italian_tax_code.token === 'False') return true;
    //   try {
    //     // We need something that also accounts for omocodies
    //     // build a CF code from data and try it against omocodies
    //     const CF = new CodiceFiscale(value);
    //     // sadly, we can compute CF only if we have a birth place
    //     if (parent.birth_place.token) {
    //       const computedCF = new CodiceFiscale({
    //         name: parent.fullname.split(' ')[0],
    //         surname: parent.fullname.split(' ')[1],
    //         gender: parent.gender.token,
    //         birthplace: parent.birth_place.token,
    //         day: moment(parent.date_of_birth).date(),
    //         month: moment(parent.date_of_birth).get('month') + 1,
    //         year: moment(parent.date_of_birth).get('year'),
    //       });
    //       if (CF.isValid() && computedCF.isValid()) {
    //         if (CF.code !== computedCF.code) {
    //           // check for omocodies, user input might be right
    //           const omocodie = computedCF.omocodie();
    //           return omocodie.includes(CF.code);
    //         } else return true;
    //       }
    //     }
    //     //  we can only check if the user input is valid, accounting for omocodies
    //     else
    //       return CF.isValid() && [...CF.omocodie(), CF.code].includes(value);
    //   } catch (e) {
    //     return false;
    //   }
    // }),
    mobile: yup
      .string()
      .trim()
      .required(intl.formatMessage(messages.required))
      .matches(
        /^[0-9+()#.\s/ext-]+$/,
        intl.formatMessage(messages.invalidPhone),
      ),
    date_of_birth: yup
      .string()
      .trim()
      .required(intl.formatMessage(messages.required)),
    high_school: yup.object().shape({
      degree_type: yup.string().required(intl.formatMessage(messages.required)),
      degree_other: yup
        .string()
        .trim()
        .when('degree_type', {
          is: (val) => val === OTHER_DEGREE_TOKEN,
          then: (schema) =>
            schema.required(intl.formatMessage(messages.required)),
          otherwise: (schema) => schema,
        }),
      start_date: yup.string().required(intl.formatMessage(messages.required)),
      end_date: yup.string().required(intl.formatMessage(messages.required)),
      vote: yup.string().trim().required(intl.formatMessage(messages.required)),
      voting_system: yup
        .string()
        .required(intl.formatMessage(messages.required)),
    }),
    university: yup.array().of(
      yup.object().shape({
        degree_type: yup
          .string()
          .required(intl.formatMessage(messages.required)),
        degree_class: yup
          .string()
          .required(intl.formatMessage(messages.required)),
        degree_other: yup
          .string()
          .trim()
          .when('degree_class', {
            is: (val) => !!val && val.indexOf(OTHER_DEGREE_MAGIC_WORD) > -1,
            then: (schema) =>
              schema.required(intl.formatMessage(messages.required)),
            otherwise: (schema) => schema,
          }),
        start_date: yup
          .string()
          .required(intl.formatMessage(messages.required)),
        end_date: yup.string().required(intl.formatMessage(messages.required)),
        vote: yup
          .string()
          .trim()
          .when('ongoing', {
            is: true,
            then: (schema) => schema.nullable(),
            otherwise: (schema) =>
              schema.required(intl.formatMessage(messages.required)),
          }),
        voting_system: yup.string().when('ongoing', {
          is: true,
          then: (schema) => schema.nullable(),
          otherwise: (schema) =>
            schema.required(intl.formatMessage(messages.required)),
        }),
      }),
    ),
    other_studies: yup.array().of(
      yup.object().shape({
        title: yup
          .string()
          .trim()
          .required(intl.formatMessage(messages.required)),
        start_date: yup
          .string()
          .required(intl.formatMessage(messages.required)),
        end_date: yup.string().required(intl.formatMessage(messages.required)),
      }),
    ),
    professional_experience: yup.array().of(
      yup.object().shape({
        settore: yup.string().required(intl.formatMessage(messages.required)),
        area: yup.string().required(intl.formatMessage(messages.required)),
        attivita: yup.string().required(intl.formatMessage(messages.required)),
        ruolo: yup.string().required(intl.formatMessage(messages.required)),
        tipologia_contrattuale: yup
          .string()
          .required(intl.formatMessage(messages.required)),
        tipologia_contrattuale_altro: yup
          .string()
          .trim()
          .when('tipologia_contrattuale', {
            is: (val) => val === OTHER_DEGREE_TOKEN,
            then: (schema) =>
              schema.required(intl.formatMessage(messages.required)),
            otherwise: (schema) => schema,
          }),
        data_inizio: yup
          .string()
          .required(intl.formatMessage(messages.required)),
        data_fine: yup.string().when('ongoing_experience', {
          is: (val) => val,
          then: (schema) => schema,
          otherwise: (schema) =>
            schema.required(intl.formatMessage(messages.required)),
        }),
        notice_to_end_experience: yup
          .string()
          .trim()
          .when('ongoing_experience', {
            is: (val) => val,
            then: (schema) =>
              schema.required(intl.formatMessage(messages.required)),
            otherwise: (schema) => schema,
          }),
      }),
    ),
    // image: yup.object().required(intl.formatMessage(messages.required)),
    personal_cv: yup.object().required(intl.formatMessage(messages.required)),
  });

const prepareCVData = (formFields, touchedFields) => {
  const data = Object.keys(formFields).reduce((acc, key) => {
    if (touchedFields.hasOwnProperty(key)) {
      switch (key) {
        case 'high_school':
        case 'custom_fields':
          acc[key] = formFields[key] ? JSON.stringify(formFields[key]) : null;
          break;
        case 'university':
        case 'other_studies':
        case 'languages':
        case 'professional_experience':
        case 'social_profiles':
          acc[key] =
            formFields[key] && !isEmpty(formFields[key])
              ? JSON.stringify(formFields[key])
              : null;
          break;
        case 'birth_place':
          acc[key] = !!formFields[key] ? formFields[key] : null;
          break;
        default:
          acc[key] = formFields[key] ?? null;
          break;
      }
    }
    if (
      (key === 'searchable_cv' || key === 'spontaneous_application') &&
      acc[key] === null
    )
      acc[key] = { title: 'No', token: 'False' };
    return acc;
  }, {});
  return data;
};

const initializeFormData = (content) => {
  const effectiveFormData = Object.keys(content).reduce(
    (accumulator, current) => {
      switch (current) {
        case '@id':
        case '@type':
        case '@components':
        case 'parent':
        case 'UID':
        case 'layout':
        case 'review_state':
        case 'rights':
        case 'version':
        case 'working_copy':
        case 'working_copy_of':
        case 'next_item':
        case 'previous_item':
        case 'lock':
        case 'exclude_from_nav':
        case 'effective':
        case 'expires':
        case 'contributors':
        case 'created':
        case 'creators':
        case 'allow_discussion':
        case 'subjects':
        case 'is_folderish':
        case 'items':
        case 'items_total':
        case 'language':
        case 'modified':
          break;
        default:
          accumulator[current] = content[current];
          break;
      }
      return accumulator;
    },
    {},
  );
  return {
    ...effectiveFormData,

    // gender: migration from radio button to select
    gender: content?.gender?.token || '',

    birth_province: content?.birth_province?.token || '',
    birth_place: content?.birth_place?.token || '',
    residency_province: content?.residency_province?.token || '',
    residency_municipality: content?.residency_municipality?.token || '',
    domicile_province: content?.domicile_province?.token || '',
    domicile_municipality: content?.domicile_municipality?.token || '',

    domicile: content?.domicile || {
      title: 'No',
      token: 'False',
    },
    italian_taxcode: content?.italian_taxcode || {
      title: 'No',
      token: 'False',
    },
    tax_code: content?.tax_code || '',
    searchable_cv: content?.searchable_cv || { title: 'No', token: 'False' },
    high_school: JSON.parse(content?.high_school ?? null),
    university: JSON.parse(content?.university ?? null) ?? [],
    other_studies: JSON.parse(content?.other_studies ?? null) ?? [],
    languages: JSON.parse(content?.languages ?? null),
    professional_experience:
      JSON.parse(content?.professional_experience ?? null) ?? [],
    social_profiles: JSON.parse(content?.social_profiles ?? null) ?? [],
    custom_fields: JSON.parse(content?.custom_fields ?? null),
    // preferred_workplace: content.preferred_workplace ??
  };
};

const resetFormData = (formData) => {
  return Object.keys(formData).reduce((accumulator, current) => {
    switch (current) {
      case 'email':
      case 'fullname':
        accumulator[current] = formData[current];
        break;
      case 'driving_license':
      case 'preferred_workplace':
        accumulator[current] = [];
        break;
      default:
        accumulator[current] = null;
        break;
    }

    return accumulator;
  }, {});
};

const useYupValidationResolver = (validationSchema, intl) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });
        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors?.inner?.reduce((allErrors, currentError) => {
            return {
              ...allErrors,
              [currentError?.path]: {
                type: currentError?.type ?? 'validation',
                message: !currentError?.message?.includes('`null`')
                  ? currentError?.message
                  : intl.formatMessage(messages.required),
              },
            };
          }, {}),
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationSchema],
  );

const scrollToStepTop = (element) => {
  element && element.scrollIntoView({ behavior: 'smooth' });
};

const getLongFieldLabel = (fieldName, intl) => {
  let fieldCategory = '';
  let specificFieldName = fieldName;

  const match = fieldName.match(/(\w+)(\[[0-9]+\])?\.(\w+)/);
  if (match) {
    fieldCategory = match[1];
    specificFieldName = match[3];
  }

  if (fieldCategory === 'high_school') {
    let cat = `${intl.formatMessage(messages.highSchoolDegreeTitle)}: `;

    switch (specificFieldName) {
      case 'degree_type':
        return `${cat}${intl.formatMessage(messages.highSchoolDegreeType)}`;
      case 'degree_other':
        return `${cat}${intl.formatMessage(messages.highSchoolDegreeOther)}`;
      case 'start_date':
        return `${cat}${intl.formatMessage(messages.startDate)}`;
      case 'end_date':
        return `${cat}${intl.formatMessage(messages.endDateShort)}`;
      case 'vote':
        return `${cat}${intl.formatMessage(messages.vote)}`;
      case 'voting_system':
        return `${cat}${intl.formatMessage(messages.votingSystem)}`;
      case 'laudem':
        return `${cat}${intl.formatMessage(messages.laudem)}`;
      default:
        return `${cat}${specificFieldName}`;
    }
  } else if (fieldCategory === 'university') {
    let cat = `${intl.formatMessage(messages.universityTitle)}: `;

    switch (specificFieldName) {
      case 'degree_type':
        return `${cat}${intl.formatMessage(messages.universityDegreeType)}`;
      case 'degree_class':
        return `${cat}${intl.formatMessage(messages.universityDegreeClass)}`;
      case 'degree_other':
        return `${cat}${intl.formatMessage(messages.universityDegreeOther)}`;
      case 'ongoing':
        return `${cat}${intl.formatMessage(messages.ongoingStudies)}`;
      case 'start_date':
        return `${cat}${intl.formatMessage(messages.startDate)}`;
      case 'end_date':
        return `${cat}${intl.formatMessage(messages.endDate)}`;
      case 'vote':
        return `${cat}${intl.formatMessage(messages.vote)}`;
      case 'voting_system':
        return `${cat}${intl.formatMessage(messages.votingSystem)}`;
      case 'laudem':
        return `${cat}${intl.formatMessage(messages.laudem)}`;
      default:
        return `${cat}${specificFieldName}`;
    }
  } else if (fieldCategory === 'other_studies') {
    let cat = `${intl.formatMessage(messages.otherStudiesTitle)}: `;

    switch (specificFieldName) {
      case 'title':
        return `${cat}${intl.formatMessage(messages.otherStudiesDegreeTitle)}`;
      case 'start_date':
        return `${cat}${intl.formatMessage(messages.startDate)}`;
      case 'end_date':
        return `${cat}${intl.formatMessage(messages.endDateShort)}`;
      default:
        return `${cat}${specificFieldName}`;
    }
  }

  switch (specificFieldName) {
    case 'gender':
      return intl.formatMessage(messages.gender_bo);

    case 'birth_place':
      return intl.formatMessage(messages.birthPlace);

    case 'birth_province':
      return intl.formatMessage(messages.birthProvince);

    case 'marital_state':
      return intl.formatMessage(messages.maritalState);

    case 'driving_license':
      return intl.formatMessage(messages.drivingLicence);

    case 'residency_address':
      return intl.formatMessage(messages.residencyAddress);

    case 'residency_municipality':
      return intl.formatMessage(messages.residencyMunicipality);

    case 'residency_province':
      return intl.formatMessage(messages.residencyProvince);

    case 'residency_postal_code':
      return `${intl.formatMessage(messages.residency)}: ${intl.formatMessage(
        messages.residencyCAP,
      )}`;

    case 'domicile_address':
      return intl.formatMessage(messages.domicileAddress);

    case 'domicile_municipality':
      return intl.formatMessage(messages.domicileMunicipality);

    case 'domicile_province':
      return intl.formatMessage(messages.domicileProvince);

    case 'domicile_postal_code':
      return `${intl.formatMessage(messages.domicile)}: ${intl.formatMessage(
        messages.domicileCAP,
      )}`;

    case 'end_date':
      return `${intl.formatMessage(messages.endDateWE)} (${intl.formatMessage(
        messages.professionalExperiences,
      )})`;

    case 'date_of_birth':
      return intl.formatMessage(messages.birthDate);

    case 'fullname':
      return intl.formatMessage(messages.fullName);

    case 'settore':
      return intl.formatMessage(messages.settore);

    case 'area':
      return intl.formatMessage(messages.area);

    case 'attivita':
      return intl.formatMessage(messages.attivita);

    case 'ruolo':
      return intl.formatMessage(messages.ruolo);

    case 'tipologia_contrattuale':
      return intl.formatMessage(messages.contractType);

    case 'tipologia_contrattuale_altro':
      return intl.formatMessage(messages.contractTypeOther);

    case 'data_inizio':
      return intl.formatMessage(messages.startDate);

    case 'data_fine':
      return intl.formatMessage(messages.endDateWE);

    case 'notice_to_end_experience':
      return intl.formatMessage(messages.daysNotice);

    case 'personal_cv':
      return 'CV';

    default:
      return intl.formatMessage({
        id: `${specificFieldName}`,
      });
  }
};

// Non avrei mai voluto, ma...
const hex_latin1 = {
  xe0: 'à',
  xe1: 'á',
  xe8: 'è',
  xe9: 'é',
  xec: 'ì',
  xed: 'í',
  xee: 'î',
  xf2: 'ò',
  xf3: 'ó',
  xf9: 'ù',
  xfa: 'ú',
  xc8: 'È',
  xc9: 'É',
};

// Brutto ma efficace
const hexLatin1Converter = (string) => {
  const snips = string.split('\\');
  return snips
    ?.map((s) => {
      if (hex_latin1.hasOwnProperty(s.toLowerCase())) return hex_latin1[s];
      else return s;
    })
    .join('');
};

export const OTHER_DEGREE_TOKEN = 'Altro';
export const OTHER_DEGREE_MAGIC_WORD = 'Specificare';
export const FOREIGN_PROVINCE = 'Estero';

export {
  prepareCVData,
  initializeFormData,
  resetFormData,
  useYupValidationResolver,
  scrollToStepTop,
  validationSchema,
  getLongFieldLabel,
  messages,
  hexLatin1Converter,
};

export class ImageFormatError extends Error {
  constructor(message, fieldId) {
    super(message);
    this.name = 'ImageFormatError';
    this.field = fieldId;
  }
}
