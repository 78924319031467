import React from 'react';
import LanguageFields from './LanguageFields';
import ComputerSkillsFields from './ComputerSkillsFields';

const KnowledgeStep = (props) => {
  const {
    languages,
    setFieldValue,
    office_automation,
    operating_systems,
    programming_languages,
    graphic_design,
    other_computer_skils,
    control,
    intl,
    isDisabled,
  } = props;

  return (
    <div className="step-container" aria-live="polite">
      <LanguageFields
        languages={languages}
        setFieldValue={setFieldValue}
        control={control}
        intl={intl}
        isDisabled={isDisabled}
      />
      <ComputerSkillsFields
        office_automation={office_automation}
        operating_systems={operating_systems}
        programming_languages={programming_languages}
        graphic_design={graphic_design}
        other_computer_skils={other_computer_skils}
        setFieldValue={setFieldValue}
        control={control}
        intl={intl}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default KnowledgeStep;
