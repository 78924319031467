/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React from 'react';

import RWHeader from '@package/components/layout/Header/RWHeader';

const Header = ({ pathname }) => {
  return <RWHeader pathname={pathname} />;
};

export default Header;
