/**
 * Edit icons block.
 * @module components/manage/Blocks/Title/Edit
 */

import React, { useEffect } from 'react';

import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';
import Body from './Body';
import { v4 as uuid } from 'uuid';

const Edit = (props) => {
  useEffect(() => {
    if ((props.data.slides ?? []).length === 0) {
      props.onChangeBlock(props.block, {
        ...props.data,
        fullwidth: false,
        slides: [
          {
            '@id': uuid(),
            content_as_default: true,
            item_bg_color: 'light-grey',
          },
        ],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return __SERVER__ ? (
    <div />
  ) : (
    <>
      <div className="block image-viewer">
        <Body
          data={props.data}
          properties={props.properties}
          inEditMode={true}
        />
      </div>

      <SidebarPortal selected={props.selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </>
  );
};

export default Edit;
