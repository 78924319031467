import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;

  const uniqueID = id + 'sspb';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '493'}
      height={size ?? '240'}
      fill="none"
      viewBox="0 0 493 240"
      className={className}
      key={key}
      {...otherProps}
    >
      <path
        fill={`url(#${uniqueID}paint0_linear)`}
        d="M176.1 89.694h115.489v115.454H176.1V89.694zm107.204 8.282h-98.919v98.89h98.919v-98.89z"
      />
      <path
        fill={`url(#${uniqueID}paint1_linear)`}
        d="M324.728 120.029h77.857v77.835h-77.857v-77.835zm72.367 5.489h-66.778v66.758h66.778v-66.758z"
      />
      <path
        fill={`url(#${uniqueID}paint2_linear)`}
        d="M261.045 159.546V72.929h86.642v86.617h-86.642zm6.887-79.731v72.845h72.867V79.815h-72.867z"
      />
      <path
        fill={`url(#${uniqueID}paint3_linear)`}
        d="M337.505 220.815v-47h47.014v47h-47.014zm4.192-42.809v38.717h38.729v-38.717h-38.729z"
      />
      <path
        fill={`url(#${uniqueID}paint4_linear)`}
        d="M417.358 166.231v-31.832H449.2v31.832h-31.842zm2.695-29.038v26.244h26.252v-26.244h-26.252z"
      />
      <path
        fill={`url(#${uniqueID}paint5_linear)`}
        d="M388.213 148.569v-47.001h47.014v47.001h-47.014zm4.092-42.91v38.719h38.729v-38.719h-38.729z"
      />
      <path
        fill={`url(#${uniqueID}paint6_linear)`}
        d="M446.805 95.382h29.746v29.637h-29.746V95.382zm27.65 2.095h-25.554v25.546h25.554V97.477z"
      />
      <path
        fill={`url(#${uniqueID}paint7_linear)`}
        d="M462.676 113.943V84.205h29.746v29.738h-29.746zm2.096-27.642v25.546h25.554V86.301h-25.554z"
      />
      <mask
        id="57ph5xlbda"
        width="249"
        height="240"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#C4C4C4"
          d="M0.422 0.084H248.96699999999998V239.388H0.422z"
        />
      </mask>
      <g mask="url(#57ph5xlbda)">
        <path
          fill={`url(#${uniqueID}paint8_linear)`}
          d="M226.59 227.101H38.025V38.593H226.59V227.1zM48.114 216.924h168.295V48.678H48.114v168.246z"
        />
      </g>
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="303.391"
          x2="164.329"
          y1="122.115"
          y2="172.744"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="410.541"
          x2="316.792"
          y1="141.886"
          y2="176.018"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="285.375"
          x2="323.33"
          y1="64.078"
          y2="168.388"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint3_linear`}
          className="paint_linear"
          x1="350.707"
          x2="371.301"
          y1="169.013"
          y2="225.613"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint4_linear`}
          className="paint_linear"
          x1="426.3"
          x2="440.248"
          y1="131.146"
          y2="169.481"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint5_linear`}
          className="paint_linear"
          x1="401.415"
          x2="422.011"
          y1="96.765"
          y2="153.367"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint6_linear`}
          className="paint_linear"
          x1="479.59"
          x2="443.802"
          y1="103.704"
          y2="116.778"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint7_linear`}
          className="paint_linear"
          x1="471.029"
          x2="484.06"
          y1="81.166"
          y2="116.978"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint8_linear`}
          className="paint_linear"
          x1="18.756"
          x2="245.808"
          y1="174.165"
          y2="91.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Icon;
