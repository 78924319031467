import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Dimmer, Loader } from 'semantic-ui-react';
import { useIntl, FormattedMessage } from 'react-intl';
import { PresetWrapper } from '@package/components';
import { defineMessages } from 'react-intl';
import JobPostingListingItem from '@package/components/Blocks/JobPosting/components/JobPostingListingItem';
import JobPostingListingPagination from '@package/components/Blocks/JobPosting/components/JobPostingListingPagination';
import JobPostingListingSpontaneousApplicationCTA from '@package/components/Blocks/JobPosting/components/JobPostingListingSpontaneousApplicationCTA';

const messages = defineMessages({
  jobposting_noresult: {
    id: 'jobposting_noresult',
    defaultMessage: 'Job Posting',
  },
  bando: {
    id: 'bando',
    defaultMessage: 'Bando',
  },
  tirocinio: {
    id: 'tirocinio',
    defaultMessage: 'Tirocinio',
  },
  no_results_message: {
    id: 'no_results_message',
    defaultMessage: 'Nessun {content} disponibile in questo momento',
  },
});

const JobPostingListing = (props) => {
  const {
    items,
    totalPages,
    totalItems,
    currentPage,
    bSize,
    bSizeOptions,
    onChangeBSize,
    onChangePage,
    hasLoaded,
    currentTab,
    isStandaloneBlock = false,
  } = props;
  const intl = useIntl();

  const loaded = !!!isStandaloneBlock ? true : hasLoaded;
  return (
    <Dimmer.Dimmable dimmed={!loaded}>
      <PresetWrapper {...props}>
        <Dimmer active={!loaded} inverted>
          <Loader indeterminate size="large">
            <FormattedMessage id="loading" defaultMessage="Loading..." />
          </Loader>
        </Dimmer>
        {loaded && (
          <Grid>
            {items?.length > 0 ? (
              items?.map((item) => {
                return <JobPostingListingItem {...item} key={item.uid} />;
              })
            ) : (
              <Grid.Row>
                <div className="no-results">
                  <div className="no-results-message">
                    {intl.formatMessage(messages.no_results_message, {
                      content:
                        currentTab === 0
                          ? intl.formatMessage(messages.jobposting_noresult)
                          : currentTab === 1
                          ? intl.formatMessage(messages.bando)
                          : intl.formatMessage(messages.tirocinio),
                    })}
                  </div>
                </div>
              </Grid.Row>
            )}
            {/* If meant to be used as a canonical listing, use default Volto pagination */}
            {/* that is included in ListingBody.jsx when generating a Listing Block */}
            <div className="listing-foot">
              {!!props?.isStandaloneBlock && (
                <JobPostingListingPagination
                  totalPages={totalPages}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  bSize={bSize}
                  bSizeOptions={bSizeOptions}
                  onChangePage={onChangePage}
                  onChangeBSize={onChangeBSize}
                  intl={intl}
                />
              )}

              <JobPostingListingSpontaneousApplicationCTA intl={intl} />
            </div>
          </Grid>
        )}
      </PresetWrapper>
    </Dimmer.Dimmable>
  );
};

JobPostingListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
  linkTitle: PropTypes.string,
  linkHref: PropTypes.any,
  preset_color: PropTypes.any,
};
export default JobPostingListing;
