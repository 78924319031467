import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'spfg';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '632'}
      height={size ?? '283'}
      fill="none"
      viewBox="0 0 632 283"
      className={className}
      key={key}
      {...otherProps}
    >
      <g clipPath={`url(#${uniqueID}clip0)`}>
        <path
          fill={`url(#${uniqueID}paint0_linear)`}
          d="M165.929 259.309V53.769h206.21v205.54h-206.21zm11.132-194.543v183.446h184.045V64.767H177.061z"
        />
        <path
          fill={`url(#${uniqueID}paint1_linear)`}
          d="M298.232 109.013h115.7v116.009h-115.7V109.013zm107.4 8.322h-99.1V216.7h99.1v-99.365z"
        />
        <path
          fill={`url(#${uniqueID}paint2_linear)`}
          d="M447.133 139.494h78v78.209h-78v-78.209zm72.5 5.515h-66.9v67.079h66.9v-67.079z"
        />
        <path
          fill={`url(#${uniqueID}paint3_linear)`}
          d="M470.132 179.2h-86.8V92.168h86.8V179.2zm-79.9-6.918h73V99.087h-73v73.195z"
        />
        <path
          fill={`url(#${uniqueID}paint4_linear)`}
          d="M58.319.424h58.4v62.065h-58.4v-6.918h51.5V7.442h-51.5V.424z"
        />
        <path
          fill={`url(#${uniqueID}paint5_linear)`}
          d="M53.733 62.489h6.9V.424h-6.9v62.065z"
        />
        <path
          fill={`url(#${uniqueID}paint6_linear)`}
          d="M267.433 281.673H.633V14.16h266.8v267.513zm-253-13.937h239.1V28.097h-239.1v239.639z"
        />
        <path
          fill={`url(#${uniqueID}paint7_linear)`}
          d="M507.034 240.764h-47.1v-47.226h47.1v47.226zm-42.9-4.212h38.8v-38.903h-38.8v38.903z"
        />
        <path
          fill={`url(#${uniqueID}paint8_linear)`}
          d="M571.834 185.918h-31.9v-31.985h31.9v31.985zm-29.1-2.707h26.3V156.84h-26.3v26.371z"
        />
        <path
          fill={`url(#${uniqueID}paint9_linear)`}
          d="M510.663 168.101v-47.086h47.239v47.086h-47.239zm4.112-42.987v38.788h38.915v-38.788h-38.915z"
        />
        <path
          fill={`url(#${uniqueID}paint10_linear)`}
          d="M642.831 169.675h-29.8v-29.88h29.8v29.88zm-27.7-2.006h25.6v-25.668h-25.6v25.668z"
        />
        <path
          fill={`url(#${uniqueID}paint11_linear)`}
          d="M599.234 144.508h-29.8v-29.78h29.8v29.78zm-27.7-2.106h25.6v-25.668h-25.6v25.668z"
        />
        <path
          fill={`url(#${uniqueID}paint12_linear)`}
          d="M615.134 133.378h-29.8v-29.879h29.8v29.879zm-27.7-2.105h25.6v-25.669h-25.6v25.669z"
        />
        <path
          fill={`url(#${uniqueID}paint13_linear)`}
          d="M163.231 126.359h-92.2V33.913h92.2v92.446zm-85.2-6.918h78.3v-78.51h-78.3v78.51z"
        />
      </g>
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="269.02"
          x2="269.02"
          y1="259.296"
          y2="53.856"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="298.239"
          x2="413.883"
          y1="167.009"
          y2="167.009"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="447.138"
          x2="525.1"
          y1="178.593"
          y2="178.593"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint3_linear`}
          className="paint_linear"
          x1="470.127"
          x2="383.368"
          y1="135.69"
          y2="135.69"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint4_linear`}
          className="paint_linear"
          x1="53.737"
          x2="116.692"
          y1="31.452"
          y2="31.452"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint5_linear`}
          className="paint_linear"
          x1="53.737"
          x2="116.692"
          y1="31.452"
          y2="31.452"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint6_linear`}
          className="paint_linear"
          x1="267.416"
          x2=".744"
          y1="147.935"
          y2="147.935"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint7_linear`}
          className="paint_linear"
          x1="507.031"
          x2="459.953"
          y1="217.154"
          y2="217.154"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint8_linear`}
          className="paint_linear"
          x1="571.832"
          x2="539.947"
          y1="169.927"
          y2="169.927"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint9_linear`}
          className="paint_linear"
          x1="534.279"
          x2="534.279"
          y1="168.098"
          y2="121.035"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint10_linear`}
          className="paint_linear"
          x1="642.829"
          x2="613.044"
          y1="154.737"
          y2="154.737"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint11_linear`}
          className="paint_linear"
          x1="599.232"
          x2="569.446"
          y1="129.62"
          y2="129.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint12_linear`}
          className="paint_linear"
          x1="615.132"
          x2="585.346"
          y1="118.44"
          y2="118.44"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint13_linear`}
          className="paint_linear"
          x1="163.225"
          x2="71.07"
          y1="80.142"
          y2="80.142"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <clipPath id={`${uniqueID}clip0`}>
          <path
            fill="#fff"
            d="M0 0H631V281.751H0z"
            transform="translate(0.632812 0.423462)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Icon;
