import React from 'react';
import { Form, Popup, Icon } from 'semantic-ui-react';
import {
  TextWidget,
  DatetimeWidgetComponent,
  CheckboxWidget,
  FormFieldWrapper,
  SelectWidget,
} from '../../CVWidgets';
import { messages, OTHER_DEGREE_TOKEN } from '../utils';
import UniversityFields from './UniversityFields';
import OtherStudiesFields from './OtherStudiesFields';

const EducationStep = (props) => {
  const {
    professional_register,
    specific_professional_register,
    professional_abilitation_exam,
    date_of_subscription,
    high_school,
    university,
    other_studies,
    setFieldValue,
    control,
    intl,
    isDisabled,
  } = props;

  const handleRadiosOnChange = (id, value) => {
    let newFieldValue = {
      title: value,
      token: value,
    };
    if (value === 'Yes' || value === 'No')
      newFieldValue = {
        title: value,
        token: value === 'Yes' ? 'True' : 'False',
      };

    setFieldValue(id, newFieldValue);
  };

  return (
    <div className="step-container" aria-live="polite">
      <h4>{intl.formatMessage(messages.highSchoolDegreeTitle)}</h4>
      <Form.Group widths="2">
        <SelectWidget
          isDisabled={isDisabled}
          id="high_school.degree_type"
          title={intl.formatMessage(messages.highSchoolDegreeType)}
          value={high_school?.degree_type}
          onChange={(id, value) => {
            setFieldValue('high_school', {
              ...high_school,
              degree_type: value,
              degree_other:
                value !== high_school?.degree_type
                  ? ''
                  : high_school?.degree_other,
            });
          }}
          vocabBaseUrl="raiway.lavoraconnoi.HighSchoolDegree"
          noValueOption={false}
          required={true}
          control={control}
        />
        {high_school?.degree_type === OTHER_DEGREE_TOKEN && (
          <TextWidget
            isDisabled={isDisabled}
            id="high_school.degree_other"
            type="text"
            name="high_school.degree_other"
            value={high_school?.degree_other}
            onChange={(id, value) => {
              setFieldValue('high_school', {
                ...high_school,
                degree_other: value,
              });
            }}
            required={high_school.degree_type === OTHER_DEGREE_TOKEN}
            title={intl.formatMessage(messages.highSchoolDegreeOther)}
            wrapped={true}
            control={control}
          />
        )}
      </Form.Group>
      <Form.Group widths="equal">
        <TextWidget
          isDisabled={isDisabled}
          id="high_school.name"
          name="high_school.name"
          type="text"
          title={intl.formatMessage(messages.highSchoolName)}
          value={high_school?.name}
          onChange={(id, value) => {
            setFieldValue('high_school', {
              ...high_school,
              name: value,
            });
          }}
          required={false}
          wrapped={true}
          control={control}
        />
        <FormFieldWrapper
          id="high_school.equipollenza"
          title={intl.formatMessage(messages.equipollenza)}
          wrapped
          control={control}
          popup={
            <Popup
              on={['click', 'hover']}
              trigger={<Icon name="question circle" />}
              content={intl.formatMessage(messages.equipollenzaPopup)}
              position="top left"
              hideOnScroll={true}
            />
          }
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="high_school.equipollenza"
            radio={true}
            title={intl.formatMessage(messages.yes)}
            value={true}
            checked={high_school?.equipollenza}
            onChange={(id, value) => {
              setFieldValue('high_school', {
                ...high_school,
                equipollenza: value,
              });
            }}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="high_school.equipollenza"
            radio={true}
            title={intl.formatMessage(messages.no)}
            value={false}
            checked={!high_school?.equipollenza}
            onChange={(id, value) => {
              setFieldValue('high_school', {
                ...high_school,
                equipollenza: value,
              });
            }}
          />
        </FormFieldWrapper>
      </Form.Group>
      <Form.Group widths="equal">
        <DatetimeWidgetComponent
          isDisabled={isDisabled}
          id="high_school.start_date"
          title={intl.formatMessage(messages.startDate)}
          required={true}
          onChange={(id, value) => {
            setFieldValue('high_school', {
              ...high_school,
              start_date: value,
            });
          }}
          wrapped={true}
          value={high_school?.start_date}
          dateOnly={true}
          control={control}
        />
        <DatetimeWidgetComponent
          isDisabled={isDisabled}
          id="high_school.end_date"
          title={intl.formatMessage(messages.endDateShort)}
          required={true}
          onChange={(id, value) => {
            setFieldValue('high_school', {
              ...high_school,
              end_date: value,
            });
          }}
          wrapped={true}
          value={high_school?.end_date}
          dateOnly={true}
          control={control}
        />
      </Form.Group>
      <Form.Group widths={'equal'}>
        <TextWidget
          isDisabled={isDisabled}
          id="high_school.vote"
          type="text"
          name="high_school.vote"
          value={high_school?.vote}
          onChange={(id, value) => {
            if (/^[0-9]*$/.test(value) || !value) {
              setFieldValue('high_school', {
                ...high_school,
                vote: value,
              });
            }
          }}
          required={true}
          title={intl.formatMessage(messages.vote)}
          wrapped={true}
          control={control}
        />
        <SelectWidget
          isDisabled={isDisabled}
          id="high_school.voting_system"
          title={intl.formatMessage(messages.votingSystem)}
          value={high_school?.voting_system}
          onChange={(id, value) => {
            setFieldValue('high_school', {
              ...high_school,
              voting_system: value,
            });
          }}
          choices={[
            ['/60', '/60'],
            ['/100', '/100'],
          ]}
          required={true}
          control={control}
        />
        <FormFieldWrapper
          id="high_school.laudem"
          title={intl.formatMessage(messages.laudem)}
          wrapped
          control={control}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="high_school.laudem"
            title={intl.formatMessage(messages.yes)}
            radio={true}
            value={true}
            checked={high_school?.laudem}
            onChange={(id, value) => {
              setFieldValue('high_school', {
                ...high_school,
                laudem: value,
              });
            }}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="high_school.laudem"
            title={intl.formatMessage(messages.no)}
            radio={true}
            value={false}
            checked={!high_school?.laudem}
            onChange={(id, value) => {
              setFieldValue('high_school', {
                ...high_school,
                laudem: value,
              });
            }}
          />
        </FormFieldWrapper>
      </Form.Group>

      <UniversityFields
        university={university}
        setFieldValue={setFieldValue}
        control={control}
        intl={intl}
        isDisabled={isDisabled}
      />

      <OtherStudiesFields
        other_studies={other_studies}
        setFieldValue={setFieldValue}
        control={control}
        intl={intl}
        isDisabled={isDisabled}
      />

      <h4>{intl.formatMessage(messages.professionalRegistry)}</h4>

      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          id="professional_abilitation_exam"
          title={intl.formatMessage(messages.professionalAbilitationExam)}
          wrapped
          className=""
          control={control}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="professional_abilitation_exam"
            radio={true}
            checked={professional_abilitation_exam?.token === 'True'}
            value={'Yes'}
            title={intl.formatMessage(messages.yes)}
            onChange={handleRadiosOnChange}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="professional_abilitation_exam"
            radio={true}
            checked={professional_abilitation_exam?.token === 'False'}
            value={'No'}
            title={intl.formatMessage(messages.no)}
            onChange={handleRadiosOnChange}
          />
        </FormFieldWrapper>
        <FormFieldWrapper
          id="professional_register"
          title={intl.formatMessage(messages.professionalRegistry)}
          wrapped
          className=""
          control={control}
        >
          <CheckboxWidget
            isDisabled={isDisabled}
            id="professional_register"
            radio={true}
            checked={professional_register?.token === 'True'}
            value={'Yes'}
            title={intl.formatMessage(messages.yes)}
            onChange={handleRadiosOnChange}
          />
          <CheckboxWidget
            isDisabled={isDisabled}
            id="professional_register"
            radio={true}
            checked={professional_register?.token === 'False'}
            value={'No'}
            title={intl.formatMessage(messages.no)}
            onChange={handleRadiosOnChange}
          />
        </FormFieldWrapper>
      </Form.Group>
      <Form.Group widths={'equal'}>
        <TextWidget
          isDisabled={isDisabled}
          id="specific_professional_register"
          type="text"
          name="specific_professional_register"
          value={specific_professional_register}
          onChange={setFieldValue}
          required={false}
          title={intl.formatMessage(messages.specificProfessionalRegistry)}
          wrapped={true}
          control={control}
          popup={
            <Popup
              on={['click', 'hover']}
              trigger={<Icon name="question circle" />}
              content={intl.formatMessage(messages.professionalRegistryPopup)}
              position="top left"
              hideOnScroll={true}
            />
          }
        />
        <DatetimeWidgetComponent
          isDisabled={isDisabled}
          id="date_of_subscription"
          title={intl.formatMessage(messages.registrationDate)}
          required={false}
          onChange={setFieldValue}
          wrapped={true}
          value={date_of_subscription}
          dateOnly={true}
          control={control}
        />
      </Form.Group>
    </div>
  );
};

export default EducationStep;
