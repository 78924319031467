import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'sp1fg';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '632'}
      height={size ?? '283'}
      fill="none"
      viewBox="0 0 632 283"
      className={className}
      key={key}
      {...otherProps}
    >
      <g clipPath={`url(#${uniqueID}clip0)`}>
        <path
          fill={`url(#${uniqueID}paint0_linear)`}
          d="M193.866 249.198V71.506h178.273v177.692H193.866zm9.625-168.186v158.592h159.11V81.012h-159.11z"
        />
        <path
          fill={`url(#${uniqueID}paint1_linear)`}
          d="M298.234 108.979h115.7v116.009h-115.7V108.979zm107.4 8.322h-99.1v99.365h99.1v-99.365z"
        />
        <path
          fill={`url(#${uniqueID}paint2_linear)`}
          d="M447.135 139.461h78v78.208h-78v-78.208zm72.5 5.514h-66.9v67.079h66.9v-67.079z"
        />
        <path
          fill={`url(#${uniqueID}paint3_linear)`}
          d="M470.134 179.167h-86.8V92.135h86.8v87.032zm-79.9-6.919h73V99.053h-73v73.195z"
        />
        <path
          fill={`url(#${uniqueID}paint4_linear)`}
          d="M58.32.39h58.401v62.066h-58.4v-6.919h51.5V7.41h-51.5V.39z"
        />
        <path
          fill={`url(#${uniqueID}paint5_linear)`}
          d="M53.735 62.456h6.9V.39h-6.9v62.066z"
        />
        <path
          fill={`url(#${uniqueID}paint6_linear)`}
          d="M267.434 281.64H15.869V29.403h251.565V281.64zM28.881 268.498h225.447V42.544H28.881v225.954z"
        />
        <path
          fill={`url(#${uniqueID}paint7_linear)`}
          d="M459.864 240.66v-47.086h47.24v47.086h-47.24zm4.212-42.887v38.788h38.915v-38.788h-38.915z"
        />
        <path
          fill={`url(#${uniqueID}paint8_linear)`}
          d="M571.834 185.884h-31.9v-31.985h31.9v31.985zm-29.1-2.707h26.3v-26.37h-26.3v26.37z"
        />
        <path
          fill={`url(#${uniqueID}paint9_linear)`}
          d="M510.665 168.067v-47.086h47.239v47.086h-47.239zm4.112-42.987v38.789h38.915V125.08h-38.915z"
        />
        <path
          fill={`url(#${uniqueID}paint10_linear)`}
          d="M599.236 144.474h-29.8v-29.779h29.8v29.779zm-27.7-2.105h25.6V116.7h-25.6v25.669z"
        />
        <path
          fill={`url(#${uniqueID}paint11_linear)`}
          d="M615.134 133.345h-29.8v-29.88h29.8v29.88zm-27.7-2.106h25.6v-25.668h-25.6v25.668z"
        />
      </g>
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="282.99"
          x2="282.99"
          y1="249.186"
          y2="71.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="298.241"
          x2="413.885"
          y1="166.976"
          y2="166.976"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="447.14"
          x2="525.102"
          y1="178.559"
          y2="178.559"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint3_linear`}
          className="paint_linear"
          x1="470.129"
          x2="383.37"
          y1="135.657"
          y2="135.657"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint4_linear`}
          className="paint_linear"
          x1="53.739"
          x2="116.694"
          y1="31.419"
          y2="31.419"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint5_linear`}
          className="paint_linear"
          x1="53.739"
          x2="116.694"
          y1="31.419"
          y2="31.419"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint6_linear`}
          className="paint_linear"
          x1="267.418"
          x2="15.974"
          y1="155.538"
          y2="155.538"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint7_linear`}
          className="paint_linear"
          x1="483.48"
          x2="483.48"
          y1="240.657"
          y2="193.594"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint8_linear`}
          className="paint_linear"
          x1="571.832"
          x2="539.947"
          y1="169.894"
          y2="169.894"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint9_linear`}
          className="paint_linear"
          x1="534.281"
          x2="534.281"
          y1="168.065"
          y2="121.001"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint10_linear`}
          className="paint_linear"
          x1="599.234"
          x2="569.448"
          y1="129.587"
          y2="129.587"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint11_linear`}
          className="paint_linear"
          x1="615.132"
          x2="585.346"
          y1="118.407"
          y2="118.407"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <clipPath id={`${uniqueID}clip0`}>
          <path
            fill="#fff"
            d="M0 0H631V281.75H0z"
            transform="translate(0.632812 0.390137)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Icon;
