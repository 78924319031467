import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  TextWidget,
  TextareaWidget,
  SelectWidget,
  CheckboxWidget,
  DatetimeWidgetComponent,
} from '.';
import FormFieldWrapper from './FormFieldWrapper';

const Field = ({
  label_it,
  label_en,
  description,
  name,
  field_type,
  // required,
  input_values_it = [],
  input_values_en = [],
  value,
  onChange,
  isOnEdit,
  valid,
  disabled = false,
  formHasErrors = false,
  id,
  control,
}) => {
  const intl = useIntl();

  const isInvalid = () => {
    return !isOnEdit && !valid;
  };

  const label = intl.locale === 'it' ? label_it : label_en;
  const input_values = intl.locale === 'it' ? input_values_it : input_values_en;

  switch (field_type) {
    case 'text':
      return (
        <TextWidget
          id={name}
          name={name}
          title={label}
          description={description}
          // required={required}
          onChange={onChange}
          value={value}
          isDisabled={disabled}
          invalid={isInvalid().toString()}
          {...(isInvalid() ? { className: 'is-invalid' } : {})}
          control={control}
        />
      );
    case 'textarea':
      return (
        <TextareaWidget
          id={name}
          name={name}
          title={label}
          description={description}
          // required={required}
          onChange={onChange}
          defaultValue={value}
          rows={10}
          isDisabled={disabled}
          invalid={isInvalid().toString()}
          {...(isInvalid() ? { className: 'is-invalid' } : {})}
          control={control}
        />
      );
    case 'select':
      return (
        <SelectWidget
          id={name}
          name={name}
          title={label}
          description={description}
          choices={[...input_values.map((v) => [v, v])]}
          value={value}
          onChange={onChange}
          isDisabled={disabled}
          invalid={isInvalid().toString()}
          {...(isInvalid() ? { className: 'is-invalid' } : {})}
          control={control}
        />
      );
    case 'single_choice':
      return (
        <FormFieldWrapper
          id={name}
          title={label}
          description={description}
          // required={required}
          wrapped
          control={control}
        >
          {input_values.map((v) => (
            <CheckboxWidget
              key={v}
              radio={true}
              id={name}
              title={v}
              onChange={onChange}
              value={v}
              checked={value === v}
              isDisabled={disabled}
              invalid={isInvalid().toString()}
              {...(isInvalid() ? { className: 'is-invalid' } : {})}
            />
          ))}
        </FormFieldWrapper>
      );
    case 'multiple_choice':
      return (
        <FormFieldWrapper
          id={name}
          title={label}
          description={description}
          // required={required}
          wrapped
          control={control}
        >
          {input_values.map((v) => (
            <CheckboxWidget
              key={v}
              id={name}
              title={v}
              onChange={onChange}
              value={v}
              checked={value.includes(v)}
              isDisabled={disabled}
              invalid={isInvalid().toString()}
              {...(isInvalid() ? { className: 'is-invalid' } : {})}
            />
          ))}
        </FormFieldWrapper>
      );
    case 'date':
      return (
        <DatetimeWidgetComponent
          id={name}
          name={name}
          title={label}
          description={description}
          dateOnly={true}
          noPastDates={false}
          onChange={onChange}
          value={value}
          isDisabled={disabled}
          invalid={isInvalid().toString()}
          {...(isInvalid() ? { className: 'is-invalid' } : {})}
          control={control}
        />
      );
    default:
      return null;
  }
};

Field.propTypes = {
  label_it: PropTypes.string.isRequired,
  label_en: PropTypes.string.isRequired,
  name: PropTypes.string,
  description: PropTypes.string,
  // required: PropTypes.bool,
  field_type: PropTypes.string.isRequired,
  input_values: PropTypes.any,
  value: PropTypes.any,
  formHasErrors: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Field;
