/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 * }
 */

// All your imports required for the config here BEFORE this line
import Favicon from '@package/components/layout/Favicon';
import { CustomCSS } from '@package/components';
import { RaiWayBlocks } from '@package/config/Blocks';
import { RaiWayWidgets } from '@package/config/Widgets';
import { RaiWayViews } from '@package/config/Views';
import ApplicationsListToolbar from '@package/components/backoffice-lavoraconnoi/Backoffice/ApplicationsListToolbar';
import BackofficeLink from '@package/components/backoffice-lavoraconnoi/BackofficeLink/BackofficeLink';
import LinkedinTracker from '@package/components/backoffice-lavoraconnoi/LinkedinTracker/LinkedinTracker';
import BodyUserRoles from '@package/components/BodyUserRoles/BodyUserRoles';
import applyRichTextConfig from '@package/config/RichTextEditor/config';
import applyAccordionConfig from '@package/config/Accordion/config';

import {
  config as faConfig,
  dom as faDom,
  library,
} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/pro-solid-svg-icons';
import * as IconsRegular from '@fortawesome/pro-regular-svg-icons';
import * as IconsDuotone from '@fortawesome/pro-duotone-svg-icons';
import * as IconsLight from '@fortawesome/pro-light-svg-icons';
import * as IconsBrands from '@fortawesome/free-brands-svg-icons';

import '@plone/volto/config';

faConfig.autoAddCss = false;
const iconList = Object.keys(Icons.fas).map((icon) => Icons[icon]);
const iconListRegular = Object.keys(IconsRegular.far).map(
  (icon) => IconsRegular[icon],
);

const iconListDuotone = Object.keys(IconsDuotone.fad).map(
  (icon) => IconsDuotone[icon],
);

const iconListLight = Object.keys(IconsLight.fal).map(
  (icon) => IconsLight[icon],
);

const iconListBrands = Object.keys(IconsBrands.fab).map(
  (icon) => IconsBrands[icon],
);

library.add(
  ...iconList,
  ...iconListRegular,
  ...iconListDuotone,
  ...iconListLight,
  ...iconListBrands,
);

export default function applyConfig(config) {
  // Add here your project's configuration here by modifying `config` accordingly
  config = applyRichTextConfig(config);
  config = applyAccordionConfig(config);
  config.settings.isMultilingual = true;
  config.settings.supportedLanguages = ['it', 'en'];
  config.settings.defaultLanguage = 'it';
  config.settings.notSupportedBrowsers = [];
  config.settings.showTags = false;
  config.settings.downloadableObjects = [];
  config.settings.viewableInBrowserObjects = ['File'];
  config.settings.appExtras = [
    ...config.settings.appExtras,
    {
      match: '',
      component: () => <style type="text/css">{faDom.css()}</style>, //load fontawesom dom css
    },
    {
      match: '',
      component: Favicon,
    },
    { match: '', component: CustomCSS },
    { match: '', component: ApplicationsListToolbar },
    { match: '', component: BackofficeLink },
    { match: '', component: BodyUserRoles },
    { match: '', component: LinkedinTracker },
  ];

  config.settings['lavora-con-noi'] = {
    backofficeURL: '/backoffice',
    openPositionsPath: {
      it: '/it/lavora-con-noi/posizioni-aperte',
      en: '/en/work-with-us/open-positions',
    },
  };

  config.settings.nonContentRoutes = [
    ...config.settings.nonContentRoutes,
    /\/passwordreset\/.*$/,
    '/passwordreset',
    config.settings['lavora-con-noi'].backofficeURL,
  ];

  RaiWayBlocks(config); //configurazione dei blocchi di RaiWay
  RaiWayWidgets(config); //configurazione dei widget di RaiWay
  RaiWayViews(config); //configurazione delle viste di RaiWay

  config.settings['volto-dropdownmenu'] = {
    options: {
      clickableNavigationRoots: true, //if true, a checkbox option in dropdown menu appears
    },
  };

  config.settings['volto-gdpr-privacy'] = {
    ...config.settings['volto-gdpr-privacy'],
    defaultPanelConfig: {
      ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
      last_updated: '2022-09-28T18:00:00+00:00',
      text: {
        it: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.it,
          description:
            "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti. Utilizza anche cookie di profilazione dell'utente per fini statistici. I cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Cambia le impostazioni'. Per saperne di più su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='/it/privacy-e-cookies' target='_blank' rel='noreferrer'>Cookie Policy.</a>",
        },
        en: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.text.en,
          description:
            "This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies for statistical and remarketing purposes. For profiling cookies you can decide whether to enable them or not by clicking on the 'Change settings' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href='/en/privacy-and-cookies' target='_blank' rel='noreferrer'>Cookie Policy</a>.",
        },
      },
      technical: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
        choices: [
          {
            config_key: 'GANALYTICS',
            text: {
              it: {
                title: 'Google Analytics 4',
                description:
                  "I cookie di Google Analytics 4 sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
              },
              en: {
                title: 'Google Analytics 4',
                description:
                  'Google Analytics 4 cookies are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
              },
            },
          },
        ],
      },
    },
  };

  return config;
}
