import React from 'react';
import { Button } from 'semantic-ui-react';
import { useIntl, defineMessages } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Icon } from '@plone/volto/components';
import ctaArrowSVG from '@package/icons/cta-arrow.svg';
import cx from 'classnames';
import GenericLCNListingItem from '@package/components/GenericLCNListingItem/GenericLCNListingItem';

const messages = defineMessages({
  creation_date: {
    id: 'creation_date',
    defaultMessage: 'Inserito il: ',
  },
  modified_date: {
    id: 'modified_date',
    defaultMessage: 'Ultima modifica ',
  },
  jp_scaduto: {
    id: 'jp_scaduto',
    defaultMessage: 'Scaduto',
  },
  jp_scadenza: {
    id: 'jp_scadenza',
    defaultMessage: 'Scadenza: ',
  },
});

const JobPostingListingItem = (props) => {
  const intl = useIntl();
  const {
    title,
    effective,
    EffectiveDate,
    possibile_sottotitolo,
    Subject,
    UID,
    Modified,
    tipologia_jobposting,
    data_scadenza_jobposting,
    moment: { default: moment },
  } = props;
  moment.locale(intl.locale);

  return (
    <GenericLCNListingItem
      key={UID}
      itemUrl={flattenToAppURL(props?.['@id'])}
      firstColumnContent={{
        className: cx('icon-container', {
          bando: tipologia_jobposting === 'bando',
          tirocinio: tipologia_jobposting === 'tirocinio',
          jobposting: tipologia_jobposting === 'jobposting',
        }),
      }}
      secondColumnContent={{
        className: 'item-title-container',
        content: (
          <>
            <span className="item-title">{title}</span>
            <span className="item-structure">{possibile_sottotitolo}</span>
          </>
        ),
        width: 3,
      }}
      thirdColumnContent={{
        className: 'item-created',
        content: (
          <>
            <div>
              {EffectiveDate !== 'None' &&
                `${intl.formatMessage(messages.creation_date)}${moment(
                  effective,
                ).format('DD.MM.YYYY')}`}
            </div>
            <div>
              {moment(data_scadenza_jobposting).isAfter(moment())
                ? `${intl.formatMessage(messages.jp_scadenza)}${moment(
                    data_scadenza_jobposting,
                  ).format('DD.MM.YYYY, HH:mm')}`
                : intl.formatMessage(messages.jp_scaduto)}
            </div>
          </>
        ),
        width: 4,
      }}
      fourthColumnContent={{
        className: 'subjects rw--chips',
        content: {
          desktop: (
            <>
              {Subject?.map((s, i) => (
                <Button className="rw--chip" as={''} key={i}>
                  {s}
                </Button>
              ))}
            </>
          ),
          mobile: (
            <div className="item-updated">
              {Modified !== 'None' &&
                `${intl.formatMessage(messages.modified_date)}${moment(
                  effective,
                ).format('DD.MM.YYYY')}`}
            </div>
          ),
        },
        width: 3,
      }}
      fifthColumnContent={{
        className: 'icon-container push-right',
        content: {
          button: (
            <Button className="icon-button" key={props?.['@id']}>
              <Icon name={ctaArrowSVG} size="24px" className="arrow-icon" />
            </Button>
          ),
        },
        width: 1,
      }}
      columns={5}
    />
  );
};

export default injectLazyLibs(['moment'])(JobPostingListingItem);
