import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { addPresetFields } from '@package/helpers/presets';
import {
  CheckboxWidget,
  ObjectListWidget,
  FormFieldWrapper,
} from '@plone/volto/components';
import { TITLE_COLORS } from '@package/helpers/presets';
import ImageSizeWidget from '@plone/volto/components/manage/Blocks/Image/ImageSizeWidget';

const messages = defineMessages({
  size: {
    id: 'Size',
    defaultMessage: 'Dimensione',
  },
  title: {
    id: 'Title',
    defaultMessage: 'Titolo',
  },
  subtitle: {
    id: 'Subtitle',
    defaultMessage: 'Sottotitolo',
  },
  description: {
    id: 'Description',
    defaultMessage: 'Descrizione',
  },
  image: {
    id: 'Image',
    defaultMessage: 'Immagine',
  },
  buttonText: {
    id: 'SliderBlock: Testo sul bottone',
    defaultMessage: 'Testo sul bottone',
  },
  buttonTextDescription: {
    id: 'SliderBlock: Testo sul bottone description',
    defaultMessage:
      "Se presente, verrà mostrato un bottone che al click porterà alla destinazione scelta nel campo 'Destinazione'. Se non compilato, il link verrà posizionato sul titolo della slide",
  },
  opacity: {
    id: "SliderBlock: Opacizza l'immagine",
    defaultMessage: "Opacizza l'immagine",
  },
  sign: {
    id: 'SliderBlock: Segno grafico',
    defaultMessage: 'Segno grafico',
  },

  titleAlign: {
    id: 'SliderBlock: Allineamento del titolo',
    defaultMessage: 'Allineamento del titolo',
  },
  textAlign: {
    id: 'SliderBlock: Allineamento del testo',
    defaultMessage: 'Allineamento del testo',
  },
  textFullWidth: {
    id: 'SliderBlock: Larghezza del testo 100%',
    defaultMessage: 'Mostra il testo a tutta larghezza',
  },
  hrefAlign: {
    id: 'SliderBlock: Allineamento del bottone',
    defaultMessage: 'Allineamento del bottone',
  },
  sign_align: {
    id: 'SliderBlock: Allineamento del segno grafico',
    defaultMessage: 'Allineamento del segno grafico',
  },
  separator: {
    id: 'SliderBlock: Mostra un separatore dopo il titolo',
    defaultMessage: 'Mostra un separatore dopo il titolo',
  },
  separator_color: {
    id: 'SliderBlock: Colore del separatore',
    defaultMessage: 'Colore del separatore',
  },
  content_as_default: {
    id: 'SliderBlock: Mostra i dati del contenuto corrente',
    defaultMessage: 'Mostra i dati del contenuto corrente',
  },
  content_as_default_desc: {
    id: 'SliderBlock: Mostra i dati del contenuto corrente description',
    defaultMessage:
      'Mostra di default i dati del contenuto corrente. Il testo inserito nei campi seguenti rimpiazzerà quello del contenuto.',
  },
  hide_title: {
    id: 'SliderBlock: Nascondi titolo sulla slide',
    defaultMessage: 'Nascondi titolo sulla slide',
  },
  show_cta_arrow: {
    id: 'SliderBlock: show_cta_arrow',
    defaultMessage: 'Mostra la freccia sul bottone',
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  const { data, block, onChangeBlock } = props;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage id="SliderBlock" defaultMessage="Blocco Slider" />:
        </h2>
      </header>

      <Segment className="form sidebar-listing-data">
        <CheckboxWidget
          id="autoplay"
          title="Autoplay"
          value={data.autoplay ? data.autoplay : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <CheckboxWidget
          id="fullwidth"
          title="A tutta larghezza"
          value={data.fullwidth ? data.fullwidth : false}
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />

        <FormFieldWrapper
          id="image_size"
          title={intl.formatMessage(messages.size)}
        >
          <ImageSizeWidget
            onChangeBlock={onChangeBlock}
            data={data}
            block={block}
          />
        </FormFieldWrapper>
      </Segment>

      <ObjectListWidget
        {...props}
        id="slides"
        className="form sidebar-listing-data"
        value={data?.slides ?? []}
        onChange={(id, value) =>
          props.onChangeBlock(block, { ...data, [id]: value })
        }
        schema={() => {
          const slideSchema = {
            title: 'Slide',
            addMessage: 'Aggiungi una Slide',
            fieldsets: [
              {
                id: 'default',
                title: 'Default',
                fields: [
                  'content_as_default',
                  'hide_title',
                  'title',
                  'subtitle',
                  'separator',
                  'description',
                  'image',
                  'opacity',
                  'href',
                  'href_title',
                  'show_cta_arrow',
                  'sign',
                ],
              },
              {
                id: 'colors',
                title: 'Colori',
                fields: ['separator_color'],
              },
              {
                id: 'align',
                title: 'Allineamento',
                fields: [
                  'title_align',
                  'text_align',
                  'href_align',
                  'sign_align',
                  'text_fullwidth',
                ],
              },
            ],
            properties: {
              content_as_default: {
                title: intl.formatMessage(messages.content_as_default),
                description: intl.formatMessage(
                  messages.content_as_default_desc,
                ),
                type: 'boolean',
                defaultValue: false,
              },
              hide_title: {
                title: intl.formatMessage(messages.hide_title),
                type: 'boolean',
                defaultValue: false,
              },
              title: {
                title: intl.formatMessage(messages.title),
              },
              subtitle: {
                title: intl.formatMessage(messages.subtitle),
              },
              description: {
                title: intl.formatMessage(messages.description),
                widget: 'textarea',
              },
              image: {
                title: intl.formatMessage(messages.image),
                widget: 'object_browser',
                mode: 'image',
                allowExternals: true,
              },
              href: {
                title: 'Link',
                widget: 'object_browser',
                mode: 'link',
                allowExternals: true,
              },
              href_title: {
                title: intl.formatMessage(messages.buttonText),
                description: intl.formatMessage(messages.buttonTextDescription),
              },
              show_cta_arrow: {
                title: intl.formatMessage(messages.show_cta_arrow),
                type: 'boolean',
                defaultValue: false,
              },
              opacity: {
                title: intl.formatMessage(messages.opacity),
                type: 'boolean',
                defaultValue: false,
              },
              separator: {
                title: intl.formatMessage(messages.separator),
                type: 'boolean',
                defaultValue: false,
              },
              separator_color: {
                title: intl.formatMessage(messages.separator_color),
                widget: 'color_list',
                colors: TITLE_COLORS,
                defaultValue: 'blue',
              },
              title_align: {
                title: intl.formatMessage(messages.titleAlign),
                widget: 'rwalign',
                defaultValue: 'left',
                alignments: ['left', 'center'],
              },
              text_align: {
                title: intl.formatMessage(messages.textAlign),
                widget: 'rwalign',
                defaultValue: 'left',
                alignments: ['left', 'center'],
              },
              text_fullwidth: {
                title: intl.formatMessage(messages.textFullWidth),
                type: 'boolean',
                defaultValue: false,
              },
              href_align: {
                title: intl.formatMessage(messages.hrefAlign),
                widget: 'rwalign',
                defaultValue: 'left',
                alignments: ['left', 'center'],
              },
              sign: {
                title: intl.formatMessage(messages.sign),
                widget: 'sign',
              },
              sign_align: {
                title: intl.formatMessage(messages.sign_align),
                widget: 'rwalign',
                defaultValue: 'right',
                alignments: ['right', 'middle', 'bottom'],
              },
            },

            required: [],
          };

          addPresetFields(slideSchema, intl, 0, 'colors');
          return slideSchema;
        }}
      />
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
  openObjectBrowser: PropTypes.func.isRequired,
};

export default Sidebar;
