import {
  SAVE_CV_SEARCH,
  GET_CV_SAVED_SEARCHES,
  RESET_CV_SAVED_SEARCHES,
} from '@package/constants/ActionTypes';

export function saveCvSearch(search) {
  return {
    type: SAVE_CV_SEARCH,
    request: {
      op: 'patch',
      path: '/@saved_search/',
      data: {
        'raiway.saved_search': JSON.stringify(search),
      },
    },
  };
}

export function getCvSavedSearches(userId) {
  return {
    type: GET_CV_SAVED_SEARCHES,
    request: {
      op: 'get',
      path: '/@saved_search/raiway.saved_search',
    },
    userId,
  };
}

export function resetCvSavedSearches() {
  return {
    type: RESET_CV_SAVED_SEARCHES,
  };
}
