import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from 'semantic-ui-react';
import orderBy from 'lodash/orderBy';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { UniversalLink, Icon } from '@plone/volto/components';
import cx from 'classnames';
import { flattenToAppURL } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import ctaArrowSVG from '@package/icons/cta-arrow.svg';
import { PresetWrapper, Button as RWButton } from '@package/components';
import GenericLCNListingItem from '@package/components/GenericLCNListingItem/GenericLCNListingItem';
import config from '@plone/volto/registry';

const messages = defineMessages({
  no_applications: {
    id: 'no_applications',
    defaultMessage:
      'Al momento non risultano inoltrate candidature per posizioni aperte',
  },
  application_date: {
    id: 'application_date',
    defaultMessage: 'candidatura del {content}',
  },
  update_date: {
    id: 'update_date',
    defaultMessage: 'aggiornamento {content}',
  },
  pending: {
    id: 'pending',
    defaultMessage: 'Candidatura inviata',
  },
  proponi_candidatura: {
    id: 'proponi_candidatura',
    defaultMessage: 'Proponi candidatura',
  },
});

const UserApplications = (props) => {
  const {
    items = [],
    isCreator,
    moment: { default: moment },
  } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  moment.locale(intl.locale);

  const openPositionsPath =
    config.settings['lavora-con-noi'].openPositionsPath[intl.locale] ||
    config.settings['lavora-con-noi'].openPositionsPath.it;

  useEffect(() => {
    if (isCreator) {
      dispatch(
        searchContent(
          openPositionsPath,
          {
            portal_type: 'JobPosting',
            review_state: 'published',
            'data_scadenza_jobposting.query:date': [new Date().toISOString()],
            'data_scadenza_jobposting.range': 'min',
            b_size: 999999,
            b_start: 0,
          },
          'openPositions',
        ),
      );
    }
    return () => {
      resetSearchContent('openPositions');
    };
  }, [dispatch, isCreator, openPositionsPath]);

  const openPositions = useSelector(
    (state) => state.search.subrequests.openPositions,
  );

  let showOpenPositionsLink = false;
  if (isCreator && openPositions && openPositions.total > 0) {
    const positionsIHaveNotAppliedTo = openPositions.items.filter(
      (position) => {
        return !items.find(
          (item) =>
            flattenToAppURL(item.jp_url) === flattenToAppURL(position['@id']),
        );
      },
    );
    if (positionsIHaveNotAppliedTo.length > 0) {
      showOpenPositionsLink = true;
    }
  }

  const itemsSortedByEffectiveDate = orderBy(items, ['created'], ['desc']);

  return (
    <PresetWrapper {...props}>
      <Grid>
        {itemsSortedByEffectiveDate.length > 0 ? (
          itemsSortedByEffectiveDate.map((item, i) => {
            return (
              <GenericLCNListingItem
                itemUrl={flattenToAppURL(item?.jp_url)}
                key={`application-${i}`}
                firstColumnContent={{
                  className: cx('icon-container', {
                    bando: item?.tipologia_jobposting === 'bando',
                    tirocinio: item?.tipologia_jobposting === 'tirocinio',
                    jobposting: item?.tipologia_jobposting === 'jobposting',
                  }),
                }}
                secondColumnContent={{
                  className: 'item-title-container',
                  content: (
                    <>
                      <span className="item-title">{item?.jp_title}</span>
                      <span className="item-structure">
                        {item?.possibile_sottotitolo}
                      </span>
                    </>
                  ),
                  width: 3,
                }}
                thirdColumnContent={{
                  className: 'item-created',
                  content: (
                    <>
                      {item.created &&
                        `${intl.formatMessage(messages.application_date, {
                          content: moment(item.created).format('DD.MM.YYYY'),
                        })}`}
                    </>
                  ),
                  width: 3,
                }}
                fourthColumnContent={{
                  className: 'item-created',
                  content: (
                    <>
                      {item.modified &&
                        `${intl.formatMessage(messages.update_date, {
                          content: moment(item.modified).format('DD.MM.YYYY'),
                        })}`}
                    </>
                  ),
                  width: 3,
                }}
                fifthColumnContent={{
                  className: cx('icon-container', {
                    'push-right': !item?.status,
                  }),
                  content: {
                    chips: (
                      <div className="rw--chips align-bottom">
                        <Button
                          className={cx('rw--chip', {
                            // We don't have status yet, so we can't know if it's approved or not
                            approved: item?.status === 'approved',
                            pending: true,
                          })}
                          as={''}
                        >
                          {`${intl.formatMessage(messages.pending)}`}
                        </Button>
                      </div>
                    ),
                    button: (
                      <Button
                        className={'icon-button'}
                        as={UniversalLink}
                        href={flattenToAppURL(item?.jp_url)}
                        key={item?.jp_url}
                      >
                        <Icon
                          name={ctaArrowSVG}
                          size="24px"
                          className="arrow-icon"
                        />
                      </Button>
                    ),
                  },
                  width: 2,
                }}
                columns={5}
              />
            );
          })
        ) : (
          <Grid.Row>
            <Grid.Column>
              <div className="no-applications">
                <span>{intl.formatMessage(messages.no_applications)}</span>
              </div>
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
      {showOpenPositionsLink && (
        <RWButton
          primary
          as={UniversalLink}
          size="big"
          color={'outline'}
          href={openPositionsPath}
          arrow={true}
          className="open-positions-link"
        >
          {intl.formatMessage(messages.proponi_candidatura)}
        </RWButton>
      )}
    </PresetWrapper>
  );
};

UserApplications.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  loaded: PropTypes.bool,
};

export default React.memo(injectLazyLibs(['moment'])(UserApplications));
