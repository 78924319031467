import React from 'react';
import { Checkbox, Header } from 'semantic-ui-react';
import {
  selectFacetSchemaEnhancer,
  selectFacetStateToValue,
  selectFacetValueToQuery,
} from '@plone/volto/components/manage/Blocks/Search/components/base';

/**
 * A facet that uses radio/checkboxes to provide an explicit list of values for
 * filtering
 */
const CheckboxFacet = (props) => {
  const { facet, choices, isMulti, onChange, value, isEditMode } = props;
  const facetValue = value;

  const fixedChoices = choices.filter(
    (c) => facet.fixedChoicesList?.indexOf(c.value) >= 0 ?? false,
  );

  return (
    <div className="checkbox-facet">
      <Header as="h4">{facet.title ?? facet?.field?.label}</Header>
      <div className="entries">
        {fixedChoices.map(({ label, value }, i) => (
          <div className="entry" key={value}>
            <Checkbox
              disabled={isEditMode}
              label={label}
              radio={!isMulti}
              checked={
                isMulti
                  ? !!facetValue?.find((f) => f.value === value)
                  : facetValue && facetValue.value === value
              }
              onChange={(e, { checked }) =>
                onChange(
                  facet.field.value,
                  isMulti
                    ? [
                        ...facetValue
                          .filter((f) => f.value !== value)
                          .map((f) => f.value),
                        ...(checked ? [value] : []),
                      ]
                    : checked
                    ? value
                    : null,
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const CheckboxFixedOptionsFacetSchemaEnhancer = ({ schema, formData }) => {
  let newSchema = selectFacetSchemaEnhancer({ schema, formData });

  if (formData.field.value) {
    let { fields } = newSchema.fieldsets[0];
    fields.push('fixedChoicesList');
    newSchema.fieldsets[0].fields = fields;

    newSchema.properties.fixedChoicesList = {
      widget: 'select_querystring',
      title: 'Valori possibili',
      description: 'Seleziona le opzioni fisse da mostrare nella lista',
      querystringField: formData.field.value,
    };
  }

  return newSchema;
};

CheckboxFacet.schemaEnhancer = CheckboxFixedOptionsFacetSchemaEnhancer;
CheckboxFacet.stateToValue = selectFacetStateToValue;
CheckboxFacet.valueToQuery = selectFacetValueToQuery;

export default CheckboxFacet;
