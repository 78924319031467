/**
 * Header component.
 * @module components/theme/Header/Header
 */

import React from 'react';

import RWFooter from '@package/components/layout/Footer/RWFooter';

const Footer = ({ pathname }) => {
  return <RWFooter pathname={pathname} />;
};

export default Footer;
