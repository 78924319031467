import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import { SidebarPortal } from '@plone/volto/components';
import { ListingLinkMore } from '@package/components';
import { PresetWrapper } from '@package/components';
import EditColumnBlock from './Block/EditBlock';
import Sidebar from './Sidebar';

const Edit = (props) => {
  const { data, selected, block, onChangeBlock } = props;
  const [focusOn, setFocusOn] = useState('title');

  useEffect(() => {
    if (!data?.columns || data?.columns?.length === 0) {
      onChangeBlock(block, {
        ...data,
        columns: [{ '@id': uuid() }, { '@id': uuid() }, { '@id': uuid() }],
      });
    }
  }, [block]);

  const focusField = (field) => {
    setFocusOn(field);

    if (!selected) {
      props.onSelectBlock(block);
    }
  };

  if (__SERVER__) {
    return <div />;
  }

  const content = (
    <>
      <div className="columns-wrapper">
        {data?.columns?.map((column, i) => (
          <EditColumnBlock
            data={column}
            index={i}
            focusOn={focusOn}
            setFocusOn={focusField}
            onChange={(index, field, value) => {
              let newColumns = [...data.columns];
              newColumns[index][field] = value;
              onChangeBlock(block, {
                ...data,
                columns: [...newColumns],
              });
            }}
            selected={selected}
          />
        ))}
      </div>
      <ListingLinkMore
        linkTitle={data.href_title}
        linkHref={data.href}
        buttonClassName="rw--button-blue"
      />
    </>
  );

  return (
    <>
      <PresetWrapper
        {...props.data}
        usePresetDefaults={false}
        className={cx('block-image-columns', {
          ['columns-' + [data.n_columns ?? 4]]: data.n_columns ?? 4,
          'multi-rows': data.columns?.length > data.n_columns ?? 4,
          'no-grow': data.noflexGrow,
        })}
      >
        {data.fullWidth ? <Container>{content}</Container> : content}
      </PresetWrapper>
      <SidebarPortal selected={selected}>
        <Sidebar {...props} />
      </SidebarPortal>
    </>
  );
};

Edit.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.object,
    greyBg: PropTypes.bool,
    alignLeft: PropTypes.bool,
  }),
  selected: PropTypes.bool.isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default Edit;
