import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Grid } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { UniversalLink, ConditionalLink } from '@plone/volto/components';
import { PresetWrapper } from '@package/components';
import { ListingImage } from '@package/components';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import CustomButton from '@package/components/Button/Button';

const messages = defineMessages({
  cta_title_default: {
    id: 'cta_title_default',
    defaultMessage: 'Vai al contenuto',
  },
});

const View = (props) => {
  const intl = useIntl();
  const { data, isEditMode = false } = props;

  const img_column_width = data.img_column_width
    ? parseInt(data.img_column_width)
    : 6;

  let image = null;

  if (data?.content_in_evidence?.length > 0) {
    image = ListingImage({
      item: data?.content_in_evidence?.[0],
      className: 'block-highlighted-image',
      size: 'larger',
    });
  }

  return (
    <PresetWrapper
      {...data}
      usePresetDefaults={false}
      className="block-highlighted-content"
    >
      {data?.content_in_evidence?.length > 0 ? (
        <>
          <Grid className="block-content">
            <Grid.Row
              columns={2}
              className={cx({
                'block-destra': data.destra,
              })}
            >
              <Grid.Column computer={img_column_width} tablet={12} mobile={12}>
                {/* image */}
                {image ? (
                  image
                ) : (
                  <div className="placeholder">
                    <img src={DefaultImageSVG} alt="" />
                  </div>
                )}
              </Grid.Column>
              <Grid.Column
                computer={12 - img_column_width}
                tablet={12}
                mobile={12}
              >
                <div className="block-highlighted-body">
                  {/* title */}
                  {!data.has_cta ? (
                    <h3 className="title">
                      <ConditionalLink
                        condition={!isEditMode}
                        item={data.content_in_evidence?.[0]}
                      >
                        {data.content_in_evidence?.[0]?.title}
                      </ConditionalLink>
                    </h3>
                  ) : (
                    <h3 className="title">
                      {data.content_in_evidence?.[0]?.title}
                    </h3>
                  )}
                  {/* descrizione */}
                  {data.content_in_evidence?.[0]?.description && (
                    <div className="content-description">
                      {data.content_in_evidence?.[0]?.description}
                    </div>
                  )}
                  {data?.has_cta &&
                    (data?.link_to?.[0] || data.link_to_external) && (
                      <div className="buttonBottom">
                        <CustomButton
                          as={UniversalLink}
                          size="big"
                          href={
                            data.link_to
                              ? flattenToAppURL(data.link_to[0]?.['@id'])
                              : data.link_to_external
                              ? data.link_to_external
                              : null
                          }
                          arrow={true}
                        >
                          {data.cta_title ||
                            intl.formatMessage(messages.cta_title_default)}
                        </CustomButton>
                      </div>
                    )}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </PresetWrapper>
  );
};

View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default View;
