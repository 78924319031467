import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import { flattenToAppURL } from '@plone/volto/helpers';
import { listMultiComment, resetMultiComment } from '@package/actions';
import { PresetWrapper } from '@package/components';

const messages = defineMessages({
  comunicazioni: {
    id: 'comunicazioni',
    defaultMessage: 'Comunicazioni da Rai Way',
  },
});

const UserMessages = ({ applications = [] }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const appsComments = useSelector((state) => state.multiComments.subrequests);

  useEffect(() => {
    const appsIds = applications.map((app) => flattenToAppURL(app['@id']));
    appsIds.forEach((id) => dispatch(listMultiComment(id, id)));
    return () => {
      dispatch(resetMultiComment(appsIds));
    };
  }, [dispatch, applications]);

  const messageItems = applications.reduce(
    (messages, application) => [
      ...messages,
      ...(appsComments[flattenToAppURL(application['@id'])]?.items?.map(
        (comment, index) => (
          <Grid.Row
            key={`message-${index}-${application['@id']}`}
            className="rw--jobposting-item"
            stretched
          >
            <Grid.Column>
              <span className="item-title">{application.jp_title}</span>
              <span>{comment.text.data}</span>
            </Grid.Column>
          </Grid.Row>
        ),
      ) ?? []),
    ],
    [],
  );

  return messageItems.length > 0 ? (
    <>
      <section id="user-messages">
        <h3 className="title">{intl.formatMessage(messages.comunicazioni)}</h3>
        <PresetWrapper>
          <Grid>{messageItems}</Grid>
        </PresetWrapper>
      </section>
    </>
  ) : null;
};

export default UserMessages;
