import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

import { getUser } from '@plone/volto/actions';
import { BodyClass } from '@plone/volto/helpers';

const BodyUserRoles = () => {
  const dispatch = useDispatch();

  const userId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );

  const userLoggedSt = useSelector((state) => state.users);

  useEffect(() => {
    if (!userLoggedSt?.get?.loading && userId) {
      dispatch(getUser(userId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const rolesBodyClasses =
    !userLoggedSt?.user?.roles || userLoggedSt?.user?.roles.length === 0
      ? ['no-user-roles']
      : userLoggedSt.user.roles.map(
          (role) => `role-${role.toLowerCase().replace(/\s/g, '-')}`,
        );

  return <BodyClass className={rolesBodyClasses.join(' ')} />;
};

export default BodyUserRoles;
