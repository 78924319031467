import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { Button } from '@package/components';
import { Button as SButton, Confirm, Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { saveCV, resetSaveCV } from '@package/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable';
import { Toast } from '@plone/volto/components';
import jwtDecode from 'jwt-decode';
import {
  useYupValidationResolver,
  initializeFormData,
  validationSchema,
} from '../../../CVForm/utils.jsx';
import { getContent } from '@plone/volto/actions';

const messages = defineMessages({
  jobposting_noresult: {
    id: 'jobposting_noresult',
    defaultMessage: 'Job Posting',
  },
  bando: {
    id: 'bando',
    defaultMessage: 'Bando',
  },
  tirocinio: {
    id: 'tirocinio',
    defaultMessage: 'Tirocinio',
  },
  candidatura: {
    id: 'candidatura',
    defaultMessage: 'Invia candidatura spontanea',
  },
  conferma_proponi_candidatura: {
    id: 'conferma_proponi_candidatura',
    defaultMessage:
      'Confermando questa operazione, il tuo CV sarà visionabile da parte di Rai Way.',
  },
  incomplete_cv: {
    id: 'incomplete_cv',
    defaultMessage:
      'Non hai ancora compilato correttamente il tuo CV. Potrai proporre una candidatura solo dopo aver compilato tutti i campi richiesti.',
  },
  no_results_message: {
    id: 'no_results_message',
    defaultMessage: 'Nessun {content} disponibile in questo momento',
  },
  spontaneousApplicationCreated: {
    id: 'spontaneousApplicationCreated',
    defaultMessage: 'Candidatura spontanea inviata con successo.',
  },
});

const JobPostingListingSpontaneousApplicationCTA = ({ intl, toastify }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const token = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );
  // Lo stato dovrebbe contenere il cv, lo chiede e recupera l'header
  const profile = useSelector(
    (state) => state.content.subrequests.profile?.data,
  );
  const [showSetSearchable, setShowSetSearchable] = useState(false);
  const [willFetchProfile, setWillFetchProfile] = useState(false);
  const { loaded, loading, error } = useSelector((state) => state.saveCV);

  const resolver = useYupValidationResolver(validationSchema(intl), intl);
  const { trigger, reset } = useForm({
    defaultValues: useMemo(() => {
      return profile ? initializeFormData(profile) : {};
    }, [profile]),
    resolver,
  });

  const handleSpontaneousApplicationClick = () => {
    if (token) {
      if (profile) {
        setShowSetSearchable(true);
      } else setWillFetchProfile(true);
    } else
      history.push(
        `/login?return_url=${history.location.pathname}&keep_navigation=true`,
      );
  };

  const handleSpontaneousApplication = async () => {
    const valid = await trigger();
    if (valid) {
      dispatch(
        saveCV(flattenToAppURL(profile['@id']), {
          searchable_cv: { token: 'True', title: 'Sì' },
          spontaneous_application: { token: 'True', title: 'Sì' },
        }),
      );
    } else {
      toastify.toast.error(
        <Toast
          error
          title={intl.formatMessage({ id: 'Error', defaultMessage: 'Error' })}
          content={intl.formatMessage(messages.incomplete_cv)}
        />,
      );
    }
    setShowSetSearchable(false);
  };

  // reset useForm default values when profile has been fetched
  useEffect(() => {
    if (profile) reset(initializeFormData(profile));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (loaded && !loading) {
      setShowSetSearchable(false);
      toastify.toast.success(
        <Toast
          success
          title={intl.formatMessage({ id: 'Success' })}
          content={intl.formatMessage(messages.spontaneousApplicationCreated)}
        />,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loaded]);

  // potremmo non avere per qualche motivo il profilo cv candidato, recuperarlo
  useEffect(() => {
    if (willFetchProfile && !profile) {
      dispatch(getContent(flattenToAppURL(user.home_page), null, 'profile'));
    } else if (willFetchProfile && profile) {
      setWillFetchProfile(false);
      setShowSetSearchable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willFetchProfile, profile]);

  useEffect(() => {
    if (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      toastify.toast.error(
        <Toast
          error
          title={intl.formatMessage({ id: 'Error', defaultMessage: 'Error' })}
          content={intl.formatMessage(messages.errore_proponi_candidatura)}
        />,
      );
    }
  }, [error, intl, toastify.toast]);

  useEffect(() => {
    return () => {
      dispatch(resetSaveCV());
    };
  }, [dispatch]);

  return (
    <div className="application-button-container">
      {(!token ||
        profile?.searchable_cv?.token === 'False' ||
        profile?.spontaneous_application?.token === 'False') && (
        <Button
          arrow={true}
          key={'candidatura-spontanea'}
          onClick={handleSpontaneousApplicationClick}
          className={'application-button'}
        >
          {intl.formatMessage(messages.candidatura)}
        </Button>
      )}
      {showSetSearchable && (
        <Confirm
          cancelButton={
            !loading
              ? intl.formatMessage({
                  id: 'cancel',
                  defaultMessage: 'Cancel',
                })
              : null
          }
          confirmButton={
            !loading ? (
              intl.formatMessage({ id: 'confirm', defaultMessage: 'Conferma' })
            ) : (
              <SButton primary disabled>
                <Loader inline={'centered'} active={loading} size="tiny" />
              </SButton>
            )
          }
          open={showSetSearchable}
          header={intl.formatMessage(messages.candidatura)}
          content={
            <div className="content">
              {intl.formatMessage(messages.conferma_proponi_candidatura)}
            </div>
          }
          onCancel={() => setShowSetSearchable(false)}
          onConfirm={() => handleSpontaneousApplication()}
          size="mini"
        />
      )}
    </div>
  );
};

JobPostingListingSpontaneousApplicationCTA.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default React.memo(
  injectLazyLibs(['toastify'])(JobPostingListingSpontaneousApplicationCTA),
);
