import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'ss1b';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '388'}
      height={size ?? '415'}
      fill="none"
      viewBox="0 0 388 415"
      className={className}
      key={key}
      {...otherProps}
    >
      <path
        fill={`url(#${uniqueID}paint0_linear)`}
        d="M.772 414.316h162.511V251.854H.773v162.462zm150.276-12.129H12.636V263.816h138.412v138.371z"
      />
      <path
        fill={`url(#${uniqueID}paint1_linear)`}
        d="M78.172 350.695h309.6V41.188h-309.6v309.507zm291.124-19.968H98.146V60.657h271.15v270.07z"
      />
      <path
        fill={`url(#${uniqueID}paint2_linear)`}
        d="M142.589 73.108h72.877V.253h-72.877v72.855zm67.344-5.453h-61.89V5.785h61.89v61.87z"
      />
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1="179.89"
          x2="-15.791"
          y1="368.695"
          y2="297.451"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="419.41"
          x2="46.618"
          y1="263.781"
          y2="128.055"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="222.913"
          x2="135.161"
          y1="52.649"
          y2="20.7"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Icon;
