import React from 'react';
import PropTypes from 'prop-types';
import { Segment } from 'semantic-ui-react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { TextWidget } from '@plone/volto/components';

const messages = defineMessages({
  backLinkTextLabel: {
    id: 'backLinkTextLabel',
    defaultMessage: 'Torna alla lista',
  },
  registerLinkTextLabel: {
    id: 'registerLinkTextLabel',
    defaultMessage: 'Invia la candidatura',
  },
});

const Sidebar = (props) => {
  const intl = useIntl();
  const { data, block, onChangeBlock } = props;

  return (
    <Segment.Group raised>
      <header className="header pulled">
        <h2>
          <FormattedMessage
            id="JobPostingCTAs"
            defaultMessage="Configurazione CTA Job Posting"
          />
          :
        </h2>
      </header>

      <Segment className="form sidebar-listing-data">
        <TextWidget
          id="backLinkText"
          title="Titolo per CTA di ritorno alla lista dei Job Posting"
          value={
            data.backLinkLabel ?? intl.formatMessage(messages.backLinkTextLabel)
          }
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
        <TextWidget
          id="registerLinkTextLabel"
          title="Titolo per la CTA candidatura"
          value={
            data.registerLinkText ??
            intl.formatMessage(messages.registerLinkTextLabel)
          }
          onChange={(name, value) => {
            onChangeBlock(block, { ...data, [name]: value });
          }}
        />
      </Segment>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  block: PropTypes.string.isRequired,
  onChangeBlock: PropTypes.func.isRequired,
};

export default Sidebar;
