import { Form, Popup, Icon, Button } from 'semantic-ui-react';
import { messages, OTHER_DEGREE_MAGIC_WORD } from '../utils';
import {
  TextWidget,
  SelectWidget,
  DatetimeWidgetComponent,
  CheckboxWidget,
  FormFieldWrapper,
} from '../../CVWidgets';

const UniversityFields = ({
  university,
  setFieldValue,
  control,
  intl,
  isDisabled,
}) => {
  const currentState = Array.isArray(university) ? university : [];

  const handleOnChange = (id, value, index) => {
    const newState = currentState.map((item, i) => {
      if (index === i) {
        if (id === 'vote' && value && !/^[0-9]*$/.test(value)) {
          return item;
        }
        if (id === 'degree_type' && item.degree_type !== value) {
          return {
            ...item,
            degree_type: value,
            degree_class: '',
            degree_other: '',
          };
        }
        if (id === 'degree_class' && item.degree_class !== value) {
          return { ...item, degree_class: value, degree_other: '' };
        }
        return { ...item, [id]: value };
      }
      return item;
    });
    setFieldValue('university', newState, false);
  };

  const addNewGroup = () => {
    setFieldValue('university', [
      ...currentState,
      {
        degree_type: '',
        degree_class: '',
        degree_other: '',
        name: '',
        equipollenza: false,
        ongoing: false,
        start_date: '',
        end_date: '',
        vote: '',
        voting_system: '',
        laudem: false,
      },
    ]);
  };

  const removeGroup = (index) => {
    setFieldValue(
      'university',
      currentState.filter((item, i) => i !== index),
    );
  };

  return (
    <div>
      <div className="education-add-container">
        <h4>{intl.formatMessage(messages.universityTitle)}</h4>
      </div>
      {currentState.map((item, index) => (
        <div className="form-fields-container" key={index}>
          <h5>
            {intl.formatMessage(
              index === 0
                ? messages.universitySubtitlePrimary
                : messages.universitySubtitleOther,
            )}
          </h5>
          <Form.Group widths="3">
            <SelectWidget
              id={`university[${index}].degree_type`}
              title={intl.formatMessage(messages.universityDegreeType)}
              value={item.degree_type}
              onChange={(id, value) =>
                handleOnChange('degree_type', value, index)
              }
              vocabBaseUrl="raiway.lavoraconnoi.Degree"
              noValueOption={false}
              required={true}
              control={control}
              isDisabled={isDisabled}
            />
            <SelectWidget
              key={item.degree_type}
              id={`university[${index}].degree_class`}
              title={intl.formatMessage(messages.universityDegreeClass)}
              value={item.degree_class}
              onChange={(id, value) =>
                handleOnChange('degree_class', value, index)
              }
              vocabBaseUrl={item.degree_type}
              noValueOption={false}
              required={true}
              control={control}
              isDisabled={isDisabled || !item.degree_type}
            />
            {item.degree_class?.indexOf(OTHER_DEGREE_MAGIC_WORD) > -1 && (
              <TextWidget
                id={`university[${index}].degree_other`}
                name={`university[${index}].degree_other`}
                type="text"
                title={intl.formatMessage(messages.universityDegreeOther)}
                value={item.degree_other}
                onChange={(id, value) =>
                  handleOnChange('degree_other', value, index)
                }
                required={
                  item.degree_class?.indexOf(OTHER_DEGREE_MAGIC_WORD) > -1 ??
                  false
                }
                wrapped={true}
                control={control}
                isDisabled={isDisabled}
              />
            )}
          </Form.Group>
          <Form.Group widths="equal">
            <TextWidget
              id={`university[${index}].name`}
              name={`university[${index}].name`}
              type="text"
              title={intl.formatMessage(messages.universityName)}
              value={item.name}
              onChange={(id, value) => handleOnChange('name', value, index)}
              required={false}
              wrapped={true}
              control={control}
              isDisabled={isDisabled}
            />
            <FormFieldWrapper
              id={`university[${index}].equipollenza`}
              title={intl.formatMessage(messages.equipollenza)}
              wrapped
              control={control}
              popup={
                <Popup
                  on={['click', 'hover']}
                  trigger={<Icon name="question circle" />}
                  content={intl.formatMessage(messages.equipollenzaPopup)}
                  position="top left"
                  hideOnScroll={true}
                />
              }
            >
              <CheckboxWidget
                isDisabled={isDisabled}
                id={`university[${index}].equipollenza`}
                radio={true}
                title={intl.formatMessage(messages.yes)}
                value={true}
                checked={item.equipollenza}
                onChange={(id, value) =>
                  handleOnChange('equipollenza', value, index)
                }
              />
              <CheckboxWidget
                isDisabled={isDisabled}
                id={`university[${index}].equipollenza`}
                radio={true}
                title={intl.formatMessage(messages.no)}
                value={false}
                checked={!item.equipollenza}
                onChange={(id, value) =>
                  handleOnChange('equipollenza', value, index)
                }
              />
            </FormFieldWrapper>
          </Form.Group>
          <Form.Group widths={'equal'}>
            <FormFieldWrapper
              id={`university[${index}].ongoing`}
              title={intl.formatMessage(messages.ongoingStudies)}
              wrapped
              required={false}
              control={control}
            >
              <CheckboxWidget
                id={`university[${index}].ongoing`}
                isDisabled={isDisabled}
                title={intl.formatMessage(messages.yes)}
                radio={true}
                value={true}
                checked={item.ongoing}
                onChange={(id, value) =>
                  handleOnChange('ongoing', value, index)
                }
              />
              <CheckboxWidget
                id={`university[${index}].ongoing`}
                isDisabled={isDisabled}
                title={intl.formatMessage(messages.no)}
                radio={true}
                value={false}
                checked={!item.ongoing}
                onChange={(id, value) =>
                  handleOnChange('ongoing', value, index)
                }
              />
            </FormFieldWrapper>
            <DatetimeWidgetComponent
              isDisabled={isDisabled}
              id={`university[${index}].start_date`}
              title={intl.formatMessage(messages.startDate)}
              required={true}
              onChange={(id, value) =>
                handleOnChange('start_date', value, index)
              }
              wrapped={true}
              value={item.start_date}
              dateOnly={true}
              control={control}
            />
            <DatetimeWidgetComponent
              isDisabled={isDisabled}
              id={`university[${index}].end_date`}
              title={intl.formatMessage(messages.endDate)}
              required={true}
              onChange={(id, value) => handleOnChange('end_date', value, index)}
              wrapped={true}
              value={item.end_date}
              dateOnly={true}
              control={control}
            />
          </Form.Group>
          <Form.Group widths={'equal'}>
            <TextWidget
              isDisabled={isDisabled}
              id={`university[${index}].vote`}
              type="text"
              name={`university[${index}].vote`}
              value={item.vote}
              onChange={(id, value) => handleOnChange('vote', value, index)}
              required={!item.ongoing}
              title={intl.formatMessage(messages.vote)}
              wrapped={true}
              control={control}
            />
            <SelectWidget
              isDisabled={isDisabled}
              id={`university[${index}].voting_system`}
              title={intl.formatMessage(messages.votingSystem)}
              value={item.voting_system}
              onChange={(id, value) =>
                handleOnChange('voting_system', value, index)
              }
              choices={[
                ['/60', '/60'],
                ['/100', '/100'],
                ['/110', '/110'],
              ]}
              required={!item.ongoing}
              control={control}
            />
            <FormFieldWrapper
              id={`university[${index}].laudem`}
              title={intl.formatMessage(messages.laudem)}
              wrapped
              control={control}
            >
              <CheckboxWidget
                isDisabled={isDisabled}
                id={`university[${index}].laudem`}
                title={intl.formatMessage(messages.yes)}
                radio={true}
                value={true}
                checked={item.laudem}
                onChange={(id, value) => handleOnChange('laudem', value, index)}
              />
              <CheckboxWidget
                isDisabled={isDisabled}
                id={`university[${index}].laudem`}
                title={intl.formatMessage(messages.no)}
                radio={true}
                value={false}
                checked={!item.laudem}
                onChange={(id, value) => handleOnChange('laudem', value, index)}
              />
            </FormFieldWrapper>
          </Form.Group>
          {!isDisabled && (
            <Button
              className="rw--button remove-fieldset removeCV active"
              onClick={() => removeGroup(index)}
            >
              {intl.formatMessage(messages.remove)}
            </Button>
          )}
        </div>
      ))}
      <div className="add-new-fieldset">
        {!isDisabled && (
          <Button
            className="rw--button rw--button-outline"
            onClick={addNewGroup}
          >
            {intl.formatMessage(messages.add, {
              context: intl.formatMessage(messages.universityTitle),
            })}
          </Button>
        )}
      </div>
    </div>
  );
};

export default UniversityFields;
