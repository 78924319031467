import { injectLazyLibs } from '@plone/volto/helpers/Loadable/Loadable';
import { Icon } from '@plone/volto/components';
import { customSelectStyles as baseCustomStyles } from '@plone/volto/components/manage/Widgets/SelectStyling';

import downSVG from '@plone/volto/icons/down-key.svg';
import upSVG from '@plone/volto/icons/up-key.svg';

export const DropdownIndicator = injectLazyLibs('reactSelect')((props) => {
  const { DropdownIndicator } = props.reactSelect.components;
  return (
    <DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? (
        <Icon name={upSVG} size="31px" color="#000094" />
      ) : (
        <Icon name={downSVG} size="31px" color="#000094" />
      )}
    </DropdownIndicator>
  );
});

export const customSelectStyles = {
  ...baseCustomStyles,
  control: (styles, state) => ({
    ...styles,
    borderWidth: '0',
    borderBottomWidth: '1px',
    boxShadow: 'none',
    minHeight: '39px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '13px',
  }),
  dropdownIndicator: (styles) => ({
    paddingRight: 0,
    display: 'flex',
  }),
  clearIndicator: (styles) => ({
    color: '#ff004d',
    display: 'flex',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '1000',
  }),
  // option: (styles) => ({
  //   display: 'flex',
  //   alignItems: 'center',
  //   minHeight: 500,
  //   padding: '0px 10px',
  //   lineHeight: '1.5',
  // }),
};
