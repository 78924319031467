import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { Button as SButton } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon } from '@plone/volto/components';
import { Button } from '@package/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { injectLazyLibs } from '@plone/volto/helpers/Loadable';
import cx from 'classnames';
// import EditAvatarModal from './EditAvatarModal';
import UserOblivionModal from './UserOblivionModal';
import { hexLatin1Converter } from '../../CVForm/utils.jsx';

import homeSVG from '@package/icons/IconaHome.svg';
import emailSVG from '@package/icons/Email.svg';
import fileSVG from '@package/icons/PDF.svg';
import mobileSVG from '@package/icons/Phone.svg';
import editingSVG from '@package/icons/Edit.svg';

const messages = defineMessages({
  draftState: {
    id: 'draftState',
    defaultMessage: 'Stato: Bozza',
  },
  activeState: {
    id: 'activeState',
    defaultMessage: 'Stato: Attivo',
  },
  compila_cv: {
    id: 'compila_cv',
    defaultMessage: '{content} il CV online',
  },
  incomplete_cv: {
    id: 'incomplete_cv',
    defaultMessage:
      'Non hai ancora compilato correttamente il tuo CV. Potrai proporre una candidatura solo dopo aver compilato tutti i campi richiesti.',
  },
  compila: {
    id: 'compila',
    defaultMessage: 'Compila',
  },
  modifica: {
    id: 'modifica',
    defaultMessage: 'Modifica',
  },
  viewCV: {
    id: 'viewCV',
    defaultMessage: 'Visualizza',
  },
  universityUserInfosLabel: {
    id: 'universityUserInfosLabel',
    defaultMessage: 'Laurea in {degree}',
  },
});

const renderDegrees = (intl, content) => {
  let highSchool = null;
  let university = [];
  try {
    highSchool = JSON.parse(content.high_school);
    university = JSON.parse(content.university) ?? [];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  if (university.length > 0) {
    return university
      .map((uni) =>
        intl.formatMessage(messages.universityUserInfosLabel, {
          degree: hexLatin1Converter(
            (uni.degree_other || uni.degree_class) ?? '',
          ),
        }),
      )
      .join(', ');
  } else if (highSchool !== null) {
    return hexLatin1Converter(
      (highSchool.degree_other || highSchool.degree_type) ?? '',
    );
  }
  return '';
};

const UserInfos = ({
  content,
  isEditing,
  setIsEditing,
  isCreator,
  userId,
  toastify,
}) => {
  const intl = useIntl();

  // handle userInfos edit
  const handleUserInfosEdit = () => {
    setIsEditing(!isEditing);
  };

  const attachments = content.items.filter((item) =>
    ['File', 'Image'].includes(item['@type']),
  );

  return (
    <div className="user-infos-container">
      <div className="actions-container-mobile">
        {isCreator && (
          <>
            <UserOblivionModal
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              intl={intl}
              userId={userId}
            />
            <SButton
              icon
              onClick={handleUserInfosEdit}
              className={cx('', { editProfileButton: isEditing })}
            >
              <Icon name={editingSVG} size="24px" color="#000094" />
            </SButton>
          </>
        )}
      </div>
      <div className="avatar-container">
        <figure>
          {content.image?.scales?.preview?.download ? (
            <img
              src={flattenToAppURL(content.image?.scales?.preview?.download)}
              alt="avatar"
              className="avatar"
            />
          ) : (
            <FontAwesomeIcon icon={['fas', 'user-circle']} size="10x" />
          )}
          {/* isCreator && (
            <EditAvatarModal
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              content={content}
              intl={intl}
            />
          ) */}
        </figure>
      </div>

      <div className="personal-infos-container">
        <div className="actions-container">
          {isCreator && (
            <>
              <UserOblivionModal
                setIsEditing={setIsEditing}
                isEditing={isEditing}
                intl={intl}
                userId={userId}
              />
              <SButton
                icon
                onClick={handleUserInfosEdit}
                className={cx('', { editProfileButton: isEditing })}
              >
                <Icon name={editingSVG} size="24px" color="#000094" />
              </SButton>
            </>
          )}
        </div>
        <div className="fullname">{content.fullname}</div>
        <div className="degree">{renderDegrees(intl, content)}</div>
        <div className="cv-state">
          {intl.formatMessage(
            content.searchable_cv.token === 'False'
              ? messages.draftState
              : messages.activeState,
          )}
        </div>
        <div className="contact-info">
          {(content.residency_address ||
            content.residency_postal_code ||
            content.residency_municipality) && (
            <div className="contact-info-item">
              <Icon size="16px" name={homeSVG}></Icon>
              {`${content.residency_address ?? ''} - ${
                content.residency_postal_code ?? ''
              }, ${content.residency_municipality?.title ?? ''}`}
            </div>
          )}
          {content.personal_cv && (
            <div className="contact-info-item">
              <Icon size="16px" name={fileSVG}></Icon>
              <a
                href={content.personal_cv.download}
                rel="noreferrer"
                target="_blank"
              >
                {content.personal_cv.filename}
              </a>
            </div>
          )}
          {attachments.map((item) => {
            const link =
              item['@type'] === 'File'
                ? `${flattenToAppURL(item['@id'])}/@@download/file`
                : `${flattenToAppURL(item['@id'])}/@@download/image`;

            return (
              <div
                key={flattenToAppURL(item['@id'])}
                className="contact-info-item"
              >
                <Icon size="16px" name={fileSVG} />
                <a href={link} title="Download">
                  {item.title}
                </a>
              </div>
            );
          })}
          <div className="contact-info-item">
            <Icon size="16px" name={emailSVG}></Icon>
            <a href={`mailto:${content.email}`}>{content.email}</a>
          </div>
          {content.mobile && (
            <div className="contact-info-item">
              <Icon size="16px" name={mobileSVG}></Icon>
              {content.mobile}
            </div>
          )}
        </div>
        <div className="buttons-container">
          <Button
            primary
            size="big"
            className={cx('cv-button', {
              editing: isEditing,
            })}
            arrow={true}
            onClick={handleUserInfosEdit}
          >
            {intl.formatMessage(messages.compila_cv, {
              content: isCreator
                ? content.curriculum
                  ? intl.formatMessage(messages.modifica)
                  : intl.formatMessage(messages.compila)
                : intl.formatMessage(messages.viewCV),
            })}
          </Button>
        </div>
      </div>
    </div>
  );
};

UserInfos.propTypes = {
  content: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isCreator: PropTypes.bool.isRequired,
};

export default React.memo(injectLazyLibs(['toastify'])(UserInfos));
