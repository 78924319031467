import React from 'react';
import { Container } from 'semantic-ui-react';
import { LanguageSelector } from '@plone/volto/components';
import HeaderSearch from '../HeaderSearch';
import { Anontools } from '@plone/volto/components';
// import { SecondaryMenu } from 'volto-secondarymenu';
import HeaderUserDropdown from '../HeaderUserDropdown';

const RWHeaderSmall = ({ pathname }) => {
  return (
    <div className="header-small">
      <Container>
        <div className="header-small-content">
          {/* <SecondaryMenu pathname={pathname} /> */}
          <HeaderSearch />
          <LanguageSelector />
          <Anontools />
          <HeaderUserDropdown />
        </div>
      </Container>
    </div>
  );
};

export default RWHeaderSmall;
