import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { Container } from 'semantic-ui-react';
import { Anontools } from '@plone/volto/components';
import { LanguageSelector } from '@plone/volto/components';
import HeaderUserDropdown from '../HeaderUserDropdown';
import { SecondaryMenu, getSecondaryMenu } from 'volto-secondarymenu';
import { useDispatch, useSelector } from 'react-redux';
import { EurolandTicker } from '@package/components';
import { useIntl } from 'react-intl';

const eurolandUrl = {
  it:
    'https://tools.eurolandir.com/tools/ticker/html/?companycode=it-rway&v=static2022&lang=it-it',
  en:
    'https://tools.eurolandir.com/tools/ticker/html/?companycode=it-rway&v=static2022&lang=en-gb',
};

const RWHeaderSmall = ({ pathname }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const menuItems = useSelector((state) => state.secondaryMenu?.result);

  // Fallback in case secondary menu redux state is somehow not present
  useEffect(() => {
    if (menuItems.length === 0) dispatch(getSecondaryMenu());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const isSiteSubsection = useMemo(() => {
    // Find all secondary menu entries that have a reference to an homepage
    // An homepage is a content of @type LFR
    // WARNING: I can set link by typing it manually in secondary menu settings, it won't work.
    // I'm not sure if it's a bug of collective.secondarymenu or not.
    // Client needs to use ObjectBrowserWidget for type to be set correctly
    // Therefore, a subsection not appearing here is MISCONFIGURATION and NOT A BUG.
    const subsections = menuItems?.reduce((acc, curr) => {
      if (curr.rootPath?.split('/').filter(Boolean).length > 1) {
        if (
          curr.items?.some(
            (item) =>
              Array.isArray(item?.linkUrl) &&
              item?.linkUrl?.some(
                (linkUrlItem) => linkUrlItem?.['@type'] === 'LRF',
              ),
          )
        )
          return [...acc, curr];
      }
      return acc;
    }, []);
    // Once that is done, we need to compare to current path, check if we are in
    // a subsection defined in the list we just computed. If that's the case, bingo.
    return subsections?.some((ss) => pathname.includes(ss?.rootPath));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItems, pathname]);

  // IMPORTANT NOTICE
  // Using css to set go back to home arrow based on isSiteSubsection and secondary
  // menu configuration, addon customization has proven unreliable in the past,
  // and we do not have budget now to do it that way.
  // Go back link in secondary menu setting configuration HAS TO BE THE FIRST ELEMENT,
  // will be explained to client, MISCONFIGURATION IS NOT A BUG.
  return (
    <div className="header-small">
      <Container>
        <div
          className={cx('header-small-content', {
            'lavora-con-noi': isSiteSubsection,
          })}
        >
          <EurolandTicker url={eurolandUrl[intl.locale]} />
          <div
            className={cx('flex', {
              'header-small-content-left': isSiteSubsection,
            })}
          >
            <SecondaryMenu pathname={pathname} />
          </div>
          <div
            className={cx('flex', {
              'header-small-content-right': isSiteSubsection,
            })}
          >
            <LanguageSelector />
            <Anontools />
            <HeaderUserDropdown />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RWHeaderSmall;
