import React from 'react';
import Icon from '@plone/volto/components/theme/Icon/Icon';
import textSizeSVG from '@package/icons/text-size.svg';
import textSVG from '@package/icons/text.svg';

import DraftJsDropdownButton from '@package/config/RichTextEditor/ToolbarButtons/DraftJsDropdownButton';

const TextSizeButton = (props) => {
  const createInlineStyleButton = props.draftJsCreateInlineStyleButton.default;

  const options = [
    {
      block_type: 'text-larger',
      value: createInlineStyleButton({
        style: 'TEXT_LARGER',
        children: <Icon name={textSVG} size="1.5em" title="Testo grande" />,
      }),
    },
    {
      block_type: 'text-medium',
      value: createInlineStyleButton({
        style: 'TEXT_MEDIUM',
        children: <Icon name={textSVG} size="1em" title="Testo medio" />,
      }),
    },
  ];

  return (_props) => (
    <DraftJsDropdownButton
      {..._props}
      optionsList={options}
      content={<Icon name={textSizeSVG} size="1em" />}
    />
  );
};

export default TextSizeButton;
