import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import getImageUrl from '@package/helpers/getImageUrl.js';
import { useIntl, defineMessages } from 'react-intl';
import { Card, Modal, Button, TransitionablePortal } from 'semantic-ui-react';
import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';
import { Icon, UniversalLink } from '@plone/volto/components';
import ctaArrowSVG from '@package/icons/cta-arrow.svg';
import clearSVG from '@plone/volto/icons/clear.svg';
import downloadSVG from '@plone/volto/icons/download.svg';

import {
  ListingHeader,
  ListingLinkMore,
  ListingImage,
  PresetWrapper,
} from '@package/components';

const messages = defineMessages({
  defaultImage: {
    id: 'Immagine segnaposto',
    defaultMessage: 'Immagine segnaposto',
  },
  viewImage: {
    id: "Vedi l'immagine",
    defaultMessage: "Vedi l'immagine",
  },
  viewPreview: {
    id: 'view_prev',
    defaultMessage: "Vedi l'anteprima di",
  },
  next_image: {
    id: 'next_image',
    defaultMessage: 'Passa ad immagine successiva',
  },
  prev_image: {
    id: 'prev_image',
    defaultMessage: "Torna all'immagine precedente",
  },
  close_modal: {
    id: 'close_modal',
    defaultMessage: 'Chiudi la modale',
  },
  donwloadHD: {
    id: 'donwloadHD',
    defaultMessage: 'Scarica in HD',
  },
  donwloadLD: {
    id: 'donwloadLD',
    defaultMessage: 'Scarica in LD',
  },
});

const PhotogalleryListing = (props) => {
  const { items, cols = 3, title_color = 'grey' } = props;
  const intl = useIntl();

  const [previewImage, setPreviewImage] = useState(null);
  const [previewIndex, setPreviewIndex] = useState(null);

  const PREVIEW_IMAGE_SIZE = 'great';

  function carouselModal(direction) {
    // check current index
    let checkIndex;
    if (direction === 'left') {
      if (previewIndex === 0) {
        checkIndex = items.length - 1;
      } else {
        checkIndex = previewIndex - 1;
      }
    } else {
      if (previewIndex === items.length - 1) {
        checkIndex = 0;
      } else {
        checkIndex = previewIndex + 1;
      }
    }
    const item = items[checkIndex];

    setPreviewImage(getImageUrl(item, PREVIEW_IMAGE_SIZE));
    setPreviewIndex(checkIndex);
  }

  const leftButtonRef = React.useRef();
  const rightButtonRef = React.useRef();
  const modalCloseRef = React.useRef();

  const refIsFocused = (ref) => {
    return document.activeElement === ref.current?.ref.current;
  };

  useEffect(() => {
    if (
      previewImage != null &&
      leftButtonRef?.current &&
      rightButtonRef?.current &&
      !refIsFocused(leftButtonRef) &&
      !refIsFocused(rightButtonRef)
    ) {
      leftButtonRef.current.focus();
    }
  }, [previewImage]);

  const getCaption = (item) => (
    <>
      {item.title ? (
        item['@type'] === 'Image' ? (
          <>
            <p
              className={cx('item-title', {
                'title-blue': title_color === 'blue',
              })}
            >
              {item.title}
            </p>
          </>
        ) : (
          <>
            <UniversalLink
              className={cx('item-title', {
                'title-blue': title_color === 'blue',
              })}
              item={item}
            >
              {item.title}
            </UniversalLink>
          </>
        )
      ) : null}
      {/*have_description && item.description ? (
        <p className="item-text">{item.description}</p>
      ) : null*/}
    </>
  );

  const figure = (image) => (
    <figure className="thumb-img">
      {image ? (
        <>{image}</>
      ) : (
        <img
          src={DefaultImageSVG}
          alt={intl.formatMessage(messages.defaultImage)}
        />
      )}
    </figure>
  );

  const getImageDownloadUrl = (field, url) => {
    return url?.replace(
      '@@images/' + field + '/' + PREVIEW_IMAGE_SIZE,
      '@@download/' + field,
    );
  };

  return (
    <PresetWrapper {...props} className="listing photogallery-template">
      <ListingHeader {...props} />
      <Grid stackable>
        <Grid.Row>
          {items.map((item, index) => {
            const image = ListingImage({ item });
            const previewImageUrl = getImageUrl(item, PREVIEW_IMAGE_SIZE);

            return (
              <Grid.Column
                computer={12 / cols}
                tablet={6}
                mobile={16}
                key={index}
              >
                <Card className="photogallery-card">
                  <div className="thumb-box">
                    <a
                      href={getImageUrl(item, 'large')}
                      onClick={(e) => {
                        e.preventDefault();
                        setPreviewImage(previewImageUrl);
                        setPreviewIndex(index);
                        e.stopPropagation();
                      }}
                      onKeyUp={(e) => {
                        if (e.keyCode === 13) {
                          //Enter
                          setPreviewImage(previewImageUrl);
                          setPreviewIndex(index);
                        }
                      }}
                      aria-label={`${intl.formatMessage(
                        messages.viewPreview,
                      )} ${item.title}`}
                    >
                      {figure(image)}
                    </a>
                  </div>

                  {getCaption(item) && (
                    <figcaption>{getCaption(item)}</figcaption>
                  )}
                </Card>
              </Grid.Column>
            );
          })}
        </Grid.Row>
      </Grid>

      <TransitionablePortal
        open={previewImage != null}
        transition={{ animation: 'fade', duration: 300 }}
      >
        <Modal
          id="modalGallery"
          className="rw--modal"
          closeIcon={
            <Button
              size="xs"
              title={intl.formatMessage(messages.close_modal)}
              className="close"
              ref={modalCloseRef}
            >
              <Icon name={clearSVG} size="24px" />
            </Button>
          }
          closeOnEscape={true}
          onClose={() => {
            setPreviewImage(null);
            setPreviewIndex(null);
          }}
          open={previewImage != null}
          onKeyUp={(e) => {
            if (e.keyCode === 37) {
              //arrow-left
              carouselModal('left');
            }
            if (e.keyCode === 39) {
              //arrow-right
              carouselModal('right');
            }
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 9) {
              //tab
              if (refIsFocused(rightButtonRef)) {
                e.preventDefault();
                modalCloseRef.current.focus();
              }
            }
          }}
          size="large"
        >
          <Modal.Content className="modal-body">
            <div className="item-preview">
              {items.length > 1 && (
                <Button
                  size="xs"
                  title={intl.formatMessage(messages.prev_image)}
                  onClick={() => carouselModal('left')}
                  className="prev"
                  ref={leftButtonRef}
                >
                  <Icon name={ctaArrowSVG} className="arrow-icon" size="22px" />
                </Button>
              )}

              <div className="image">
                {previewImage && (
                  <img
                    alt={items[previewIndex].title}
                    role="presentation"
                    src={previewImage}
                    loading="lazy"
                  />
                )}
              </div>

              {items.length > 1 && (
                <Button
                  size="xs"
                  title={intl.formatMessage(messages.next_image)}
                  onClick={() => carouselModal('right')}
                  className="next"
                  ref={rightButtonRef}
                >
                  <Icon name={ctaArrowSVG} className="arrow-icon" size="22px" />
                </Button>
              )}
            </div>
            {items.length > 1 && previewIndex != null && (
              <>
                <div className="item-actions">
                  <UniversalLink
                    href={getImageDownloadUrl(
                      items[previewIndex].image_field,
                      previewImage,
                    )}
                  >
                    <Icon name={downloadSVG} size="24px" />

                    {intl.formatMessage(messages.donwloadHD)}
                  </UniversalLink>
                  <UniversalLink
                    href={
                      getImageDownloadUrl(
                        items[previewIndex].image_field,
                        previewImage,
                      ) +
                      '/' +
                      PREVIEW_IMAGE_SIZE
                    }
                  >
                    <Icon name={downloadSVG} size="24px" />
                    {intl.formatMessage(messages.donwloadLD)}
                  </UniversalLink>
                </div>
                <div className="item-caption">
                  <h5>{items[previewIndex].title}</h5>
                  <p>{items[previewIndex].description}</p>
                </div>
              </>
            )}
          </Modal.Content>
        </Modal>
      </TransitionablePortal>

      <ListingLinkMore {...props} />
    </PresetWrapper>
  );
};
PhotogalleryListing.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  linkHref: PropTypes.any,
  linkTitle: PropTypes.string,
};

export default PhotogalleryListing;
