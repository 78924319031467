import React from 'react';
import cx from 'classnames';
import { ListingLinkMore, PresetWrapper } from '@package/components';
import ViewColumn from './Block/ViewBlock';

import { Container } from 'semantic-ui-react';

const View = ({ data }) => {
  const content = (
    <>
      <div className="columns-wrapper">
        {data?.columns?.map((numberBlock, i) => (
          <ViewColumn data={numberBlock} index={i} />
        ))}
      </div>

      <ListingLinkMore
        linkTitle={data.href_title}
        linkHref={data.href}
        buttonClassName="rw--button-blue"
      />
    </>
  );

  return (
    <PresetWrapper
      {...data}
      usePresetDefaults={false}
      className={cx('block-image-columns', {
        ['columns-' + [data.n_columns ?? 4]]: data.n_columns ?? 4,
        'multi-rows': data.columns?.length > data.n_columns ?? 4,
        'no-grow': data.noflexGrow,
      })}
    >
      {data.fullWidth ? <Container>{content}</Container> : content}
    </PresetWrapper>
  );
};

export default View;
