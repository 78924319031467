/*
-------- CUSTOMIZATIONS: ---------
- gestito il campo 'showTotalResults'. Pr su volto: https://github.com/plone/volto/pull/3368
*/
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  searchResults: {
    id: 'Search results',
    defaultMessage: 'Search results',
  },
  searchedFor: {
    id: 'Searched for',
    defaultMessage: 'Searched for',
  },
});

const SearchDetails = ({ total, text, as = 'h4', data }) => {
  const El = as;
  const intl = useIntl();
  return (
    <El className="search-details">
      {text ? `${intl.formatMessage(messages.searchedFor)}: ${text}. ` : ''}
      {(data.showTotalResults ?? true) && (
        <>
          {' '}
          {intl.formatMessage(messages.searchResults)}: {total}
        </>
      )}
    </El>
  );
};

export default SearchDetails;
