import React, { useEffect, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { getVocabulary } from '@plone/volto/actions';
import WorkExperienceFields from './WorkExperienceFields';
import { CheckboxWidget, FormFieldWrapper } from '../../CVWidgets';
import { messages } from '../utils';

const WorkExperienceStep = (props) => {
  const {
    professional_experience,
    business_areas_of_interest,
    setFieldValue,
    control,
    intl,
    isDisabled,
  } = props;
  const dispatch = useDispatch();
  const [weStepState, setWeStepState] = useState(null);

  const baofVocabulary = useSelector(
    (state) =>
      state.vocabularies['raiway.lavoraconnoi.BusinessAreasOfInterest']?.items,
  );

  useEffect(() => {
    dispatch(
      getVocabulary({
        vocabNameOrURL: 'raiway.lavoraconnoi.BusinessAreasOfInterest',
      }),
    );
  }, [dispatch]);

  if (baofVocabulary?.length > 0 && !weStepState) {
    setWeStepState(
      baofVocabulary?.reduce(
        (obj, item) => ({
          ...obj,
          [item.value]: business_areas_of_interest?.some(
            (baof) => baof.token === item.value,
          ),
        }),
        {},
      ),
    );
  }

  const baofTitles = baofVocabulary?.reduce((acc, item) => {
    acc[item.value] = item.label;
    return acc;
  }, {});

  const getIsDisabled = (checkboxItem) => {
    return (
      Object.values(weStepState)?.reduce((a, item) => a + item, 0) > 2 &&
      !weStepState[checkboxItem]
    );
  };

  const handleOnChange = (id, value) => {
    setWeStepState({
      ...weStepState,
      [id]: !weStepState[id],
    });

    if (!weStepState[id]) {
      setFieldValue('business_areas_of_interest', [
        ...business_areas_of_interest,
        { token: id, title: baofTitles[id] },
      ]);
    } else {
      setFieldValue(
        'business_areas_of_interest',
        business_areas_of_interest.filter((baof) => baof.token !== id),
      );
    }
  };
  return (
    <div className="step-container" aria-live="polite">
      <WorkExperienceFields
        professional_experience={professional_experience}
        setFieldValue={setFieldValue}
        control={control}
        intl={intl}
        isDisabled={isDisabled}
      />
      <h4>{intl.formatMessage(messages.businessAreasOfInterest)}</h4>
      <p className="help">
        {intl.formatMessage(messages.businessAreasOfInterestHelp)}
      </p>
      <Form.Group widths={'equal'}>
        <FormFieldWrapper
          wrapped
          id="business_areas_of_interest"
          className="checkbox-list"
          control={control}
        >
          {weStepState &&
            Object.keys(weStepState)?.map((item, i) => (
              <CheckboxWidget
                key={item}
                id={item}
                value={weStepState[item]}
                onChange={handleOnChange}
                title={baofTitles[item]}
                isDisabled={isDisabled || getIsDisabled(item)}
              />
            ))}
        </FormFieldWrapper>
      </Form.Group>
    </div>
  );
};

export default WorkExperienceStep;
