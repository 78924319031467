import React from 'react';
import { Pagination, Grid, Dropdown } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import { calculateCurrentShown, initialQuery } from '../utils';
import { Button } from '@package/components';

const messages = defineMessages({
  b_size: {
    id: 'b_size',
    defaultMessage: 'Risultati per pagina',
  },
  shown: {
    id: 'shown',
    defaultMessage: 'visualizzati',
  },
  last_page: {
    id: 'last_page',
    defaultMessage: 'Ultima pagina',
  },
  pages: {
    id: 'pages',
    defaultMessage: 'Pagina {currentPage}/{totalPages}',
  },
});

const JobPostingListingPagination = ({
  currentPage,
  totalPages,
  totalItems,
  bSize,
  bSizeOptions,
  onChangePage,
  onChangeBSize,
  intl,
}) => {
  return totalItems > 0 ? (
    <>
      <Grid.Row
        className="pagination-wrapper"
        only="large screen"
        columns={3}
        verticalAlign="middle"
      >
        <Grid.Column computer={4} mobile={12} textAlign="left">
          {totalPages > 1 && (
            <Pagination
              className="pagination"
              activePage={currentPage}
              totalPages={totalPages}
              onPageChange={onChangePage}
              firstItem={null}
              lastItem={{
                content:
                  totalPages >= 5
                    ? intl.formatMessage(messages.last_page)
                    : null,
              }}
              boundaryRange={0}
              siblingRange={1}
              prevItem={null}
              nextItem={null}
              ellipsisItem={{
                content: '...',
                onClick: (e, props) =>
                  onChangePage(e, { activePage: props.value }),
              }}
            />
          )}
        </Grid.Column>
        <Grid.Column computer={4} mobile={12} className="results-count">
          <span>{intl.formatMessage(messages.shown)}</span>
          <span className="current-shown">{`${calculateCurrentShown(
            currentPage,
            bSize,
            totalItems,
          )}`}</span>
          <span>{`/${totalItems}`}</span>
        </Grid.Column>
        <Grid.Column computer={4} mobile={12} className="bsize-selection">
          {(totalPages > 1 || bSize !== initialQuery.b_size) && (
            <>
              <span>{intl.formatMessage(messages.b_size)}</span>
              <Dropdown
                defaultValue={bSize}
                placeholder={bSize}
                onChange={onChangeBSize}
                options={bSizeOptions}
                selection
                icon="chevron down"
              />
            </>
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row
        className="pagination-wrapper mobile"
        only="tablet mobile"
        columns={3}
        verticalAlign={'middle'}
      >
        <Grid.Column className="prevPage" width={3}>
          <Button
            className="rw--button rw--button-outline"
            onClick={(e, props) =>
              onChangePage(e, { activePage: currentPage - 1 })
            }
            arrow={true}
            reverse={true}
            disabled={currentPage === 0}
          />
        </Grid.Column>
        <Grid.Column className="pageIndicator" width={6}>
          {intl.formatMessage(messages.pages, {
            currentPage,
            totalPages,
          })}
        </Grid.Column>
        <Grid.Column className="nextPage" width={3}>
          <Button
            arrow={true}
            className="rw--button rw--button-outline"
            onClick={(e, props) =>
              onChangePage(e, { activePage: currentPage + 1 })
            }
            disabled={currentPage === totalPages}
          />
        </Grid.Column>
      </Grid.Row>
    </>
  ) : null;
};

JobPostingListingPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  totalItems: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  bSize: PropTypes.number.isRequired,
  bSizeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  onChangeBSize: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  intl: PropTypes.any,
};

export default JobPostingListingPagination;
