import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import contentListingSVG from '@plone/volto/icons/content-listing.svg';
import rightArrowSVG from '@plone/volto/icons/right-key.svg';

import config from '@plone/volto/registry';

const messages = defineMessages({
  applications_list: {
    id: 'applications_list',
    defaultMessage: 'Applications list',
  },
});

export const ApplicationsListToolbar = () => {
  const intl = useIntl();

  const hasAction = useSelector(
    (state) =>
      state?.actions?.actions?.object?.filter(
        (action) => action.id === 'applications-list',
      )?.length > 0,
  );

  const jp_uid = useSelector((state) => state?.content?.data?.jp_uid);

  return hasAction && jp_uid ? (
    <Plug
      pluggable="toolbar-more-manage-content"
      id="applications-list-toolbar"
    >
      <li>
        <Link
          to={`${flattenToAppURL(
            `${config.settings['lavora-con-noi'].backofficeURL}?bo_pane=CV&jp_uid=${jp_uid}`,
          )}`}
          aria-label={intl.formatMessage(messages.applications_list)}
          id="applications-list-toolbar-panel"
        >
          <Icon name={contentListingSVG} size="24px" color="#000094" />
          {intl.formatMessage(messages.applications_list)}
          <Icon name={rightArrowSVG} size="24px" />
        </Link>
      </li>
    </Plug>
  ) : null;
};

export default ApplicationsListToolbar;
