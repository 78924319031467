import {
  ADD_MULTI_COMMENT,
  LIST_MULTI_COMMENT,
  RESET_MULTI_COMMENT,
} from '@package/constants/ActionTypes';

export function addMultiComment(urls, text, commentId) {
  return {
    type: ADD_MULTI_COMMENT,
    mode: 'serial',
    request:
      typeof urls === 'string'
        ? {
            op: 'post',
            path: commentId
              ? `${urls}/@comments/${commentId}`
              : `${urls}/@comments`,
            data: { text },
          }
        : urls.map((url, index) => ({
            op: 'post',
            path: commentId?.[index]
              ? `${url}/@comments/${commentId[index]}`
              : `${url}/@comments`,
            data: { text },
          })),
  };
}

export function listMultiComment(url, subrequest) {
  return {
    type: LIST_MULTI_COMMENT,
    request: {
      op: 'get',
      path: `${url}/@comments`,
    },
    subrequest,
  };
}

export function resetMultiComment(subrequests) {
  return {
    type: RESET_MULTI_COMMENT,
    subrequests,
  };
}
