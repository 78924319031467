import { compact, concat, isArray, join, map, pickBy, toPairs } from 'lodash';

import {
  RESET_SEARCH_CV_CONTENT,
  SEARCH_CV_CONTENT,
} from '@package/constants/ActionTypes';

const WILDCARD_SEARCHES = [
  'FullTextSearch',
  'fullname',
  'residency_nation',
  'field_of_study',
];

/**
 * Search content function.
 * @function searchContent
 * @param {string} url Url to use as base.
 * @param {Object} options Search options.
 * @param {string} subrequest Key of the subrequest.
 * @returns {Object} Search content action.
 */
export function searchCvContent(url, options, subrequest = null) {
  let queryArray = [];
  options = pickBy(options, (value, key) =>
    WILDCARD_SEARCHES.includes(key) ? value : true,
  );
  const arrayOptions = pickBy(options, (item) => isArray(item));

  queryArray = concat(
    queryArray,
    options
      ? join(
          map(toPairs(pickBy(options, (item) => !isArray(item))), (item) => {
            if (WILDCARD_SEARCHES.includes(item[0])) {
              // Adds the wildcard where needed
              item[1] = `${item[1]}*`;
            }
            return join(item, '=');
          }),
          '&',
        )
      : '',
  );

  queryArray = concat(
    queryArray,
    arrayOptions
      ? join(
          map(pickBy(arrayOptions), (item, key) =>
            join(
              item.map((value) => {
                if (/:.*$/.test(key)) {
                  return `${key.replace(/:(.*)$/, ':list:$1')}=${value}`;
                } else {
                  return `${key}:list=${value}`;
                }
              }),
              '&',
            ),
          ),
          '&',
        )
      : '',
  );

  const querystring = join(compact(queryArray), '&');

  return {
    type: SEARCH_CV_CONTENT,
    subrequest,
    request: {
      op: 'post',
      path: `${url}/@search_cv${querystring ? `?${querystring}` : ''}`,
    },
  };
}

/**
 * Reset search content function.
 * @function resetSearchContent
 * @param {string} subrequest Key of the subrequest.
 * @returns {Object} Search content action.
 */
export function resetSearchCvContent(subrequest = null) {
  return {
    type: RESET_SEARCH_CV_CONTENT,
    subrequest,
  };
}
