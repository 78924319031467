import React from 'react';
const Icon = (props) => {
  const { id = '', size, className, key, ...otherProps } = props;
  const uniqueID = id + 'ss1fg';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ? 'auto' : '388'}
      height={size ?? '415'}
      fill="none"
      viewBox="0 0 388 415"
      className={className}
      key={key}
      {...otherProps}
    >
      <path
        fill={`url(#${uniqueID}paint0_linear)`}
        d="M.535 414.431h162.512v-162.46H.535v162.46zm150.276-12.13H12.399V263.933h138.412v138.368z"
      />
      <path
        fill={`url(#${uniqueID}paint1_linear)`}
        d="M77.935 350.812h309.6V41.304h-309.6v309.508zm291.124-19.968H97.909V60.773h271.15v270.071z"
      />
      <path
        fill={`url(#${uniqueID}paint2_linear)`}
        d="M142.351 73.223h72.878V.368h-72.878v72.855zm67.345-5.452h-61.891V5.899h61.891v61.872z"
      />
      <defs>
        <linearGradient
          id={`${uniqueID}paint0_linear`}
          className="paint_linear"
          x1=".546"
          x2="162.979"
          y1="333.212"
          y2="333.212"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint1_linear`}
          className="paint_linear"
          x1="77.954"
          x2="387.406"
          y1="196.079"
          y2="196.079"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id={`${uniqueID}paint2_linear`}
          className="paint_linear"
          x1="142.356"
          x2="215.198"
          y1="36.801"
          y2="36.801"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default Icon;
