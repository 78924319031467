import React from 'react';
import { useSelector } from 'react-redux';
import { Icon, UniversalLink } from '@plone/volto/components';
import { Plug } from '@plone/volto/components/manage/Pluggable';
import rightArrowSVG from '@plone/volto/icons/right-key.svg';

import config from '@plone/volto/registry';

const BackofficeLink = () => {
  const backofficeAction = useSelector(
    (state) =>
      state.actions.actions.user?.filter(
        (action) => action.id === 'backoffice',
      )[0],
  );

  return backofficeAction ? (
    <Plug pluggable="toolbar-user-menu" id="backoffice-link">
      <li>
        <UniversalLink href={config.settings['lavora-con-noi'].backofficeURL}>
          {backofficeAction.title}
          <Icon name={rightArrowSVG} size="24px" />
        </UniversalLink>
      </li>
    </Plug>
  ) : null;
};

export default BackofficeLink;
