/**
 * User oblivion action.
 * @module monet-volto/actions/userOblivion
 */
export const USER_OBLIVION = 'USER_OBLIVION';

/**
 * User oblivion
 * @function userOblivion
 * @returns {Object}  User oblivion action
 */
export function userOblivion(id) {
  return {
    type: USER_OBLIVION,
    request: {
      op: 'del',
      path: `/@oblivion/${id}`,
    },
  };
}
